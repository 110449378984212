import { useAppSelector } from 'dux/app/hooks';

import { productsCategories } from 'constants/products';

import { getCartV2Currency, getCartV2Data, getDeliveryByV2 } from 'dux/cartV2/selectors';
import * as checkoutCartSelectors from 'dux/checkoutCart/selectors';

import useCheckoutCategory from './useCheckoutCategory';

const useShippingMethodInfosByCartType = () => {
  const checkoutCategory = useCheckoutCategory();

  // Cart V1
  const cartSubscriptionCoupons = useAppSelector(checkoutCartSelectors.getCartSubscriptionCoupons);
  const deliveryBy = useAppSelector(checkoutCartSelectors.getDeliveryBy);
  const cartData = useAppSelector(checkoutCartSelectors.getCartData);
  const currency = useAppSelector(checkoutCartSelectors.getCartCurrency);

  // Cart V2
  const deliveryByV2 = useAppSelector(getDeliveryByV2);
  const cartDataV2 = useAppSelector(getCartV2Data);
  const currencyV2 = useAppSelector(getCartV2Currency);

  if (checkoutCategory === productsCategories.ACCESSORIES) {
    return {
      deliveryBy: deliveryByV2,
      cartData: cartDataV2,
      currency: currencyV2,
      isCartV2: true,
    };
  }

  return {
    cartSubscriptionCoupons,
    deliveryBy,
    cartData,
    currency,
  };
};

export default useShippingMethodInfosByCartType;
