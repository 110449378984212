import collateralsHeroSkincareImageDesktop from 'assets/images/digitalLeaflet/collaterals_hero_skincare_d.jpg';
import collateralsHeroSkincareImageMobile from 'assets/images/digitalLeaflet/collaterals_hero_skincare_m.jpg';
// fragrances
import arcadiaFragranceImage from 'assets/images/digitalLeaflet/fragrances/arcadia.jpg';
import auraFragranceImage from 'assets/images/digitalLeaflet/fragrances/aura.jpg';
import blancaFragranceImage from 'assets/images/digitalLeaflet/fragrances/blanca.jpg';
import botanicaFragranceImage from 'assets/images/digitalLeaflet/fragrances/botanica.jpg';
import corsicaFragranceImage from 'assets/images/digitalLeaflet/fragrances/corsica.jpg';
import defaultFragranceImage from 'assets/images/digitalLeaflet/fragrances/default.jpg';
import fragranceFreeFragranceImage from 'assets/images/digitalLeaflet/fragrances/fragrance-free.jpg';
import meleniFragranceImage from 'assets/images/digitalLeaflet/fragrances/meleni.jpg';
import oasisFragranceImage from 'assets/images/digitalLeaflet/fragrances/oasis.jpg';
import pacificaFragranceImage from 'assets/images/digitalLeaflet/fragrances/pacifica.jpg';
import palmaFragranceImage from 'assets/images/digitalLeaflet/fragrances/palma.jpg';
import paradisoFragranceImage from 'assets/images/digitalLeaflet/fragrances/paradiso.jpg';
import perleFragranceImage from 'assets/images/digitalLeaflet/fragrances/perle.jpg';
import preludeFragranceImage from 'assets/images/digitalLeaflet/fragrances/prelude.jpg';
// products-related
import conditionerImage from 'assets/images/digitalLeaflet/product-nav-conditioner.png';
import curlCreamImage from 'assets/images/digitalLeaflet/product-nav-curl-cream.png';
import dryShampooImage from 'assets/images/digitalLeaflet/product-nav-dry-shampoo.png';
import hairMaskImage from 'assets/images/digitalLeaflet/product-nav-hair-mask.png';
import hairOilImage from 'assets/images/digitalLeaflet/product-nav-hair-oil.png';
import supplementsJarsImage from 'assets/images/digitalLeaflet/product-nav-hair-supplements-jars.png';
import supplementsPouchesImage from 'assets/images/digitalLeaflet/product-nav-hair-supplements-pouches.png';
import leaveInImage from 'assets/images/digitalLeaflet/product-nav-leave-in-conditioner.png';
import placeholderProductImage from 'assets/images/digitalLeaflet/product-nav-placeholder.png';
import scalpMaskImage from 'assets/images/digitalLeaflet/product-nav-scalp-mask.png';
import shampooImage from 'assets/images/digitalLeaflet/product-nav-shampoo.png';
import cleanserImage from 'assets/images/digitalLeaflet/product-nav-skin-cleanser.png';
import cleanserMiniImage from 'assets/images/digitalLeaflet/product-nav-skin-cleanser-mini.png';
import moisturizerImage from 'assets/images/digitalLeaflet/product-nav-skin-moisturizer.png';
import moisturizerMiniImage from 'assets/images/digitalLeaflet/product-nav-skin-moisturizer-mini.png';
import serumImage from 'assets/images/digitalLeaflet/product-nav-skin-serum.png';
import serumMiniImage from 'assets/images/digitalLeaflet/product-nav-skin-serum-mini.png';
import stylingGelImage from 'assets/images/digitalLeaflet/product-nav-styling-gel.png';
import howToCurlcream from 'assets/images/digitalLeaflet/prose-custom-curl-cream-in-use.jpg';
import howToHairMask from 'assets/images/digitalLeaflet/prose-custom-hair-mask-in-use.jpg';
import collateralsHeroHaircareImageDesktop from 'assets/images/digitalLeaflet/prose-custom-hair-trio-desktop.jpg';
import collateralsHeroHaircareImageMobile from 'assets/images/digitalLeaflet/prose-custom-hair-trio-mobile.jpg';
import howToScalpMask from 'assets/images/digitalLeaflet/prose-custom-pre-shampoo-scalp-mask-in-use.jpg';
import howToSupplements from 'assets/images/digitalLeaflet/prose-custom-supplements-in-use.jpg';
import howToLeavein from 'assets/images/digitalLeaflet/prose-model-posing-with-custom-leave-in-conditioner.jpg';
import howToConditionner from 'assets/images/digitalLeaflet/prose-model-using-custom-conditioner.jpg';
import howToDryShampoo from 'assets/images/digitalLeaflet/prose-model-using-custom-dry-shampoo.jpg';
import howToHairoil from 'assets/images/digitalLeaflet/prose-model-using-custom-hair-oil.jpg';
import howToShampoo from 'assets/images/digitalLeaflet/prose-model-using-custom-shampoo.jpg';
import howToStylinggel from 'assets/images/digitalLeaflet/prose-model-using-custom-styling-gel.jpg';
import howToCleanser from 'assets/images/digitalLeaflet/prose-skin-cleanser-in-use.jpg';
import howToMoisturizer from 'assets/images/digitalLeaflet/prose-skin-moisturizer-in-use.jpg';
import howToSerum from 'assets/images/digitalLeaflet/prose-skin-serum-in-use.jpg';

export const heroImages = {
  haircare: {
    mobile: { imageData: collateralsHeroHaircareImageMobile, alt: 'Prose custom hair trio' },
    desktop: { imageData: collateralsHeroHaircareImageDesktop, alt: 'Prose custom hair trio' },
  },
  skincare: {
    mobile: { imageData: collateralsHeroSkincareImageMobile, alt: 'A box with your products' },
    desktop: { imageData: collateralsHeroSkincareImageDesktop, alt: 'A box with your products' },
  },
};

export const heroBodyText = 'Look forward to always‑improving results with your custom products.';

export const headingTexts = {
  haircare: 'Only good hair days ahead',
  skincare: 'Your healthiest skin starts here',
};

export const fragranceImages = {
  arcadia: arcadiaFragranceImage,
  aura: auraFragranceImage,
  blanca: blancaFragranceImage,
  botanica: botanicaFragranceImage,
  corsica: corsicaFragranceImage,
  fragranceFree: fragranceFreeFragranceImage,
  meleni: meleniFragranceImage,
  oasis: oasisFragranceImage,
  pacifica: pacificaFragranceImage,
  palma: palmaFragranceImage,
  perle: perleFragranceImage,
  prelude: preludeFragranceImage,
  paradiso: paradisoFragranceImage,
  default: defaultFragranceImage /* 'default' is not the name of a fragrance here */,
} as const;

export const productsAdditionalContent = {
  // haircare
  shampoo: {
    productImage: { src: shampooImage, alt: 'shampoo' },
    navLabel: 'Shampoo',
    howToImage: { src: howToShampoo, alt: 'Prose model using custom shampoo' },
  },
  leavein_conditioner: {
    productImage: { src: leaveInImage, alt: 'leave-in conditioner' },
    navLabel: 'Leave‑in Conditioner',
    howToImage: {
      src: howToLeavein,
      alt: 'Prose model posing with bottle of custom leave-in conditioner',
    },
  },
  hair_mask: {
    productImage: { src: hairMaskImage, alt: 'hair mask' },
    navLabel: 'Hair mask',
    howToImage: { src: howToHairMask, alt: 'Prose custom hair mask in use' },
  },
  scalp_mask: {
    productImage: { src: scalpMaskImage, alt: 'scalp mask' },
    navLabel: 'Scalp Mask',
    howToImage: { src: howToScalpMask, alt: 'Prose custom scalp mask in use' },
  },
  dry_shampoo: {
    productImage: { src: dryShampooImage, alt: 'dry shampoo' },
    navLabel: 'Dry Shampoo',
    howToImage: { src: howToDryShampoo, alt: 'Prose model using custom dry shampoo' },
  },
  curl_cream: {
    productImage: { src: curlCreamImage, alt: 'curl cream' },
    navLabel: 'Curl Cream',
    howToImage: { src: howToCurlcream, alt: 'Prose custom curl cream in use' },
  },
  conditioner: {
    productImage: { src: conditionerImage, alt: 'conditioner' },
    navLabel: 'Conditioner',
    howToImage: {
      src: howToConditionner,
      alt: 'Prose model using custom conditioner in the shower',
    },
  },
  leavein /* actually hair oil */: {
    productImage: { src: hairOilImage, alt: 'hair oil' },
    navLabel: 'Hair Oil',
    howToImage: { src: howToHairoil, alt: 'Prose model using custom hair oil' },
  },
  styling_gel: {
    productImage: { src: stylingGelImage, alt: 'styling gel' },
    navLabel: 'Styling Gel',
    howToImage: { src: howToStylinggel, alt: 'Prose model using custom styling gel' },
  },
  supplement_booster: {
    productImage: { src: supplementsPouchesImage, alt: 'supplements' },
    navLabel: 'Root Source™ Hair Supplements',
    howToImage: { src: howToSupplements, alt: '' },
  },
  supplement_core: {
    productImage: { src: supplementsJarsImage, alt: 'supplements' },
    navLabel: 'Root Source™ Hair Supplements',
    howToImage: { src: howToSupplements, alt: '' },
  },

  // skincare
  cleanser: {
    productImage: { src: cleanserImage, alt: 'cleanser' },
    navLabel: 'Cleanser',
    howToImage: { src: howToCleanser, alt: '' },
  },
  serum: {
    productImage: { src: serumImage, alt: 'serum' },
    navLabel: 'Serum',
    howToImage: { src: howToSerum, alt: '' },
  },
  moisturizer: {
    productImage: { src: moisturizerImage, alt: 'moisturizer' },
    navLabel: 'Moisturizer',
    howToImage: { src: howToMoisturizer, alt: '' },
  },
  cleanser_mini: {
    productImage: { src: cleanserMiniImage, alt: 'cleanser' },
    navLabel: 'Cleanser [Starter Set]',
    howToImage: { src: howToCleanser, alt: '' },
  },
  serum_mini: {
    productImage: { src: serumMiniImage, alt: 'serum' },
    navLabel: 'Serum [Starter Set]',
    howToImage: { src: howToSerum, alt: '' },
  },
  moisturizer_mini: {
    productImage: { src: moisturizerMiniImage, alt: 'moisturizer' },
    navLabel: 'Moisturizer [Starter Set]',
    howToImage: { src: howToMoisturizer, alt: '' },
  },

  // default
  default: {
    productImage: { src: placeholderProductImage, alt: '' },
    navLabel: '',
    howToImage: { src: howToShampoo, alt: '' },
  },
};
