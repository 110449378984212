import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@prose-ui/legacy';
import { makeStyles } from 'legacyStyles';

import {
  CHECKOUT_DEFAULT_THEME,
  CHECKOUT_OPTIMISATION_THEME,
} from 'Apps/Checkout/constants/checkoutInputThemes';

import { srcOrNextImageProp } from 'PropTypes/imageProps';

const ImageContainer = styled.div`
  position: absolute;
  right: 18px;
  top: 18px;
`;

const useStyles = makeStyles(
  (theme, _props, classes) => ({
    root: {
      paddingBottom: 18,
      position: 'relative',
      minWidth: 80,
    },
    checkoutTheme: {
      marginTop: theme.spacing.s8,
      marginBottom: theme.spacing.s8,
      width: '100%',
      maxWidth: 300,
    },
    checkoutOptimisationTheme: {
      paddingBottom: '10px',
      margin: 0,
      width: '100%',
    },
    checkoutOptimisationThemeError: {
      paddingBottom: '14px',
    },
    maxWidth: {
      maxWidth: 400,
    },
    fullWidth: {
      width: '100%',
    },
    margin: {
      marginTop: theme.spacing.s8,
      marginBottom: theme.spacing.s8,
    },
    inputRoot: {
      position: 'relative',
      background: theme.palette.common.white.light,
      minHeight: 55,
      '&:before': {
        content: '""',

        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',

        pointerEvents: 'none',

        boxShadow: `2px 2px 3px 0 ${theme.palette.common.grey.light}`,
        border: `solid 0.5px ${theme.palette.common.grey.light}`,
        mixBlendMode: 'multiply',
      },
    },
    focused: {},
    error: {},
    underline: {
      '&:after': {
        borderBottom: `2px solid ${theme.palette.common.noir.medium}`,
        left: 0,
        bottom: 0,
        // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
        content: '""',
        position: 'absolute',
        right: 0,
        transform: 'scaleX(0)',
        transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        pointerEvents: 'none', // Transparent to the hover style.
      },
      [`&.${classes.error}:after`]: {
        borderBottomColor: theme.palette.common.rouge.medium,
        transform: 'scaleX(1)', // error is always underlined in red
      },
      [`&.${classes.focused}:after`]: {
        transform: 'scaleX(1)',
      },
    },
    component: {
      ...theme.typography.p2,
      fontSize: 16,
      color: theme.palette.common.grey.dark,
      background: 'transparent',
      marginTop: 20,
      width: '100%',
      padding: '4px 16px',
      border: 'none',
      overflow: 'hidden',
      whiteSpace: 'var(--white-space, nowrap)',
      textOverflow: 'ellipsis',
    },
    componentNoLabel: {
      marginTop: 16,
    },
    label: {
      ...theme.typography.p2,
      fontSize: 16,
      color: theme.palette.common.grey.dark,
      position: 'absolute',
      top: 0,
      left: 2 * theme.spacing.s8,
      transformOrigin: 'top left',
      transform: `translate(0, 19px) scale(1)`,
      pointerEvents: 'none',
      transition: [
        'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      ],
    },
    shrink: {
      color: theme.palette.common.noir.medium,
      transform: 'translate(0, 8px) scale(0.75)',
      textAlign: 'left',

      /* If label is > 1 line, we want to show an ellipsis */
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    errorMessage: {
      ...theme.typography.p3,
      color: theme.palette.common.rouge.medium,
      position: 'absolute',
      margin: 0,
      left: 2 * theme.spacing.s8,
      bottom: -4,
    },
    infoMessage: {
      ...theme.typography.p3,
      color: theme.palette.common.noir.medium,
      position: 'absolute',
      margin: 0,
      left: 2 * theme.spacing.s8,
      bottom: -4,
    },
    labelError: {
      color: theme.palette.common.rouge.medium,
    },
    hiddenLabel: {
      display: 'none',
    },
    charCount: {
      position: 'absolute',
      bottom: 0,
      right: 10,
      fontSize: 12,
      color: theme.palette.common.grey.dark,
      '&.alert': {
        color: theme.palette.common.rouge.medium,
      },
    },
    disabled: {
      opacity: 0.4,
    },
  }),
  { name: 'BaseField' }
);

/**
 * Currently used a base for all *Field components.
 * Cannot be used alone, have to be extended to be usefull.
 * default max width: **400px**.
 */
const BaseField = forwardRef(
  (
    {
      className,
      children,
      disabled,
      id,
      image,
      label,
      value,
      error,
      fullWidth,
      hiddenLabel,
      info,
      margin,
      maxCount,
      maxCountAlert,
      maxWidth,
      onFocus,
      onBlur,
      theme,
      whiteSpace,
      ...props
    },
    ref
  ) => {
    const { classes, cx } = useStyles(undefined, props?.classes ? { props } : undefined);
    const [focused, setFocused] = useState(false);

    const handleFocus = event => {
      if (!focused) {
        setFocused(true);
      }
      if (onFocus) {
        onFocus(event);
      }
    };

    const handleBlur = event => {
      if (focused) {
        setFocused(false);
      }
      if (onBlur) {
        onBlur(event);
      }
    };

    const childrenProps = {
      id,
      className: cx(classes.component, {
        [classes.componentNoLabel]: !label || hiddenLabel,
      }),
      value,
      name: id,
      onFocus: handleFocus,
      onBlur: handleBlur,
      disabled,
      ...props,
    };

    return (
      <div
        ref={ref}
        className={cx(
          classes.root,
          {
            [classes.checkoutTheme]: theme === CHECKOUT_DEFAULT_THEME,
            [classes.maxWidth]: maxWidth,
            [classes.fullWidth]: fullWidth,
            [classes.margin]: margin,
            [classes.disabled]: disabled,
            [classes.checkoutOptimisationTheme]: theme === CHECKOUT_OPTIMISATION_THEME,
            [classes.checkoutOptimisationThemeError]:
              theme === CHECKOUT_OPTIMISATION_THEME && error,
          },
          className
        )}
        style={{ '--white-space': whiteSpace }}
      >
        <div
          className={cx(
            classes.inputRoot,
            {
              [classes.focused]: focused,
              [classes.error]: Boolean(error),
            },
            classes.underline
          )}
        >
          {label && (
            <label
              className={cx(classes.label, {
                [classes.shrink]: value || focused,
                [classes.labelError]: Boolean(error),
                [classes.hiddenLabel]: hiddenLabel,
              })}
              htmlFor={id}
            >
              {label}
            </label>
          )}
          {children(childrenProps)}
          {Boolean(image) && (
            <ImageContainer>
              <img alt={image.alt} src={image.src.src} />
            </ImageContainer>
          )}
          {maxCount && (
            <div
              className={cx(classes.charCount, {
                alert: value && maxCount - value.length <= maxCountAlert,
              })}
            >
              {value && maxCount - value.length}
            </div>
          )}
        </div>
        {error && (
          <p
            aria-live="assertive"
            className={cx(classes.errorMessage, {
              [classes.errorMessageCheckoutOptimisation]: theme === CHECKOUT_OPTIMISATION_THEME,
            })}
            data-testid="field-error"
            role="alert"
          >
            {error}
          </p>
        )}
        {info && !error && <p className={classes.infoMessage}>{info}</p>}
      </div>
    );
  }
);

BaseField.displayName = 'BaseField';

BaseField.defaultProps = {
  classes: null,
  className: null,
  disabled: false,
  error: null,
  fullWidth: false,
  hiddenLabel: false,
  image: null,
  info: null,
  label: null,
  margin: false,
  maxCount: null,
  maxCountAlert: 9,
  maxWidth: true,
  onBlur: null,
  onChange: null,
  onFocus: null,
  theme: null,
  value: null,
  whiteSpace: 'nowrap',
};

BaseField.propTypes = {
  /**
   * Enables to overrides the element inner styles.
   * Use with caution.
   */
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,

  /**
   * Function to render the element, will be called with the component props
   */
  children: PropTypes.func.isRequired,

  /**
   * Special disabled state UI
   */
  disabled: PropTypes.bool,

  /**
   * Label will be hidden, this is an a11y feature
   */
  hiddenLabel: PropTypes.bool,

  /**
   * The id of the children component.
   * Use this property to make `label` and `helperText` accessible for screen readers.
   */
  id: PropTypes.string.isRequired,

  /**
   * An image on the right of the input.
   */
  image: PropTypes.shape({
    src: srcOrNextImageProp,
    alt: PropTypes.string,
  }),

  /**
   * The label content.
   */
  label: PropTypes.node,

  /**
   * The value of the `Input` element, required for a controlled component.
   */
  value: PropTypes.string,

  /**
   * The error message to display under the component.
   * Error message have priority over info.
   */
  error: PropTypes.string,

  /**
   * The info message to display under the component.
   * Info message are overriden by error message.
   */
  info: PropTypes.string,

  /**
   * The element will have width 100%.
   * Limited by the maxWidth prop.
   */
  fullWidth: PropTypes.bool,

  /**
   * The element will have a max width of 400px.
   * True by default for backward comp purposes.
   * To be deprecated because too much contextual.
   */
  maxWidth: PropTypes.bool,

  /**
   * The element will receive a default margin
   * (top and bottom).
   */
  margin: PropTypes.bool,

  /**
   * add an UI for characters remaining
   */
  maxCount: PropTypes.number,
  maxCountAlert: PropTypes.number,

  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,

  /**
   * Changes the element appearance
   */
  theme: PropTypes.oneOf([CHECKOUT_DEFAULT_THEME, CHECKOUT_OPTIMISATION_THEME]),

  /**
   * Controls text wrapping. Non exhaustive list to be updated as needed
   */
  whiteSpace: PropTypes.oneOf(['nowrap', 'normal']),
};

export default BaseField;
