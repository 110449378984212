import { MY_SKIN } from '../constants';
import * as consultationComponents from '../constants/consultationComponents';
import { SKIN } from '../constants/progressCategories';

const routineProducts = {
  Component: consultationComponents.SkincareRoutineProducts,
  ModalComponent: consultationComponents.MedicalModal,
  name: 'routineProducts',
  category: MY_SKIN,
  progressCategory: SKIN,
  route: `/consultation/skincare/${MY_SKIN}/other-products`,
  title: 'What products do you use regularly?',
  subtitle: 'Select all that apply, thinking about a typical week.',
  tip: {
    title: 'fun fact:',
    content:
      "Whether you prefer to stick to the essentials or you love experimenting with new products, we'll help you build a custom routine that fits your unique needs — because skincare is always personal.",
  },
  requiredOptionsForModalToOpen: ['medical_topicals'],
  optionsGroup: [
    {
      productLabel: 'Cleansers',
      options: [
        {
          value: 'rinse_off_cleanser',
          label: 'Rinse-off cleanser',
        },
        {
          value: 'micellar_water',
          label: 'Micellar water',
        },
        {
          value: 'cleansing_oil',
          label: 'Cleansing oil',
        },
      ],
    },
    {
      productLabel: 'Treatments and moisturizers',
      options: [
        {
          value: 'toner',
          label: 'Toner',
        },
        {
          value: 'spf',
          label: 'SPF',
        },
        {
          value: 'serum',
          label: 'Serum',
        },
        {
          value: 'scrub',
          label: 'Scrub',
        },
        {
          value: 'face_oil',
          label: 'Face oil',
        },
        {
          value: 'mask',
          label: 'Mask',
        },
        {
          value: 'eye_cream',
          label: 'Eye Cream',
        },
        {
          value: 'medical_topicals',
          label: 'Medicated topicals (e.g. tretinoin)',
        },
        {
          value: 'shaving_products',
          label: 'Shaving Products',
        },
        {
          value: 'moisturizer',
          label: 'Moisturizer',
        },
      ],
    },
    {
      productLabel: 'Makeup',
      options: [
        {
          value: 'eye_makeup',
          label: 'Eye makeup',
        },
        {
          value: 'lip_makeup',
          label: 'Lip makeup',
        },
        {
          value: 'face_makeup',
          label: 'Face makeup',
        },
      ],
    },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers?.diag_routine_products,
  getAnswers: ({ selected }) => ({
    diag_routine_products: selected,
  }),
  multi: true,
};

export default routineProducts;
