import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { matchPath, Outlet, useLocation } from 'react-router-dom';

import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'dux/app/hooks';

import PromotionalModalWelcomeKit from 'Components/PromotionalModalWelcomeKit';
import { SkincareModalModalWithStateLogic } from 'Components/SkincareMinisModal';
import { TrialOfferMixedWithSkincareMinisModalWithStateLogic } from 'Components/TrialOfferMixedWithSkincareMinisModal';
import { TrialOfferPromotionModalWithStateLogic } from 'Components/TrialOfferPromotionModal';

import { couponCodes } from 'Services/CouponService';

import { seenPromotionalModal } from 'dux/user/actions';
import { getIsAuthenticated } from 'dux/auth/selectors';
import {
  getCouponsStatuses,
  getIsLoading as getIsCouponsStatusLoading,
} from 'dux/couponsStatuses/selectors';
import {
  getTrialOfferFlagVariant,
  isFlagsDoneFetching,
  shouldShowEvergreenWelcomeKit,
  shouldShowSkincareMinisPhase2,
  shouldShowTrialOffer,
  shouldShowWelcomeKitVisibility,
} from 'dux/featureFlags/selectors';
import {
  getHasActiveHaircareSubscription,
  getHasActiveSkincareSubscription,
  getHasEitherHairOrSkinSubscription,
  getHasHaircareSubscriptionInAnyState,
  getHasOrdersByCategory,
  getHasSkincareSubscriptionInAnyState,
} from 'dux/user/selectors';

const storage = {
  local: 'localStorage',
  session: 'sessionStorage',
  none: 'none',
};

/**
 * Component for remembering whether or not its children has been presented to a customer
 *
 * @param {object} props - Persist props
 * @param {ReactNode} props.children - children of the component
 * @param {string} props.persistKey - key of the value to persist
 * @param {'local'|'session'} props.persistStorage - location where to persist the value
 */
const Persist = ({ children, persistKey, persistStorage }) => {
  const dispatch = useDispatch();
  const storageType = storage[persistStorage];
  const hasSeenModal = window?.[storageType]?.getItem(persistKey);

  useEffect(() => {
    if (storageType !== 'none') {
      window?.[storageType]?.setItem(persistKey, true);
      dispatch(seenPromotionalModal(persistKey));
    }
  }, [storageType, persistKey, dispatch]);

  if (hasSeenModal) return null;

  return children;
};
Persist.propTypes = {
  children: PropTypes.node.isRequired,
  persistKey: PropTypes.string.isRequired,
  persistStorage: PropTypes.string.isRequired,
};

/**
 * Component for delaying the display of it's children
 *
 * @param {object} props - Delay props
 * @param {ReactNode} props.children - children of the component
 * @param {number} [props.delay=2000] - delay's duration
 */
const Delay = ({ children, delay }) => {
  const [showChildren, setShowChildren] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowChildren(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  if (!showChildren) return null;

  return children;
};
Delay.defaultProps = {
  delay: 2000,
};
Delay.propTypes = {
  /** Delay's children */
  children: PropTypes.node.isRequired,
  /** Delay's duration */
  delay: PropTypes.number,
};

/**
 * @typedef {ModalConfig}
 * @property {() => boolean} shouldShow - arbitrary conditions to display the modal
 * @property {() => JSX.Element} render - what modal to render
 * @property {() => string[]} [includedPaths] - path list where the modal is allowed to be rendered
 * @property {() => string[]} [excludedPaths] - path list where the modal is disallowed to be rendered
 * @property {number} delay - duration before showing the modal
 * @property {object} persistence - persistence configuration
 * @property {'local'|'session'} persistence.storage - where to store the info the modal has been seen
 * @property {string} persistence.key - key to retrieve the infor the modal has been seen
 */

const beforeEvergreenWelcomeKit = ({
  isAuthenticated,
  isCouponsStatusLoading,
  couponsStatuses,
  showWelcomeKitVisibility,
}) => {
  const isOfferAvailable = couponsStatuses?.coupons?.some(
    c =>
      c.code === couponCodes.skincareWelcomeKit &&
      c.is_attached_to_customer &&
      c.status === 'created'
  );
  if (showWelcomeKitVisibility && isAuthenticated && !isCouponsStatusLoading) {
    return isOfferAvailable;
  }
  return false;
};

const afterEvergreenWelcomeKit = ({
  showWelcomeKitVisibility,
  isAuthenticated,
  hasSkincareOrder,
  hasActiveHaircareSubscription,
  isCouponsStatusLoading,
  couponsStatuses,
}) => {
  const isOfferAvailable = couponsStatuses?.coupons?.some(
    c =>
      c.code === couponCodes.skincareWelcomeKit &&
      c.is_attached_to_customer &&
      c.status === 'created'
  );
  return (
    !isCouponsStatusLoading &&
    showWelcomeKitVisibility &&
    isAuthenticated &&
    hasActiveHaircareSubscription &&
    !hasSkincareOrder &&
    isOfferAvailable
  );
};

/**
 * Modals and their conditions
 *
 * @type Record<string, ModalConfig>
 */
const Modals = {
  welcomeKit: {
    shouldShow: ({ showEvergreenWelcomeKit, ...args }) => {
      if (showEvergreenWelcomeKit) {
        return afterEvergreenWelcomeKit(args);
      }
      return beforeEvergreenWelcomeKit(args);
    },
    includedPaths: showEvergreenWelcomeKit => (showEvergreenWelcomeKit ? ['*'] : []),
    excludedPaths: showEvergreenWelcomeKit => (showEvergreenWelcomeKit ? [] : ['/checkout/*']),
    render: () => <PromotionalModalWelcomeKit isOpen />,
    delay: 2000,
    persistence: {
      storage: 'session',
      key: 'seenWelcomeKitModal',
    },
  },
  trialOfferMixedWithSkincareMinis: {
    shouldShow: ({
      showTrialOffer,
      showSkincareMinisPhase2,
      hasHaircareSubscriptionInAnyState,
      hasSkincareSubscriptionInAnyState,
    }) =>
      showTrialOffer &&
      showSkincareMinisPhase2 &&
      !hasHaircareSubscriptionInAnyState &&
      !hasSkincareSubscriptionInAnyState,
    excludedPaths: () => ['/checkout/*', '/signin', '/'],
    render: () => <TrialOfferMixedWithSkincareMinisModalWithStateLogic isOpen />,
    delay: 3000,
    persistence: {
      storage: 'session',
      key: 'seentrialOfferMixedWithSkincareMinisModal',
    },
  },
  trialOffer: {
    shouldShow: ({ showTrialOffer }) => showTrialOffer,
    excludedPaths: () => ['/checkout/*', '/signin', '/'],
    render: () => <TrialOfferPromotionModalWithStateLogic isOpen />,
    delay: 3000,
    persistence: {
      storage: 'session',
      key: 'seenTrialOfferModal',
    },
  },
  skincareMinis: {
    shouldShow: ({ showSkincareMinisPhase2, hasHaircareSubscriptionInAnyState }) =>
      showSkincareMinisPhase2 && hasHaircareSubscriptionInAnyState,
    excludedPaths: () => ['/signin', '/'],
    render: () => <SkincareModalModalWithStateLogic isOpen />,
    delay: 3000,
    persistence: {
      storage: 'session',
      key: 'seenSkincareMinisModal',
    },
  },
  trialOfferPostPurchase: {
    shouldShow: ({
      showTrialOffer,
      hasEitherHairOrSkinSubscription,
      showEvergreenWelcomeKit,
      hasActiveHaircareSubscription,
      hasSkincareOrder,
      hasSkincareSubscriptionInAnyState,
    }) => {
      if (showEvergreenWelcomeKit) {
        return (
          showTrialOffer &&
          hasActiveHaircareSubscription &&
          hasSkincareOrder &&
          !hasSkincareSubscriptionInAnyState
        );
      }
      return showTrialOffer && hasEitherHairOrSkinSubscription;
    },
    includedPaths: () => ['/checkout/success'],
    render: () => (
      <TrialOfferPromotionModalWithStateLogic displayAs="trialOfferPostPurchase" isOpen />
    ),
    delay: 3000,
    persistence: {
      storage: 'none',
      key: 'seenTrialOfferPostPurchaseModal',
    },
  },
};

/**
 * List of the modals by order of priority
 *
 * @type ModalConfig[]
 * */
const priorityList = [
  Modals.welcomeKit,
  Modals.trialOfferMixedWithSkincareMinis,
  Modals.skincareMinis,
  Modals.trialOfferPostPurchase,
  Modals.trialOffer,
];

const useShowCriterias = () => {
  const flagsDoneFetching = useSelector(isFlagsDoneFetching);
  const showTrialOffer = useSelector(shouldShowTrialOffer);

  const isAuthenticated = useSelector(getIsAuthenticated);
  const isCouponsStatusLoading = useSelector(getIsCouponsStatusLoading);
  const couponsStatuses = useSelector(getCouponsStatuses);
  const showWelcomeKitVisibility = useSelector(shouldShowWelcomeKitVisibility);
  const hasActiveSkincareSubscription = useSelector(getHasActiveSkincareSubscription);
  const hasEitherHairOrSkinSubscription = useSelector(getHasEitherHairOrSkinSubscription);
  const { skincare: hasSkincareOrder } = useSelector(getHasOrdersByCategory);
  const hasActiveHaircareSubscription = useSelector(getHasActiveHaircareSubscription);
  const showEvergreenWelcomeKit = useSelector(shouldShowEvergreenWelcomeKit);
  const hasSkincareSubscriptionInAnyState = useSelector(getHasSkincareSubscriptionInAnyState);
  const hasHaircareSubscriptionInAnyState = useSelector(getHasHaircareSubscriptionInAnyState);
  const trialOfferFlagVariant = useSelector(getTrialOfferFlagVariant);
  const showSkincareMinisPhase2 = useSelector(shouldShowSkincareMinisPhase2);

  return {
    flagsDoneFetching,
    isAuthenticated,
    isCouponsStatusLoading,
    couponsStatuses,
    showWelcomeKitVisibility,
    showTrialOffer,
    hasActiveSkincareSubscription,
    hasEitherHairOrSkinSubscription,
    hasSkincareOrder,
    hasActiveHaircareSubscription,
    showEvergreenWelcomeKit,
    hasSkincareSubscriptionInAnyState,
    trialOfferFlagVariant,
    showSkincareMinisPhase2,
    hasHaircareSubscriptionInAnyState,
  };
};

const PromotionalLayout = () => {
  const showCriterias = useShowCriterias();
  const { pathname } = useLocation();

  const foundModal = priorityList.find(
    ({ shouldShow, includedPaths = () => ['*'], excludedPaths = () => [] }) => {
      const isIncluded = includedPaths(showCriterias.showEvergreenWelcomeKit).some(pattern =>
        Boolean(matchPath(pattern, pathname))
      );
      const isExcluded = excludedPaths(showCriterias.showEvergreenWelcomeKit).some(pattern =>
        Boolean(matchPath(pattern, pathname))
      );

      return isIncluded && !isExcluded && shouldShow(showCriterias);
    }
  );

  if (!foundModal) {
    return <Outlet />;
  }

  return (
    <>
      <Delay delay={foundModal?.delay}>
        <Persist
          persistKey={foundModal?.persistence?.key}
          persistStorage={foundModal?.persistence?.storage}
        >
          {foundModal?.render()}
        </Persist>
      </Delay>
      <Outlet />
    </>
  );
};

export default PromotionalLayout;
