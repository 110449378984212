import { Box, theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';

type HorizontalDividerProps = {
  text?: string;
  lineColor?: string;
  textColor?: string;
  textSize?: string;
};

const Divider = styled.div<{ lineColor?: string; textColor?: string; textSize?: string }>`
  display: flex;
  align-items: center;
  gap: ${theme.spacing['2x']};
  width: 100%;

  color: ${(props) => props.textColor || theme.colors.primary[400]};
  font-size: ${(props) => props.textSize || theme.typography.fontSize.md};

  &::before,
  &::after {
    position: relative;
    top: -2px;
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.lineColor || theme.colors.neutral[700]};
    content: '';
  }
`;

const HorizontalDivider = ({
  text = 'OR',
  lineColor,
  textColor,
  textSize,
}: HorizontalDividerProps) => {
  return (
    <Box display="flex" paddingY="4x">
      <Divider lineColor={lineColor} textColor={textColor} textSize={textSize}>
        {text}
      </Divider>
    </Box>
  );
};

export default HorizontalDivider;
