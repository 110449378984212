import { createApi } from '@reduxjs/toolkit/query/react';

import customBaseQuery from 'Services/customBaseQuery';

type FeedbackStatuses = Array<{
  category: 'haircare' | 'skincare';
  sub_category: 'supplements' | 'topicals';
  feedback_pubkey?: string;
  order_pubkey: string;
  status: 'not_started' | 'created' | 'completed' | 'disabled';
}>;

const feedbackApiSlice = createApi({
  reducerPath: 'feedbackApi',
  baseQuery: customBaseQuery,
  endpoints: (build) => ({
    feedbackStatusesQuery: build.query<FeedbackStatuses, void>({
      query: () => ({
        url: `v1/account/feedback`,
        endpointName: 'feedback/feedbackStatusesQuery',
      }),
      keepUnusedDataFor: 0, // no caching for now
    }),
  }),
});

export default feedbackApiSlice;

export const { feedbackStatusesQuery } = feedbackApiSlice.endpoints;
