import { createAsyncThunk } from '@reduxjs/toolkit';

import * as GiftService from 'Services/GiftService';
import { isFetchError } from 'Services/HTTPError';

import { dateToUtc } from 'utils/dateUtils';
import logSentryError from 'utils/logSentry';

import { trackHeapEvent } from 'dux/tracking/actions';
import { patchUser } from 'dux/user/actions';
import { getUserHasAcceptedTerms } from 'dux/user/selectors';

export const createGift = createAsyncThunk(
  'gift/createGift',
  async (values, { dispatch, getState }) => {
    const state = getState();

    if (!getUserHasAcceptedTerms(state)) {
      await dispatch(patchUser({ has_accepted_terms: true }));
    }

    try {
      const giftObject = {
        giftee_info: {
          email: values.emailGiftee,
          last_name: values.lastnameGiftee,
          first_name: values.firstnameGiftee,
        },
        message: values.message,
        enabled_at: dateToUtc(values.enabled_at),
        occasion: `${values.occasion}`,
        variant_slug: values.selectedGiftCard,
      };
      dispatch(trackHeapEvent('Gifting Page - For Them filled'));

      const gift = await GiftService.post(giftObject);
      return gift.pubkey;
    } catch (error) {
      if (!isFetchError(error)) logSentryError('[dux/gift] createGift', error); // ignore network errors

      throw error;
    }
  }
);
