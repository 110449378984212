import { useEffect } from 'react';

import { useAppSelector } from 'dux/app/hooks';

import * as TagSegmentService from 'Services/TagSegmentService';

import trialOfferGwpDecemberContent from 'assets/content/trialOfferCombGwpPromotionModal';
import trialOfferGwpJuly4thContent from 'assets/content/trialOfferJuly4thGwpPromotionModal';
import trialOfferGwpMysteryContent from 'assets/content/trialOfferMysteryGWPPromotionModal';
import trialOfferContent from 'assets/content/trialOfferPromotionModal';
import trialOfferGwpSummerSaleContent from 'assets/content/trialOfferSummerSaleGwpPromotionModal';
import trialOfferGwpToiletry from 'assets/content/trialOfferToiletryGWPPromotionModal';

import {
  getTrialOfferFlagVariant,
  isFlagsDoneFetching as getIsFlagsDoneFetching,
  shouldShowTrialOffer,
  trialOfferCombGwp,
  trialOfferEvergreen,
  trialOfferGwpJuly4th,
  trialOfferGwpMystery,
  trialOfferGwpSummerSale,
  trialOfferToiletryGwp,
} from 'dux/featureFlags/selectors';
import {
  getHasHaircareSubscriptionInAnyState,
  getHasSkincareSubscriptionInAnyState,
} from 'dux/user/selectors';

const trialOfferVariantContent = {
  [trialOfferEvergreen]: trialOfferContent,
  [trialOfferCombGwp]: trialOfferGwpDecemberContent,
  [trialOfferGwpMystery]: trialOfferGwpMysteryContent,
  [trialOfferToiletryGwp]: trialOfferGwpToiletry,
  [trialOfferGwpJuly4th]: trialOfferGwpJuly4thContent,
  [trialOfferGwpSummerSale]: trialOfferGwpSummerSaleContent,
};

export const useTrialOfferPromotionModalContent = () => {
  const hasHaircareSubscriptonInAnyState = useAppSelector(getHasHaircareSubscriptionInAnyState);
  const hasSkincareSubscriptonInAnyState = useAppSelector(getHasSkincareSubscriptionInAnyState);
  const variant = useAppSelector(getTrialOfferFlagVariant);

  const content = trialOfferVariantContent[variant];

  return {
    content:
      !hasHaircareSubscriptonInAnyState && !hasSkincareSubscriptonInAnyState
        ? content?.contentDefault
        : hasHaircareSubscriptonInAnyState && !hasSkincareSubscriptonInAnyState
        ? content?.contentSkincare
        : hasSkincareSubscriptonInAnyState && !hasHaircareSubscriptonInAnyState
        ? content?.contentHaircare
        : null,
    variant,
  };
};

export const useRedeemCouponForTrialOffer = () => {
  const showTrialOffer = useAppSelector(shouldShowTrialOffer);
  const isFlagsDoneFetching = useAppSelector(getIsFlagsDoneFetching);

  useEffect(() => {
    if (showTrialOffer && isFlagsDoneFetching) {
      TagSegmentService.post('onsite_trial_offer_50');
    }
  }, [showTrialOffer, isFlagsDoneFetching]);
};
