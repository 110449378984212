import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { styled } from '@prose-ui/legacy';

import { BaseBanner } from 'Blocks/Banner/BannerComponents';

const Container = styled(BaseBanner)`
  ${({ backgroundcolor }) => `
    background: ${backgroundcolor};
  `}
`;

const LinkCta = styled(Link)`
  ${({ theme }) => `
    color: ${theme.palette.common.white.light};
  `}
`;

const GiftWithPurchaseBanner = ({ backgroundColor, text, redirectionUrl }) => {
  return (
    <Container backgroundcolor={backgroundColor} data-testid="gwp-banner">
      <LinkCta data-click="gwp-lp" data-from="banner" to={redirectionUrl}>
        {text}
      </LinkCta>
    </Container>
  );
};

GiftWithPurchaseBanner.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  redirectionUrl: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default GiftWithPurchaseBanner;
