export const ExpressCheckoutAbTest = {
  TEST_ID: 'LixCqDh-QF-7qH7FKmJYDQ',
  name: 'Express Checkout AB Test - November 2021',
};

export const GrowthbookFakeExperimentExperiment = {
  TEST_ID: 'oHUXOZBgQHy7KCSh97Wjbg',
  name: 'GrowthBook Fake Experiment',
};

export const ShipNowExperiment = {
  TEST_ID: '69f993F50d20cd09C98198',
  name: 'Ship Now Launch AB test - February 2024',
};
export const EmailNameConsultationCaptureExperiment = {
  TEST_ID: '9b872cd7ac045f9ac3f066bec4a4729a',
  name: 'Name/Email Capture in HC Consultation - February 2024',
};

export const SubscribeSaveCheckoutModuleExperiment = {
  TEST_ID: 'e6bbd55979c051d98c73758c5e24b9a225928176778ad9c7a66d89b9c8ce7f36',
  name: 'Subscribe and save module at checkout',
};
export const VideoInHomepageHeroExperiment = {
  TEST_ID: '1984863891edebcd131523665f100921c87752a869b3534bf55100c2d5fe9f90',
  name: 'Homepage Video in Hero AB Test - August 2024',
};
export const FeedbackAwarenessCtaExperiment = {
  TEST_ID: '8d3bcb79c46428d165b23333ab9ebe39565579a98c4656d1b4c666a005836735',
  name: 'AB Test - "Refine your formula" vs "Provide feedback"',
};
