import { createContext, useMemo } from 'react';

import { Outlet } from 'react-router-dom';

import { useAppSelector } from 'dux/app/hooks';

import { type availableCountries, countriesCode } from 'constants/countries';
import currencies from 'constants/currencies';

import { getUserCurrency, getUserGeolocationGuessedCountry } from 'dux/user/selectors';

export type CountryContextType = {
  country: (typeof availableCountries)[number];
  currency: keyof typeof currencies;
};

export const CountryContext = createContext<CountryContextType>({
  country: countriesCode.US,
  currency: currencies.USD,
});

export const CountryProvider = () => {
  const geolocatedCountry: CountryContextType['country'] = useAppSelector(
    getUserGeolocationGuessedCountry,
  );
  const userCurrency: CountryContextType['currency'] = useAppSelector(getUserCurrency);

  const countryContextValue = useMemo(
    () => ({
      country: geolocatedCountry,
      currency: userCurrency,
    }),
    [geolocatedCountry, userCurrency],
  );

  return (
    <CountryContext.Provider value={countryContextValue}>
      <Outlet />
    </CountryContext.Provider>
  );
};
