import PropTypes from 'prop-types';
import { forbidExtraProps } from 'airbnb-prop-types';

import { Link } from 'react-router-dom';

import dayjs from 'dayjs';
import { makeStyles } from 'legacyStyles';

import { subscriptionCategories } from 'constants/subscriptions';

import { capitalizeFirstLetter } from 'utils/textUtils';

import Typography from './Typography';

const useStyles = makeStyles(theme => ({
  root: {
    /* class to be overriden by parent */
  },
  link: {
    color: theme.palette.common.grey.dark,
    fontWeight: 600,
    textDecoration: 'underline',
  },
}));

// This component is used at 3 different places, mobile checkout, desktop checkout and account page
const SubscriptionTicketLegalWording = ({
  ctaWording,
  paragraph,
  bold,
  align,
  category,
  chargeDate,
  ...props
}) => {
  const { classes } = useStyles(undefined, props?.classes ? { props } : undefined);
  const productsCategory =
    category === subscriptionCategories.SKINCARE
      ? subscriptionCategories.SKINCARE
      : subscriptionCategories.HAIRCARE;

  return (
    <Typography
      align={align}
      bold={bold}
      className={classes.root}
      color="grey"
      paragraph={paragraph}
      variant="p3"
    >
      I authorize Prose to charge my payment account{' '}
      {chargeDate ? `on ${dayjs(chargeDate).format('MM/DD/YY')}` : 'now'} and on a recurring basis
      at the selected frequency until I cancel at the then-current rate for the subscribed products
      in the order summary. I may cancel my subscription without incurring further charges at any
      time before 11:59 pm PT prior to the charge date by visiting my account, selecting Your
      Subscription, selecting Edit, and selecting Cancel My{' '}
      {capitalizeFirstLetter(productsCategory)}
      Subscription, or by emailing hello@prose.com. By clicking “{ctaWording}” I acknowledge I have
      read and agree to the above terms, and the Prose{' '}
      <Link className={classes.link} target="_blank" to="/terms/subscription">
        Subscription Terms of Service
      </Link>{' '}
      and{' '}
      <Link className={classes.link} target="_blank" to="/terms">
        End User License Agreement
      </Link>
      .
    </Typography>
  );
};

SubscriptionTicketLegalWording.propTypes = forbidExtraProps({
  ctaWording: PropTypes.string.isRequired,
  paragraph: PropTypes.bool,
  bold: PropTypes.bool,
  align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
  category: PropTypes.string,
  chargeDate: PropTypes.string,
  classes: PropTypes.shape({}),
});

SubscriptionTicketLegalWording.defaultProps = {
  align: 'center',
  paragraph: false,
  bold: true,
  category: subscriptionCategories.HAIRCARE,
  classes: null,
  chargeDate: null,
};

export default SubscriptionTicketLegalWording;
