import { useAppSelector } from 'dux/app/hooks';

import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';

import Typography from 'Components/Typography';

import { productsCategories } from 'constants/products';

import * as skincareWelcomeKitContent from 'assets/content/skincareWelcomeKit';

import * as checkoutCartSelectors from 'dux/checkoutCart/selectors';
import {
  shouldShowPostPurchaseCrossSellOffer,
  shouldShowTrialOffer,
} from 'dux/featureFlags/selectors';
import {
  getHasHaircareSubscriptionInAnyState,
  getHasSkincareSubscriptionInAnyState,
} from 'dux/user/selectors';
import { Variant } from 'dux/featureFlags/postPurchaseCrossSellOfer';
import { useSkincareWelcomeKitOffer } from 'dux/promotions/hooks';

import useCheckoutCategory from '../hooks/useCheckoutCategory';

const Wrapper = styled.div<{ backgroundColor: string }>`
  width: 100%;
  margin-top: 8px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  text-align: center;
  padding: 10px 8px 8px 8px;
  border-radius: 4px;

  ${legacyTheme.breakpoints.up('sm')} {
    margin-bottom: 16px;
  }
`;

const OrderSummaryPromoCommunication = () => {
  const subscriptionDiscount = useAppSelector(checkoutCartSelectors.getSubscriptionDiscount);
  const checkoutCategory = useCheckoutCategory();

  // @ts-expect-error feature flags selectors are not typed yet
  const showPostPurchaseCrossSellOffer = useAppSelector(shouldShowPostPurchaseCrossSellOffer);
  const showTrialOffer = useAppSelector(shouldShowTrialOffer);
  const hasHaircareSubscriptionInAnyState = useAppSelector(getHasHaircareSubscriptionInAnyState);
  const hasSkincareSubscriptionInAnyState = useAppSelector(getHasSkincareSubscriptionInAnyState);

  const cartHasSubscription = useAppSelector(checkoutCartSelectors.cartHasSubscription);
  const hasSkincareMinisInItems = useAppSelector(checkoutCartSelectors.getHasSkincareMinisInItems);
  const hasSkincareMinisInCatalog = useAppSelector(
    checkoutCartSelectors.getHasSkincareMinisInCatalog,
  );

  const cartCatalogItems = useAppSelector(checkoutCartSelectors.getSelectableProducts) as Array<{
    category: string;
    subscription: { active: boolean };
  }>;

  const checkIsEverySkincareCartItemSubscribed = () =>
    cartCatalogItems
      .filter((item) => item.category === 'formula')
      .every((formulaItem) => formulaItem.subscription.active === true && formulaItem);

  const isSkincareWelcomeKitOfferAvailable = useSkincareWelcomeKitOffer();
  const showSkincareWelcomeKitOffer =
    isSkincareWelcomeKitOfferAvailable && checkoutCategory === productsCategories.SKINCARE;
  const isSkincareWelcomeKitOfferApplied =
    showSkincareWelcomeKitOffer && checkIsEverySkincareCartItemSubscribed();

  const show50DiscountMessage =
    (showTrialOffer &&
      ((checkoutCategory === productsCategories.HAIRCARE && !hasHaircareSubscriptionInAnyState) ||
        (checkoutCategory === productsCategories.SKINCARE &&
          !hasSkincareSubscriptionInAnyState &&
          !hasSkincareMinisInCatalog))) ||
    (showPostPurchaseCrossSellOffer === Variant.FIRST_ORDER_50_OFFER &&
      checkoutCategory === productsCategories.SKINCARE);

  const is50DiscountApplied = show50DiscountMessage && cartHasSubscription;

  const isSubscriptionDiscountApplied = cartHasSubscription;

  return (
    <>
      {isSkincareWelcomeKitOfferApplied && (
        <Wrapper
          backgroundColor={skincareWelcomeKitContent.orderSummaryCommunication.backgroundColor}
        >
          {/* @ts-expect-error - Typography has no TS types for now */}
          <Typography
            data-testid="order-summary-promo-communication"
            markupName="span"
            variant="p2"
          >
            {skincareWelcomeKitContent.orderSummaryCommunication.content}
          </Typography>
        </Wrapper>
      )}
      {!isSkincareWelcomeKitOfferApplied && hasSkincareMinisInItems && (
        <Wrapper backgroundColor={theme.colors.highlight[200]}>
          {/* @ts-expect-error - Typography has no TS types for now */}
          <Typography
            data-testid="order-summary-promo-communication"
            markupName="span"
            variant="p2"
          >
            <b>FREE</b> Skincare Starter Set added!
          </Typography>
        </Wrapper>
      )}
      {!isSkincareWelcomeKitOfferApplied && !hasSkincareMinisInItems && is50DiscountApplied && (
        <Wrapper backgroundColor={theme.colors.tertiary[200]}>
          {/* @ts-expect-error - Typography has no TS types for now */}
          <Typography
            data-testid="order-summary-promo-communication"
            markupName="span"
            variant="p2"
          >
            <b>50% off</b> + <b>free shipping</b> applied!
          </Typography>
        </Wrapper>
      )}
      {!isSkincareWelcomeKitOfferApplied &&
        !hasSkincareMinisInItems &&
        !is50DiscountApplied &&
        isSubscriptionDiscountApplied && (
          <Wrapper backgroundColor={theme.colors.highlight[200]}>
            {/* @ts-expect-error - Typography has no TS types for now */}
            <Typography
              data-testid="order-summary-promo-communication"
              markupName="span"
              variant="p2"
            >
              <b>{subscriptionDiscount}% off</b> + <b>free shipping</b> applied!
            </Typography>
          </Wrapper>
        )}
    </>
  );
};

export default OrderSummaryPromoCommunication;
