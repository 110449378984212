import { getJson, patchJson, postJson } from './APIClient';

export const get = () => getJson('/v1/account/hair_profile/');

export const fetchSkincareTextures = () =>
  getJson('/v1/account/hair_profile/skeleton_recommendations?category=skincare');

export const patch = data => patchJson('/v1/account/hair_profile/', { ...data, source: 'website' });

export const fetchAggressors = () => getJson('/v1/account/hair_profile/aggressors');

export const fetchScoring = () => getJson('/v1/account/hair_profile/scoring');
export const fetchSkincareScoring = () =>
  getJson('/v1/account/hair_profile/scoring?category=skincare');

export const fetchFragrances = async () => {
  const results = await getJson('/v1/account/hair_profile/fragrances');
  return results.fragrances;
};

export const fetchSkincareFragrances = async () => {
  const results = await getJson('/v1/account/hair_profile/fragrances?category=skincare');
  return results.fragrances;
};

export const fetchFragrancesFeedback = async () => {
  const results = await getJson('/v1/account/hair_profile/fragrances?use_feedback=true');
  return results.fragrances;
};

export const fetchIngredients = () => getJson('/v1/account/hair_profile/ingredients');

export const fetchSkincareIngredients = () =>
  getJson('/v1/account/hair_profile/ingredients?category=skincare');

export const fetchSkincareIncis = () => getJson('/v1/account/hair_profile/incis?category=skincare');
export const fetchHaircareIncis = () => getJson('/v1/account/hair_profile/incis?category=haircare');

export const getHairProfileProductVariantBySlug = data =>
  postJson('/v1/account/hair_profile/variant', data);

export const getRecommendationBySlug = data =>
  postJson('/v1/account/hair_profile/recommendation_slug', data);
