import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Navigate, useLocation } from 'react-router-dom';

import { connect } from 'react-redux';

import UserFraud from 'Apps/Main/Scenes/UserFraud';

import Delay from 'Components/Delay';

import * as CouponService from '../Services/CouponService';
import { isFetchError, isInvalidCoupon } from '../Services/HTTPError';

import ErrorScene from 'Scenes/ErrorScene';
import LoadingScene from 'Scenes/LoadingScene';

import { forwardUTMIfNeeded, toRelativeURL } from 'utils/forwardUTM';
import { getVisitorStatus, isReferralCodeValid } from 'utils/friendBuy';

import * as trackingActions from 'dux/tracking/actions';
import {
  openRootToast as openRootToastAction,
  setReferral as setReferralAction,
} from 'dux/user/actions';
import { getIsAuthenticated, getIsAuthenticating } from 'dux/auth/selectors';
import { fetchCouponsStatuses as fetchCouponsStatusesAction } from 'dux/couponsStatuses/thunks';

/**
 * Sets a referral code in the store if user is likelly allowed to use that code
 * In case user is logged, attach coupon to the user right away
 */

const RedeemLanding = ({
  isAuthenticating,
  isAuthenticated,
  setReferral,
  trackBreadcrumb,
  openRootToast,
  fetchCouponStatuses,
}) => {
  const [isLoading, setIsLoading] = useState(isAuthenticating || isAuthenticated);
  const [error, setError] = useState(null);
  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);
  const referral = {
    // fbuy_ref_code is legacy but still used by email campaigns, DO NOT remove it upon friendbuy v2 migration
    code: params.get('referralCode') || params.get('fbuy_ref_code'),
    source: params.get('utm_source'),
  };
  const fraud = params.get('fbuy_fraud');
  /* forwardUTMIfNeeded implem uses URL constructor wich requires absolute urls instead of relative ones,
   thus the document.location.origin use. */
  const next = `${document.location.origin}${params.get('next') ?? '/'}`;
  const targetURL = forwardUTMIfNeeded(document.location, next);

  useEffect(() => {
    trackBreadcrumb(`${pathname}${search}`);
  }, [pathname, search]);

  useEffect(() => {
    let didCleanup = false;

    // Only called in friendbuy v2
    async function createCoupon() {
      if (referral.code) {
        getVisitorStatus(async status => {
          if (isReferralCodeValid(status)) {
            try {
              await CouponService.create({
                code: referral.code,
                source: referral.source,
              });
              setReferral({ code: referral.code, source: referral.source });
              openRootToast();
              if (!didCleanup) {
                setIsLoading(false);
              }
            } catch (err) {
              setReferral(null);
              if (!didCleanup) {
                setError(err);
              }
            }
          } else {
            setReferral(null);
            if (!didCleanup) {
              setError({ code: 'coupon_invalid' });
            }
          }
        });
      }
    }

    // call createCoupon only if the user is authenticated
    if (!isAuthenticating && isAuthenticated) {
      createCoupon();
    }

    // whether or not the loading should be shown
    if (!isAuthenticating && !isAuthenticated) {
      // put the coupon into memory as the customer isn't authenticated
      setReferral({ code: referral.code, source: referral.source });
      setIsLoading(false);
    }

    return () => {
      didCleanup = true;
    };
  }, [
    isAuthenticating,
    isAuthenticated,
    referral.code,
    referral.source,
    openRootToast,
    setReferral,
  ]);

  if (error && isInvalidCoupon(error)) {
    return <Navigate replace to="/redeem/invalid-code" />;
  }

  if (error) {
    return <ErrorScene isOffline={isFetchError(error)} />;
  }

  if (fraud) {
    return <UserFraud />;
  }

  if (isLoading) {
    return (
      <Delay>
        {({ pastDelay }) => {
          return <LoadingScene pastDelay={pastDelay} />;
        }}
      </Delay>
    );
  }

  /*
   * refetch coupon remote status upon leaving the page;
   * fancy look but real utility here of the [comma operator](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Comma_operator)
   */
  return (
    referral.code && fetchCouponStatuses(),
    (<Navigate replace to={referral.code ? toRelativeURL(targetURL) : 'invalid-code'} />)
  );
};

RedeemLanding.propTypes = {
  isAuthenticating: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  setReferral: PropTypes.func.isRequired,
  openRootToast: PropTypes.func.isRequired,
  trackBreadcrumb: PropTypes.func,
  fetchCouponStatuses: PropTypes.func.isRequired,
};

RedeemLanding.defaultProps = {
  trackBreadcrumb: () => {},
};

const mapStateToProps = state => ({
  isAuthenticating: getIsAuthenticating(state),
  isAuthenticated: getIsAuthenticated(state),
});

const mapDispatchToProps = {
  setReferral: setReferralAction,
  openRootToast: openRootToastAction,
  trackBreadcrumb: trackingActions.trackBreadcrumb,
  fetchCouponStatuses: fetchCouponsStatusesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(RedeemLanding);
