import get from 'lodash/fp/get';

import logSentryError from 'utils/logSentry';

import { authClient } from './AuthClient';

const customBaseQuery = async (
  { url, method = 'get', params = undefined, ...options } /* ,  api, extraOptions */
) => {
  try {
    const result = await authClient(url, { json: params, method });

    return { data: await result.json() };
  } catch (error) {
    const queryName = get('queryName', options) ?? 'customBaseQuery';
    const endpointName = get('endpointName', options) ?? url;
    logSentryError(`[${endpointName}] ${queryName}`, error);

    return { error };
  }
};

export default customBaseQuery;
