import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs/plugin/utc';

import config from '../Config';

dayjs.extend(dayjsPluginUTC);

/** *
 * (from,to)
 * from : beggining date if no date it's gonna be today
 * to: maximum timeframe in weeks, default is 12
 * return a string in format YYYY-MM-DD for input max date
 ** */
export const createMaxDateForInput = ({ date, timeframe } = {}) => {
  const date2 = date ? dayjs(date) : getNow();
  const timeframe2 = timeframe || config.defaultWeeksAhead;

  return dayjs(date2).utc().add(timeframe2, 'weeks');
};

/** *
 * return a string in format YYYY-MM-DD for input min date
 ** */
// const createMinDateForInput = date => {
//   return date ? dayjs(date) : dayjs();
// };

/** *
 *
 * Return a dayjsObject
 */
export const getNow = () => {
  return dayjs();
};

/**
 * format date YYYY-MM-DD
 */
export const getFormatYYYYMMDD = date => {
  return date ? dayjs(date).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
};

/**
 * dateToUtc
 */
export const dateToUtc = date => {
  if (date) {
    if (date === getFormatYYYYMMDD()) {
      date = new Date().toISOString();
      return date;
    }

    const d = new Date(date);
    d.setUTCHours(config.giftScheduleHour);
    return d.toISOString();
  }
  const d = new Date();
  d.setUTCHours(config.giftScheduleHour);
  return d.toISOString();
};

export const getFormatMMDD = date => {
  return dayjs(date).format('MM/DD');
};
