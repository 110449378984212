export const LOAD_ADDRESSES_REQUEST = 'LOAD_ADDRESSES_REQUEST';
export const LOAD_ADDRESSES_SUCCESS = 'LOAD_ADDRESSES_SUCCESS';
export const LOAD_ADDRESSES_FAILURE = 'LOAD_ADDRESSES_FAILURE';

export const SET_NEW_ADDRESS = 'SET_NEW_ADDRESS';

export const ADDRESS_VALIDATION_CHOICE = 'ADDRESS_VALIDATION_CHOICE';
export const ADDRESS_VALIDATION_INVALID_FIELDS = 'ADDRESS_VALIDATION_INVALID_FIELDS';
export const ADDRESS_VALIDATION_RECOMMENDED_FIELDS = 'ADDRESS_VALIDATION_RECOMMENDED_FIELDS';
export const ADDRESS_VALIDATION_CHOICE_RESET = 'ADDRESS_VALIDATION_CHOICE_RESET';
