import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import { getGuestTokens } from 'Services/Auth';

import { getIsAuthenticated, getIsAuthenticating } from 'dux/auth/selectors';
import { shouldShowUnlockAccessories } from 'dux/featureFlags/selectors';
import { actions } from 'dux/guestAuth/slice';

import { guestUserSignup } from './thunks';

export const useFetchGuestAuthIfNeeded = () => {
  const dispatch = useAppDispatch();
  const isGuestAuthenticated = getGuestTokens();
  const isAuthenticating = useAppSelector(getIsAuthenticating);
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  // @ts-expect-error feature flags selectors are not typed yet
  const showUnlockAccessories = useAppSelector(shouldShowUnlockAccessories);

  useEffect(() => {
    if (showUnlockAccessories && !isAuthenticated && !isAuthenticating) {
      if (!isGuestAuthenticated) {
        dispatch(guestUserSignup());
      } else {
        dispatch(actions.setGuestAuthenticated());
      }
    }
  }, [isGuestAuthenticated, showUnlockAccessories, isAuthenticating, isAuthenticated]);
};
