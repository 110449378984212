import { createABTestHook } from 'abTesting/hooks';
import { EmailNameConsultationCaptureExperiment } from 'abTesting/testConfigs';

import {
  getActiveOrVariantForEmailNameConsultationCaptureAbTest,
  isEmailNameConsultationCaptureAbTestInExperiment,
} from 'dux/featureFlags/selectors';

export const useEmailNameConsultationCaptureAbTest = createABTestHook({
  isInExperimentSelector: isEmailNameConsultationCaptureAbTestInExperiment,
  variantNameSelector: getActiveOrVariantForEmailNameConsultationCaptureAbTest,
  experimentConfig: EmailNameConsultationCaptureExperiment,
});
