import { useAppSelector } from 'dux/app/hooks';

import { productsCategories } from 'constants/products';

import { getCartShippingAddressV2, getCartV2IsFree } from 'dux/cartV2/selectors';
import * as checkoutCartSelectors from 'dux/checkoutCart/selectors';

import useCheckoutCategory from './useCheckoutCategory';

const useSelectAddressInfosByCartType = () => {
  const checkoutCategory = useCheckoutCategory();

  // Cart V1
  const isFreeAndCartHasNoSubscription = useAppSelector(
    checkoutCartSelectors.getIsFreeAndCartHasNoSubscription
  );
  const shippingAddress = useAppSelector(checkoutCartSelectors.getCartShippingAddress);

  // Cart V2
  const shippingAddressV2 = useAppSelector(getCartShippingAddressV2);
  const isFree = useAppSelector(getCartV2IsFree);

  if (checkoutCategory === productsCategories.ACCESSORIES) {
    return {
      shippingAddress: shippingAddressV2,
      isFree,
    };
  }

  return {
    shippingAddress,
    isFree: isFreeAndCartHasNoSubscription,
  };
};

export default useSelectAddressInfosByCartType;
