import { publicReview } from './constants/feedbackSlugs';
import conditionerAntiBrass from './questions/hair/conditionerAntiBrass';
import conditionerContinueAntiBrass from './questions/hair/conditionerContinueAntiBrass';
import conditionerImpact from './questions/hair/conditionerImpact';
import conditionerSatisfaction from './questions/hair/conditionerSatisfaction';
import curlCreamDefinition from './questions/hair/curlCreamDefinition';
import curlCreamFrizz from './questions/hair/curlCreamFrizz';
import curlCreamMoisture from './questions/hair/curlCreamMoisture';
import curlCreamSatisfaction from './questions/hair/curlCreamSatisfaction';
import dryShampooCleansing from './questions/hair/dryShampooCleansing';
import dryShampooSatisfaction from './questions/hair/dryShampooSatisfaction';
import dryShampooVolume from './questions/hair/dryShampooVolume';
import fragranceIntensitySatisfaction from './questions/hair/fragranceIntensitySatisfaction';
import fragranceTransitionScreen from './questions/hair/fragranceTransitionScreen';
import haircareFragranceNewChoice from './questions/hair/haircareFragranceNewChoice';
import haircareNewFragrance from './questions/hair/haircareNewFragrance';
import hairCurlDefinition from './questions/hair/hairCurlDefinition';
import hairDryness from './questions/hair/hairDryness';
import hairFrizziness from './questions/hair/hairFrizziness';
import hairMaskImpact from './questions/hair/hairMaskImpact';
import hairMaskSatisfaction from './questions/hair/hairMaskSatisfaction';
import hairOilFragranceIntensitySatisfaction from './questions/hair/hairOilFragranceIntensitySatisfaction';
import hairOilFrizz from './questions/hair/hairOilFrizz';
import hairOilNourishment from './questions/hair/hairOilNourishment';
import hairOilSatisfaction from './questions/hair/hairOilSatisfaction';
import hairResults from './questions/hair/hairResults';
import hairSatisfaction from './questions/hair/hairSatisfaction';
import hairShine from './questions/hair/hairShine';
import hairTangles from './questions/hair/hairTangles';
import hairTransitionScreen from './questions/hair/hairTransitionScreen';
import hairVolume from './questions/hair/hairVolume';
import leaveInConditionerFrizz from './questions/hair/leaveInConditionerFrizz';
import leaveInConditionerManageable from './questions/hair/leaveInConditionerManageable';
import leaveInConditionerMoisture from './questions/hair/leaveInConditionerMoisture';
import leaveInConditionerSatisfaction from './questions/hair/leaveInConditionerSatisfaction';
import leaveInConditionerTangles from './questions/hair/leaveInConditionerTangles';
import overallExperience from './questions/hair/overallExperience';
import overallTransitionScreen from './questions/hair/overallTransitionScreen';
import productsTransitionScreen from './questions/hair/productsTransitionScreen';
import scalpFlakiness from './questions/hair/scalpFlakiness';
import scalpMaskFragranceSatisfaction from './questions/hair/scalpMaskFragranceSatisfaction';
import scalpMaskSatisfaction from './questions/hair/scalpMaskSatisfaction';
import scalpOiliness from './questions/hair/scalpOiliness';
import scalpSatisfaction from './questions/hair/scalpSatisfaction';
import scalpSensitivity from './questions/hair/scalpSensitivity';
import scalpTransitionScreen from './questions/hair/scalpTransitionScreen';
import shampooImpact from './questions/hair/shampooImpact';
import shampooSatisfaction from './questions/hair/shampooSatisfaction';
import stylingGelFlexible from './questions/hair/stylingGelFlexible';
import stylingGelHold from './questions/hair/stylingGelHold';
import stylingGelSatisfaction from './questions/hair/stylingGelSatisfaction';
import publicReviewScreen from './questions/shared/publicReview';

const questions = [
  // INTRO
  overallTransitionScreen,
  overallExperience,

  // SCALP QUESTIONS
  scalpTransitionScreen,
  scalpSatisfaction,
  scalpSensitivity,
  scalpFlakiness,
  scalpOiliness,

  // HAIR QUESTIONS
  hairTransitionScreen,
  hairSatisfaction,
  hairDryness,
  hairShine,
  hairCurlDefinition,
  hairFrizziness,
  hairTangles,
  hairVolume,

  // PRODUCTS
  productsTransitionScreen,

  // HAIR MASK
  hairMaskSatisfaction,
  hairMaskImpact,

  // SCALP MASK
  scalpMaskSatisfaction,

  // SHAMPOO
  shampooSatisfaction,
  shampooImpact,

  // CONDITIONER
  conditionerSatisfaction,
  conditionerImpact,
  conditionerAntiBrass,
  conditionerContinueAntiBrass,

  // DRY SHAMPOO
  dryShampooSatisfaction,
  dryShampooCleansing,
  dryShampooVolume,

  // HAIR OIL
  hairOilSatisfaction,
  hairOilNourishment,
  hairOilFrizz,

  // CURL CREAM
  curlCreamSatisfaction,
  curlCreamDefinition,
  curlCreamFrizz,
  curlCreamMoisture,

  // LEAVE-IN CONDITIONER
  leaveInConditionerSatisfaction,
  leaveInConditionerManageable,
  leaveInConditionerTangles,
  leaveInConditionerFrizz,
  leaveInConditionerMoisture,

  // STYLING GEL
  stylingGelSatisfaction,
  stylingGelFlexible,
  stylingGelHold,

  // FRAGRANCE
  fragranceTransitionScreen,
  scalpMaskFragranceSatisfaction,
  hairOilFragranceIntensitySatisfaction,
  fragranceIntensitySatisfaction,
  haircareNewFragrance,
  haircareFragranceNewChoice,

  // RESULTS
  hairResults,

  // PUBLIC REVIEW
  { ...publicReviewScreen, route: `/feedback/haircare/${publicReview}` },
];

export default questions;
