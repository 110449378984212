import { createSlice } from '@reduxjs/toolkit';

import { userSignout } from 'dux/auth/actions';
import { loadCartThunk } from 'dux/checkoutCart/thunks';

import { createGift } from './thunks';

const initialState = {
  isSaving: false,
  error: null,
  selectedGift: null,
  giftPubkey: null,
};

const giftSlice = createSlice({
  name: 'gift',
  initialState,
  reducers: {
    setSelectedGift: (draft, action) => {
      draft.selectedGift = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      // reinitialize state upon signout
      .addCase(userSignout, () => initialState)

      .addCase(createGift.pending, draft => {
        draft.isSaving = true;
      })
      .addCase(createGift.fulfilled, (draft, action) => {
        draft.giftPubkey = action.payload;
        draft.error = null;
        draft.isSaving = false;
      })
      .addCase(createGift.rejected, (draft, action) => {
        draft.error = action.error;
        draft.isSaving = false;
      })

      // --- external duxes ---
      .addCase(loadCartThunk.pending, () => initialState);
  },
});

export const { reducer, actions } = giftSlice;
