import { combineReducers } from '@reduxjs/toolkit';

import { addresses, addressValidation } from 'dux/checkoutAddresses/reducers';
import cart from 'dux/checkoutCart/reducers';
import order from 'dux/checkoutOrder/reducers';
import { payment, paypal, stripe } from 'dux/checkoutPayment/slice';

export default combineReducers({
  addressValidation,
  addresses,
  cart,
  order,
  payment,
  paypal,
  stripe,
});
