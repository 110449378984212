import myHairLifestyleTransitionScreen from 'Apps/Consultation/questions/myLifestyleTransitionScreen';
import mySkinLifestyleTransitionScreen from 'Apps/Consultation/questions/skin/myLifestyleTransistionScreen';

import {
  FIRST_HAIRCARE_QUESTION_ROUTE,
  FIRST_SKINCARE_QUESTION_ROUTE,
  SIGN_IN_FIRST_QUERY,
  signInFirstConsultationRouteByCategory,
} from './constants';

const pickBy = (o = {}, f) =>
  Object.keys(o).reduce((acc, key) => (f(o[key], key) ? { ...acc, [key]: o[key] } : acc), {});

// extracts the parts of the suvey object that corresponds to answers
export const getConsultationAnswers = consultation =>
  pickBy(consultation, (_value, key) =>
    ['profile_', 'diag_', 'pref_', 'goal_'].some(prefix => key.startsWith(prefix))
  );

export const formatSignupUserFromFormikToApi = values => ({
  email: values.email,
  username: values.email,
  first_name: values.firstName,
  last_name: values.lastName,
  has_accepted_terms: true,
});

export const setSignupFormikFieldError = actions => (field, value) => {
  switch (field) {
    case 'first_name':
      return actions.setFieldError('firstName', value);
    case 'last_name':
      return actions.setFieldError('lastName', value);
    case 'email':
    case 'username':
    default:
      return actions.setFieldError('email', value);
  }
};

const hairTypeMap = {
  dry: 0,
  normal: 1,
  oily: 2,
  oily_dry: 2,
};

const regreaseDelayMap = {
  dont_know: 0,
  within_one_day: 2,
  second_day: 1,
  three_more_days: 0,
  never: 0,
};

const normalize =
  ({ min, max }) =>
  value =>
    Math.max(min, Math.min(max, value));

const normalizeSebum = (hairType, greasyDays) => {
  if (!(hairType in hairTypeMap)) {
    throw new Error('Unknown value for hair type');
  }
  if (!(greasyDays in regreaseDelayMap)) {
    throw new Error('Unknown value for greasy days');
  }
  const score = hairTypeMap[hairType];
  const booster = regreaseDelayMap[greasyDays];
  return normalize({ min: 0, max: 4 })(score + booster);
};

// diag_sebum_level is computed when diag_regrease_delay and diag_hair_type are both available
export const withSebumLevel = (answer, answers) => {
  const newAnswers = { ...answers, ...answer };
  if (newAnswers.diag_hair_type && newAnswers.diag_regrease_delay) {
    return {
      ...answer,
      diag_sebum_level: normalizeSebum(newAnswers.diag_hair_type, newAnswers.diag_regrease_delay),
    };
  }
  return answer;
};

export const getPublicConsultationRoutes = category => [
  '/consultation',
  '/consultation/category',
  `/consultation/${category}`,
  FIRST_HAIRCARE_QUESTION_ROUTE,
  // To be removed when email-name-consultation-capture-ab-test is over
  '/consultation/haircare/firstname',
  '/consultation/haircare/my-hair/texture',
  FIRST_SKINCARE_QUESTION_ROUTE,
  signInFirstConsultationRouteByCategory[category],
  myHairLifestyleTransitionScreen.route,
  mySkinLifestyleTransitionScreen.route,
];

export const getPublicConsultationRoutesQueries = [SIGN_IN_FIRST_QUERY];
