import { Link } from 'react-router-dom';

import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';

import * as bannerContent from 'assets/content/banner';

// Banners (top level wrapper)
export const BaseBanner = styled.div`
  ${legacyTheme.typography.p2};

  width: 100%;
  height: ${legacyTheme.props.bannerHeight};
  z-index: ${legacyTheme.zIndex.appBar};

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  transition: background 0.3s ease;
  color: ${theme.colors.neutral[100]};

  ${legacyTheme.breakpoints.match('md')} {
    justify-content: ${({ props }) => (props?.hasLink ? 'space-between' : 'center')};
    padding-left: ${({ props }) => (props?.hasLink ? '20px' : 'inherit')};
  }

  ${legacyTheme.breakpoints.match('sm')} {
    justify-content: center;
    padding-left: inherit;
  }

  & em {
    font-style: normal;
    text-decoration: underline;
  }
`;

export const LoadingBanner = styled(BaseBanner)`
  background: ${theme.colors.neutral[100]};
`;

export const DefaultBanner = styled(BaseBanner)`
  background: ${theme.colors.primary[400]};
`;

export const GiveawayBanner = styled(BaseBanner)`
  background: ${theme.colors.primary['300']};
`;

export const MembershipBanner = styled(BaseBanner)`
  color: ${theme.colors.neutral[900]};
  background-color: ${theme.colors.tertiary[200]};
`;

// Links second level components
export const LinkCta = styled(Link)`
  color: ${theme.colors.neutral[100]};
`;

export const LinkCtaNoir = styled(Link)`
  color: ${theme.colors.primary[400]};
`;

export const LinkCtaWhite = styled(Link)`
  color: white;
  text-decoration: underline;
`;

const LongLinkCta = styled(Link)`
  display: flex;
  flex-direction: column;

  color: ${theme.colors.neutral[100]};
  text-decoration: underline;
  letter-spacing: 0.5px;

  ${legacyTheme.breakpoints.up('xs')} {
    display: block;

    border-bottom: 1px solid white;
    line-height: 10px;
    text-decoration: none;
  }
`;

export const ClickableCta = styled(DefaultBanner)`
  cursor: pointer;
  text-decoration: underline;
`;

export const CheckoutBanner = styled(DefaultBanner)`
  background-color: ${theme.colors.primary[400]};
  color: ${theme.colors.neutral[100]};
`;

// Side CTA Components
const DesktopBannerSideCTA = styled.div`
  position: absolute;
  right: 20px;

  height: ${legacyTheme.props.bannerHeight};
  padding-top: 10px;

  display: flex;

  ${legacyTheme.breakpoints.match('sm')} {
    display: none;
  }
`;

// useful to wrap text supposed to open a modal
export const UnstyledButton = styled.button`
  cursor: pointer;

  padding: 0;
  line-height: 10px;

  outline: inherit;
  appearance: none;
  border: none;
  border-bottom: 1px solid currentColor;
  background: none;
  color: ${({ color }) =>
    (color === 'noir' && theme.colors.primary[400]) || theme.colors.neutral[100]};
`;

export const DefaultBannerContent = () => (
  <LongLinkCta data-click="refer" data-from="banner" data-testid="default-banner" to="/reviews">
    {bannerContent.defaultContent}
  </LongLinkCta>
);

export const ReferFriendSideLink = () => (
  <DesktopBannerSideCTA>
    <LinkCtaNoir
      data-click="refer"
      data-from="banner"
      data-testid="referral-cta-side-banner"
      to="/account/refer-a-friend"
    >
      {bannerContent.referralCta}
    </LinkCtaNoir>
  </DesktopBannerSideCTA>
);
