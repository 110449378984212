import type { ReactNode } from 'react';

import { breakpoints } from '@prose-ui/foundations/grid';
import { styled } from '@prose-ui/legacy';
import { mediaMinWidth } from '@prose-ui/utils/media';
import type { HeightCSSValue, HeightProp } from '@prose-ui/utils/props';
import { cssHeight } from '@prose-ui/utils/props';
import type { ImageProps as NextImageProps } from 'next/image';
import Image from 'next/image';

type ContentPositions = 1 | 2;

type ImageParams = {
  src: string;
  imgObjectPosition?: string;
};
type PerDeviceImagesParams = {
  mobile: ImageParams;
  desktop: ImageParams & { alt: string };
};
type NextImageParams = ImageParams & NextImageProps;

type ResponsiveProp<T> = { mobile: T; desktop: T };

const RootContainer = styled.div<{
  contentPositions: NonNullable<Props['contentPositions']>;
  minHeight: ResponsiveProp<HeightCSSValue>;
}>`
  position: relative;
  overflow: hidden;
  width: 100%;

  display: grid;

  grid-template-rows: ${({ contentPositions }) =>
    contentPositions.mobile === 1 ? 'min-content auto' : 'auto min-content'};

  ${mediaMinWidth('md')} {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
  }

  min-height: ${({ minHeight }) => minHeight?.mobile};

  ${mediaMinWidth('md')} {
    min-height: ${({ minHeight }) => minHeight?.desktop};
  }

  &:after {
    content: ''; /* to make the 'order' property work on child */
  }
`;

const BackgroundPicture = styled.picture<{ imgObjectPosition: ResponsiveProp<string> }>`
  position: absolute;
  z-index: 0;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & > img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: ${({ imgObjectPosition }) => imgObjectPosition.mobile};

    ${mediaMinWidth('md')} {
      object-position: ${({ imgObjectPosition }) => imgObjectPosition.desktop};
    }
  }
`;

const BackgroundImage = styled(Image)<{ imgObjectPosition: string }>`
  position: absolute;
  z-index: 0;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: ${({ imgObjectPosition }) => imgObjectPosition};
`;

/**
 * This ContentContainer is required to use the `order` property.
 * It should have the least amount of opinion possible about how its content is positioned inside of it (here, centered with a 'dumb' padding).
 * A supplementary container, tailor-made for the content, should be passed in the children if more control is needed.
 */
const ContentContainer = styled.div<{
  contentPositions: NonNullable<Props['contentPositions']>;
}>`
  width: 100%;
  height: 100%;

  display: grid;
  place-content: center center;

  z-index: 1;

  order: ${({ contentPositions }) => contentPositions.mobile - 1};

  ${mediaMinWidth('md')} {
    order: ${({ contentPositions }) => contentPositions.desktop - 1};
  }
`;

type Props = {
  children: ReactNode; // required (React.PropsWithChildren makes "children" optional)
  imageParams: PerDeviceImagesParams | NextImageParams;
  contentPositions?: ResponsiveProp<ContentPositions>;
  minHeight?: ResponsiveProp<HeightProp>;
};

const ImageBgHero = ({
  children,
  imageParams,
  contentPositions = { mobile: 1, desktop: 1 },
  minHeight = { mobile: '100vh', desktop: '100vh' },
}: Props) => {
  const isPerDeviceImages = (
    imgParams: PerDeviceImagesParams | NextImageParams,
  ): imgParams is PerDeviceImagesParams => {
    return 'mobile' in imageParams;
  };

  return (
    <RootContainer
      contentPositions={contentPositions}
      minHeight={{ mobile: cssHeight(minHeight.mobile), desktop: cssHeight(minHeight.desktop) }}
    >
      {isPerDeviceImages(imageParams) ? (
        <BackgroundPicture
          imgObjectPosition={{
            desktop: imageParams.desktop.imgObjectPosition || 'center center',
            mobile: imageParams.mobile.imgObjectPosition || 'center center',
          }}
        >
          {/* here the logic in `media` attribute is reversed (compared to css), because the first correct one is selected */}
          <source
            media={`(max-width: calc(${breakpoints.md} - 1px))`}
            srcSet={imageParams.mobile.src}
          />
          <source media={mediaMinWidth('md')} srcSet={imageParams.desktop.src} />
          {/* We currently use <picture> + <source> + <img> because the "art direction" feature
          is still unstable in next/image (see `unstable_getImageProps`) */}
          <img alt={imageParams.desktop.alt ?? ''} src={imageParams.desktop.src} />
        </BackgroundPicture>
      ) : (
        <BackgroundImage
          alt={imageParams.alt ?? ''}
          fill={typeof imageParams.height === 'undefined' ? true : undefined}
          height={imageParams.height}
          imgObjectPosition={imageParams.imgObjectPosition || 'center center'}
          src={imageParams.src}
          width={imageParams.width}
        />
      )}
      <ContentContainer contentPositions={contentPositions}>{children}</ContentContainer>
    </RootContainer>
  );
};

export default ImageBgHero;
