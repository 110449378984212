import type { Get, PartialDeep } from 'type-fest';
import { isKey, isObjectType } from 'types/predicates';

export type I18nContentMap<C> = {
  US: C;
  CA?: PartialDeep<C>;
  GB?: PartialDeep<C>;
};

// INFO: Generated with chatGPT
type Path<T> = T extends object
  ? {
      [K in Extract<keyof T, string>]: T[K] extends object ? K | `${K}.${Path<T[K]>}` : K;
    }[Extract<keyof T, string>]
  : never;

export const interpRegExp = /{{\s*([^{}\s]*)\s*}}/g;

/**
 * maps content with the proper locale
 *
 * @param contentMap - a content object with per locale strings / JSX.Elements
 * @param translationKey - key of the locale to display
 * @param [fallbackTranslationKey='US'] - default key in case the content doesn't exist on the desired locale
 *
 * @returns t - translation mapping function
 */
export const createTranslationFn =
  <C>(
    contentMap: I18nContentMap<C>,
    translationKey: keyof I18nContentMap<C>,
    fallbackTranslationKey: keyof I18nContentMap<C> = 'US',
  ) =>
  <P extends Path<C>>(path: P): NonNullable<Get<C, P>> => {
    const pathKeys = path.split('.');
    let currNode: any = contentMap[translationKey];
    let defaultCurrNode: any = contentMap[fallbackTranslationKey];
    pathKeys.forEach((pathKey) => {
      defaultCurrNode =
        isObjectType(defaultCurrNode) && isKey(defaultCurrNode, pathKey)
          ? defaultCurrNode[pathKey]
          : '';
      currNode =
        isObjectType(currNode) && isKey(currNode, pathKey) ? currNode[pathKey] : defaultCurrNode;
    });
    return currNode;
  };
