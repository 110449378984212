import { MY_SKIN } from '../constants';
import { SKIN } from '../constants/progressCategories';

import breakoutsIcon from 'assets/images/skincare/breakouts.svg';
import cloggedPoresIcon from 'assets/images/skincare/cloggedPores.svg';
import drynessIcon from 'assets/images/skincare/dryness.svg';
import dullnessIcon from 'assets/images/skincare/dullness.svg';
import enlargedPoresIcon from 'assets/images/skincare/enlargedPores.svg';
import fineLinesIcon from 'assets/images/skincare/fineLines.svg';
import hyperpigmentationIcon from 'assets/images/skincare/hyperpigmentation.svg';
import lackFirmnessIcon from 'assets/images/skincare/lackFirmness.svg';
import oilinessIcon from 'assets/images/skincare/oiliness.svg';
import rednessIcon from 'assets/images/skincare/redness.svg';
import scarsIcon from 'assets/images/skincare/scars.svg';
import selectedBreakoutsIcon from 'assets/images/skincare/selectedBreakouts.svg';
import selectedCloggedPoresIcon from 'assets/images/skincare/selectedCloggedPores.svg';
import selectedDrynessIcon from 'assets/images/skincare/selectedDryness.svg';
import selectedDullnessIcon from 'assets/images/skincare/selectedDullness.svg';
import selectedEnlargedPoresIcon from 'assets/images/skincare/selectedEnlargedPores.svg';
import selectedFineLinesIcon from 'assets/images/skincare/selectedFineLines.svg';
import selectedHyperpigmentationIcon from 'assets/images/skincare/selectedHyperpigmentation.svg';
import selectedLackFirmnessIcon from 'assets/images/skincare/selectedLackFirmness.svg';
import selectedOilinessIcon from 'assets/images/skincare/selectedOiliness.svg';
import selectedRednessIcon from 'assets/images/skincare/selectedRedness.svg';
import selectedScarsIcon from 'assets/images/skincare/selectedScars.svg';
import selectedSensitivityIcon from 'assets/images/skincare/selectedSensitivity.svg';
import selectedUnderEyeSkinIcon from 'assets/images/skincare/selectedUnderEyeSkin.svg';
import selectedUnevenTextureIcon from 'assets/images/skincare/selectedUnevenTexture.svg';
import sensitivityIcon from 'assets/images/skincare/sensitivity.svg';
import underEyeSkinIcon from 'assets/images/skincare/underEyeSkin.svg';
import unevenTextureIcon from 'assets/images/skincare/unevenTexture.svg';

const concerns = {
  CardProps: {
    compactMode: true,
  },
  name: 'concerns',
  category: MY_SKIN,
  progressCategory: SKIN,
  route: `/consultation/skincare/${MY_SKIN}/concerns`,
  title: 'What are your biggest skin concerns right now?',
  subtitle: (
    <>
      Select all that apply, and focus on what feels most pressing. <br />
      You can specify concerns for different areas later.
    </>
  ),
  tip: {
    title: 'Why we ask:',
    content:
      'This helps us determine which active ingredients to include in your custom formulas. Let us know what you’d like to address over the coming months, and we’ll keep checking in to update your formulas if your goals change.',
  },
  options: [
    {
      value: 'enlarged_pores',
      label: 'Enlarged pores',
      icon: enlargedPoresIcon,
      selectedIcon: selectedEnlargedPoresIcon,
    },
    {
      value: 'clogged_pores',
      label: 'Clogged pores or blackheads',
      icon: cloggedPoresIcon,
      selectedIcon: selectedCloggedPoresIcon,
    },
    {
      value: 'redness',
      label: 'Redness',
      icon: rednessIcon,
      selectedIcon: selectedRednessIcon,
    },
    {
      value: 'dullness',
      label: 'Dullness',
      icon: dullnessIcon,
      selectedIcon: selectedDullnessIcon,
    },
    {
      value: 'fine_lines',
      label: 'Appearance of fines lines or wrinkles',
      icon: fineLinesIcon,
      selectedIcon: selectedFineLinesIcon,
    },
    {
      value: 'under_eye_skin',
      label: 'Under-eye skin',
      icon: underEyeSkinIcon,
      selectedIcon: selectedUnderEyeSkinIcon,
    },
    {
      value: 'uneven_texture',
      label: 'Uneven texture',
      icon: unevenTextureIcon,
      selectedIcon: selectedUnevenTextureIcon,
    },
    {
      value: 'hyperpigmentation',
      label: 'Hyperpigmentation',
      icon: hyperpigmentationIcon,
      selectedIcon: selectedHyperpigmentationIcon,
    },
    {
      value: 'oiliness',
      label: 'Oiliness',
      icon: oilinessIcon,
      selectedIcon: selectedOilinessIcon,
    },
    {
      value: 'dryness',
      label: 'Dryness',
      icon: drynessIcon,
      selectedIcon: selectedDrynessIcon,
    },
    {
      value: 'scars',
      label: 'Scars or marks',
      icon: scarsIcon,
      selectedIcon: selectedScarsIcon,
    },
    {
      value: 'lack_firmness',
      label: 'Lack of firmness',
      icon: lackFirmnessIcon,
      selectedIcon: selectedLackFirmnessIcon,
    },
    {
      value: 'breakouts',
      label: 'Breakouts or blemishes',
      icon: breakoutsIcon,
      selectedIcon: selectedBreakoutsIcon,
    },
    {
      value: 'sensitivity',
      label: 'Sensitivity',
      icon: sensitivityIcon,
      selectedIcon: selectedSensitivityIcon,
    },
  ],
  shouldBeIncluded: () => true,
  getInitialValue: answers => answers.diag_concerns,
  getAnswers: ({ selected }) => ({
    diag_concerns: selected,
  }),
  multi: true,
};

export default concerns;
