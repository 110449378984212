import { useAppSelector } from 'dux/app/hooks';

import { productsCategories } from 'constants/products';

import {
  getCartCreditCouponsV2,
  getCartOfferCouponsV2,
  getCartV2Currency,
  getCartV2Data,
  getCartV2IsFree,
  getComputedCartV2Items,
  getGiftCardsV2,
  getItemQuantityInCartV2,
} from 'dux/cartV2/selectors';
import * as checkoutCartSelectors from 'dux/checkoutCart/selectors';
import * as userSelectors from 'dux/user/selectors';

import useCheckoutCategory from './useCheckoutCategory';

const useCartOrderSummaryInfosByCartType = () => {
  const checkoutCategory = useCheckoutCategory();

  // Cart V1
  const cart = useAppSelector(checkoutCartSelectors.getCartData);
  const giftsWithPurchase = useAppSelector(checkoutCartSelectors.getGWPsInCart);
  const mustHaveJarsInCart = useAppSelector(checkoutCartSelectors.getMustHaveJarsInCart);
  const shouldProposePumps = useAppSelector(state =>
    checkoutCartSelectors.shouldProposePumps(state, {
      checkoutCategory,
    })
  );

  const currency = useAppSelector(checkoutCartSelectors.getCurrencyFromCart);
  const subscriptionCoupons = useAppSelector(checkoutCartSelectors.getCartSubscriptionCoupons);
  const offerCoupons = useAppSelector(checkoutCartSelectors.getCartOfferCoupons);
  const freeOrder = useAppSelector(userSelectors.getIsOrderFree);
  const creditCoupons = useAppSelector(checkoutCartSelectors.getCartCreditCoupons);
  const cartHasSubscription = useAppSelector(checkoutCartSelectors.cartHasSubscription);
  const deliveryBy = useAppSelector(checkoutCartSelectors.getDeliveryBy);

  const cartProducts = useAppSelector(checkoutCartSelectors.getCartProducts);
  const cartProductsQuantity = useAppSelector(checkoutCartSelectors.getCartProductsQuantity);

  const hasItemsFromGiftSet = useAppSelector(checkoutCartSelectors.getHasItemsFromGiftSet);
  const giftCards = useAppSelector(checkoutCartSelectors.getGiftCards);

  // Cart V2
  const cartV2 = useAppSelector(getCartV2Data);
  const currencyV2 = useAppSelector(getCartV2Currency);
  const freeOrderV2 = useAppSelector(getCartV2IsFree);
  const creditCouponsV2 = useAppSelector(getCartCreditCouponsV2);
  const offerCouponsV2 = useAppSelector(getCartOfferCouponsV2);
  const cartProductsV2 = useAppSelector(getComputedCartV2Items);
  const cartProductsQuantityV2 = useAppSelector(getItemQuantityInCartV2);
  const giftCardsV2 = useAppSelector(getGiftCardsV2);

  if (checkoutCategory === productsCategories.ACCESSORIES) {
    return {
      cart: cartV2,
      currency: currencyV2,
      freeOrder: freeOrderV2,
      creditCoupons: creditCouponsV2,
      offerCoupons: offerCouponsV2,
      cartProducts: cartProductsV2,
      cartProductsQuantity: cartProductsQuantityV2,
      isCartV2: true,
      giftCards: giftCardsV2,
    };
  }

  return {
    cart,
    currency,
    freeOrder,
    creditCoupons,
    offerCoupons,
    cartProducts,
    cartProductsQuantity,
    giftsWithPurchase,
    mustHaveJarsInCart,
    shouldProposePumps,
    subscriptionCoupons,
    cartHasSubscription,
    deliveryBy,
    hasItemsFromGiftSet,
    giftCards,
  };
};

export default useCartOrderSummaryInfosByCartType;
