import { createAsyncThunk } from '@reduxjs/toolkit';

import * as CouponService from 'Services/CouponService';

import logSentryError from 'utils/logSentry';

import { getIsAuthenticated } from 'dux/auth/selectors';

import { getIsLoading } from './selectors';

export const fetchCouponsStatuses = createAsyncThunk(
  'couponsStatuses/fetchCouponsStatuses',
  async () => {
    try {
      const data = await CouponService.getCouponsStatuses();
      return data;
    } catch (error) {
      logSentryError('[dux/couponsStatuses] fetchCouponsStatuses', error);

      throw error;
    }
  },
  {
    condition(_, { getState }) {
      const state = getState();
      return getIsAuthenticated(state) && !getIsLoading(state);
    },
  }
);
