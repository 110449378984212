import { createContext, useMemo } from 'react';

import { matchPath, Outlet, useLocation } from 'react-router-dom';

import { subscriptionUXCategories } from 'constants/subscriptions';

import { checkoutProductsCategories } from './Checkout/constants/checkoutProductsCategories';

const pathList = [
  ['/checkout/:category/', checkoutProductsCategories],
  ['/account/subscription/:category/*', subscriptionUXCategories],
] as const;

// XXX: to be uncommented once constants/subscription is converted to TypeScript
// export type CategoryContextType = (typeof pathList)[number][1][number];
export type CategoryContextType = 'haircare' | 'skincare' | 'supplements' | 'accessories';
export const CategoryContext = createContext<CategoryContextType | null>(null);

export const CategoryProvider = () => {
  const { pathname } = useLocation();

  const cat = useMemo(() => {
    const foundTuple = pathList.find(([pathPattern]) => {
      const { category } = matchPath(pathPattern, pathname)?.params ?? {};

      return category !== undefined;
    }) ?? ['', null];

    return (
      (matchPath(foundTuple[0], pathname)?.params?.category as CategoryContextType | undefined) ??
      null
    );
  }, [pathname]);

  return (
    <CategoryContext.Provider value={cat}>
      <Outlet />
    </CategoryContext.Provider>
  );
};
