import { useState } from 'react';

import { Box, theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';
import { mediaMinWidth } from '@prose-ui/utils/media';
import { animated, useTransition } from '@react-spring/web';
import dayjs from 'dayjs';
import Image from 'next/image';

import CollapsibleWithEllipsis from 'Components/CollapsibleWithEllipsis';
import Spacer from 'Components/Spacer';
import Typography from 'Components/Typography';

import { genitive } from 'utils/textUtils';

import type { EnrichedOrderItem } from 'dux/digitalLeaflet/types';

import ImageLabel from './ImageLabel';
import InciListModal from './InciListModal';
import IngredientsCarousel from './IngredientsCarousel';
import SupplementsFactsModal from './SupplementsFactsModal';

const Root = styled.div`
  --page-padding: ${theme.spacing['3x']};
  position: relative;
  width: 100%;
  margin: auto;

  ${mediaMinWidth('lg')} {
    --content-width: 1400px;
    max-width: var(--content-width, 1400px);
  }
`;

const ProductDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaMinWidth('lg')} {
    display: grid;
    grid-template-columns: 1fr 45%;
    column-gap: min(1.5vw, ${theme.spacing['10x']});
  }
`;

const HeadContainer = styled.div`
  grid-column: 1/2;

  height: fit-content;
  max-width: 100%;
  padding-right: var(--page-padding);
  padding-left: min(
    1vw,
    var(--page-padding)
  ); /* reduce left padding on mobile to have more space for img */

  margin-bottom: ${theme.spacing['6x']};

  display: grid;
  grid-auto-flow: column;
  grid-template-columns: max-content auto;
  grid-gap: ${theme.spacing['4x']};
  align-items: center;

  ${mediaMinWidth('md')} {
    grid-template-columns: auto 1fr;
  }

  ${mediaMinWidth('lg')} {
    grid-gap: ${theme.spacing['6x']};
    margin-bottom: ${theme.spacing['8x']};
  }
`;

const ProductImage = styled(Image)`
  height: fit-content;
  object-fit: contain;

  max-width: 106px;

  ${mediaMinWidth('md')} {
    max-width: 186px;
  }
`;

const KeyBenefitTypography = styled(Typography)`
  font-size: ${theme.typography.fontSize.xs};

  ${mediaMinWidth('md')} {
    font-size: ${theme.typography.fontSize.sm};
  }
`;

const CustomerProductText = styled(Typography)`
  font-size: ${theme.typography.fontSize.lg};

  ${mediaMinWidth('md')} {
    font-size: ${theme.typography.fontSize.xl};
  }
`;

const DatesContainer = styled.div`
  /* shows the separator if not wrapping */

  --separator-margin: 2.5ch;

  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  & > p:first-of-type {
    margin-right: calc(2 * var(--separator-margin));
    width: max-content;
  }
  & > p:nth-of-type(2) {
    position: relative;
    width: max-content;

    /* separator */
    :before {
      position: absolute;
      left: calc(-1 * var(--separator-margin));
      content: '';
      width: 1px;
      height: 1.2em;
      background: ${theme.colors.neutral[900]};
    }
  }
`;

const DescriptionContainer = styled.div`
  grid-column: 1/2;
  padding-inline: var(--page-padding);
`;

const IngredientsContainer = styled.div`
  grid-column: 1/2;
`;
const IngredientsTitle = styled(Typography)`
  text-align: center;
  padding-inline: var(--page-padding);

  ${mediaMinWidth('lg')} {
    text-align: start;
  }
`;

const ClickableTypography = styled(Typography)`
  cursor: pointer;
  width: fit-content;
  padding-inline: var(--page-padding);
  margin: auto;

  ${mediaMinWidth('lg')} {
    margin: 0;
  }
`;

const HowToContainer = styled.div`
  grid-column: -2 / -1;
  grid-row: 1 / span 99; /* spans across all rows, to avoid moving the other column's content when expanding */
  align-self: start;

  margin-inline: auto;
  max-width: 600px;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

const HowToImage = styled(Image)`
  width: 100%;
  height: auto;
  display: block;
`;

const RoutineDescription = styled.div`
  margin-bottom: 0;
  padding: ${theme.spacing['6x']};

  display: flex;
  align-items: center;

  background: ${theme.colors.neutral[300]};

  ${mediaMinWidth('lg')} {
    padding-block: ${theme.spacing['10x']};
  }
`;

type Props = {
  customerFirstName: string;
  product: EnrichedOrderItem;
};

const ProductDetails = ({ customerFirstName, product: productProp }: Props) => {
  const [isIngredientsModalOpen, setIsIngredientsModalOpen] = useState(false);

  const transitions = useTransition(productProp, {
    initial: { opacity: 1, position: 'static' },
    from: { opacity: 0, position: 'absolute' as const }, // 'as const' to avoid a TS error: https://github.com/pmndrs/react-spring/issues/2007
    leave: { opacity: 0, position: 'absolute' },
    enter: { opacity: 1, position: 'static' },
    config: {
      duration: 300,
    },
  });

  return transitions((style, product) => (
    <Root aria-live="polite">
      <animated.div style={{ ...style, width: '100%' }}>
        <ProductDetailsContainer>
          <HeadContainer>
            <ProductImage alt={product.formula.label} src={product.productImage.src} />
            <div>
              {product.formula.key_benefit && (
                <>
                  {/* @ts-expect-error - Typography has no TS types for now */}
                  <KeyBenefitTypography
                    bold
                    color="noir"
                    data-testid="digital-leaflet-product-key-benefit"
                    upperCase
                    variant="mono1"
                  >
                    {product.formula.key_benefit}
                  </KeyBenefitTypography>
                  <Spacer size={8} />
                </>
              )}
              {/* @ts-expect-error - Typography has no TS types for now */}
              <CustomerProductText
                aria-live="polite"
                color="noir"
                data-testid="digital-leaflet-product-label"
                variant="h2"
              >
                {genitive(customerFirstName)} {product.formula.label}
              </CustomerProductText>
              <Spacer size={8} />

              {product.formula.produced_at && (
                <>
                  <DatesContainer>
                    {/* @ts-expect-error - Typography has no TS types for now */}
                    <Typography data-testid="digital-leaflet-product-produced-at" variant="mono2">
                      BOTTLED ON {dayjs(product.formula.produced_at).format('MM/DD/YYYY')}
                    </Typography>
                    {product.best_before && (
                      // @ts-expect-error - Typography has no TS types for now
                      <Typography data-testid="digital-leaflet-product-best-before" variant="mono2">
                        BEST BY {dayjs(product.best_before).format('MM/DD/YYYY')}
                      </Typography>
                    )}
                  </DatesContainer>
                  <Spacer size={8} />
                </>
              )}

              {/* @ts-expect-error - Typography has no TS types for now */}
              <Typography color="grey" data-testid="digital-leaflet-product-capacity" variant="p2">
                {product.formula.capacity}
              </Typography>
            </div>
          </HeadContainer>
          <DescriptionContainer>
            <CollapsibleWithEllipsis clampedLines={3}>
              {product.formula.descriptionString}
              {product.formula.derivedFragranceDescription}
            </CollapsibleWithEllipsis>
            <Spacer size={40} />
          </DescriptionContainer>

          <IngredientsContainer>
            {/* @ts-expect-error - Typography has no TS types for now */}
            <IngredientsTitle variant="mono2">
              Key ACTIVE ingredients in your {product.formula.label} formula
            </IngredientsTitle>
            <Spacer size={32} />
            <IngredientsCarousel ingredients={product.formula.displayedIngredients} />
            <Spacer size={24} />
            {product.isSupplement ? (
              <>
                {/* @ts-expect-error - Typography has no TS types for now */}
                <ClickableTypography
                  color="noir"
                  data-testid="full-ingredient-list-button"
                  onClick={() => setIsIngredientsModalOpen(true)}
                  underline
                  variant="p1"
                >
                  View Supplements Facts
                </ClickableTypography>

                <SupplementsFactsModal
                  factsImages={product.factsImages}
                  firstname={customerFirstName}
                  isOpen={isIngredientsModalOpen}
                  onClose={() => setIsIngredientsModalOpen(false)}
                />
              </>
            ) : (
              <>
                {/* @ts-expect-error - Typography has no TS types for now */}
                <ClickableTypography
                  color="noir"
                  data-testid="full-ingredient-list-button"
                  onClick={() => setIsIngredientsModalOpen(true)}
                  underline
                  variant="p1"
                >
                  View Full Ingredient List
                </ClickableTypography>

                <InciListModal
                  customerFirstName={customerFirstName}
                  incis={product.formula.incis}
                  isOpen={isIngredientsModalOpen}
                  onClose={() => setIsIngredientsModalOpen(false)}
                  productLabel={product.navLabel}
                  productType={product.formula.type}
                  withDisclaimer={product.incisListRequiresDisclaimer}
                />
              </>
            )}

            <Spacer size={40} />
          </IngredientsContainer>
          <HowToContainer>
            <Box position="relative">
              <ImageLabel>How to use</ImageLabel>
              <HowToImage alt="" src={product.howToImage.src} />
            </Box>
            <RoutineDescription>
              <CollapsibleWithEllipsis clampedLines={3}>
                {product.formula.routine.join(' ')}
              </CollapsibleWithEllipsis>
            </RoutineDescription>
          </HowToContainer>
        </ProductDetailsContainer>
      </animated.div>
    </Root>
  ));
};

export default ProductDetails;
