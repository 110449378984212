import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@prose-ui/legacy';
import { makeStyles } from 'legacyStyles';

import LazyImg from 'Components/LazyImg';
import { breakpoints } from 'Components/ThemeProvider';
import Typography from 'Components/Typography';

import BlockLayout from 'Blocks/BlockLayout';

import { srcOrNextImageProp } from 'PropTypes/imageProps';

import useMedia from 'utils/useMedia';

import TwoUp from './LegacyTwoUp';

const useStyles = makeStyles(
  theme => ({
    root: {
      '&.image-mode-contain': {
        alignItems: 'center',
      },
      '&.image-mode-contain-desktop': {
        alignItems: 'center',
      },
    },
    imageContainer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      '&.image-mode-cover': {
        position: 'relative',
        overflow: 'hidden',
      },
      [theme.breakpoints.match('md')]: {
        height: '66.67vw',
        '&.is-uncropped': {
          height: '95vw',
        },
      },
      [theme.breakpoints.up('md')]: {
        '&.has-bottom-border': {
          borderBottom: '1px solid',
        },
        '&.has-middle-border-left': {
          borderLeft: '1px solid',
        },
        '&.has-middle-border-right': {
          borderRight: '1px solid',
        },
      },
      '&.image-mode-contain': {
        [theme.breakpoints.match('md')]: {
          // on mobile contain mode, block spacing is before image
          marginTop: theme.props.verticalSpacingMob,
        },
      },
      '&.image-mode-contain-desktop': {
        [theme.breakpoints.match('md')]: {
          // on mobile contain mode, block spacing is before image
        },
      },
    },
    image: {
      '&.image-mode-contain': {
        width: '100%',
      },
      '&.image-mode-contain-desktop': {
        width: '100%',
        height: '100%',
        display: 'block',
        objectFit: 'cover',
      },
      '&.image-mode-cover': {
        objectFit: 'cover',
        width: '100%',
        minHeight: '100.5%', // ensure no white space after translate
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
      },
    },
    blockContent: {
      maxWidth: theme.width.half,
      [theme.breakpoints.match('md')]: {
        maxWidth: theme.width.normal,
      },
      '&.image-mode-contain': {
        [theme.breakpoints.match('md')]: {
          // on mobile contain mode, image will be closer to the title
          marginTop: theme.props.minimumBlockPadding,
        },
      },
      '&.image-mode-contain-desktop': {
        [theme.breakpoints.match('md')]: {
          // on mobile contain mode, image will be closer to the title
          marginTop: 72,
        },
      },
    },
  }),
  { name: 'TextImage' }
);

const ImageCaption = styled(Typography)`
  position: absolute;
  bottom: 30px;
  left: 30px;
`;

/**
  This component derives from the TwoUp component, with one block being an image, and another
  being some text or other content with internal padding.
  <br />
  <br />
  You should configure it using two different images. They will be swaped at the md breakpoint
  (1024px). On wide screens the image is cropped vertically so it respects the content on the
  other block. On narrow screens the image is stacked and respects a 3:2 ratio.
*/
const TextImage = forwardRef(
  (
    {
      TitleProps,
      SubTitleProps,
      DescriptionProps,
      backgroundColor,
      children,
      contentAlign,
      contentAlignMob,
      description,
      hasBorder,
      hasUncroppedMobileImage,
      hasWhiteFont,
      imageAlt,
      imageDesktop,
      imageTablet = imageDesktop,
      imageMobile,
      imageMode,
      hasImageBorder,
      title,
      subTitle,
      dataTestId,
      hasMiddleBorder,
      wideScreenOrder,
      imageCaption,
      ...props
    },
    ref
  ) => {
    // style override through classes prop
    const { classes, cx } = useStyles(undefined, props?.classes ? { props } : undefined);
    const isTablet = useMedia(`(min-width: ${breakpoints.md}px)`);
    const isDesktop = useMedia(`(min-width: ${breakpoints.lg}px)`);
    const image = isDesktop ? imageDesktop : isTablet ? imageTablet : imageMobile;
    const whiteFont = ['vert', 'rouge'].includes(backgroundColor) || hasWhiteFont;

    return (
      <TwoUp
        ref={ref}
        {...props}
        backgroundColor={backgroundColor}
        classes={{
          root: cx(classes.root, `image-mode-${imageMode}`),
        }}
        wideScreenOrder={wideScreenOrder}
      >
        <div
          className={cx(classes.imageContainer, `image-mode-${imageMode}`, {
            'is-uncropped': hasUncroppedMobileImage,
            'has-bottom-border': hasImageBorder,
            'has-middle-border-left': hasMiddleBorder && wideScreenOrder === '21',
            'has-middle-border-right': hasMiddleBorder && wideScreenOrder === '12',
          })}
        >
          <LazyImg
            alt={imageAlt}
            className={cx(classes.image, `image-mode-${imageMode}`)}
            src={image.src}
          />
          {imageCaption && (
            <ImageCaption color="white" variant="p1">
              {imageCaption}
            </ImageCaption>
          )}
        </div>

        <BlockLayout
          classes={{
            content: cx(classes.blockContent, `image-mode-${imageMode}`),
          }}
          contentAlign={contentAlign}
          contentAlignMob={contentAlignMob}
          dataTestId={dataTestId}
        >
          {subTitle && (
            <Typography
              color={whiteFont ? 'white' : 'noir'}
              markupName="p"
              paragraph
              style={{ fontSize: 16 }}
              variant="mono1"
              {...SubTitleProps}
            >
              {subTitle}
            </Typography>
          )}
          {title && (
            <Typography
              color={whiteFont ? 'white' : 'noir'}
              markupName="h3"
              paragraph
              variant="h1"
              {...TitleProps}
            >
              {title}
            </Typography>
          )}

          {description && (
            <Typography
              color={whiteFont ? 'white' : 'grey'}
              paragraph={Boolean(children)}
              variant="p1"
              {...DescriptionProps}
            >
              {description}
            </Typography>
          )}

          {children}
        </BlockLayout>
      </TwoUp>
    );
  }
);

TextImage.propTypes = {
  backgroundColor: PropTypes.string,
  imageCaption: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node,
  contentAlign: PropTypes.oneOf(['center', 'left']),
  contentAlignMob: PropTypes.oneOf(['center', 'left']),
  description: PropTypes.node,
  DescriptionProps: PropTypes.shape({}),
  hasBorder: PropTypes.bool,
  hasWhiteFont: PropTypes.bool,
  hasUncroppedMobileImage: PropTypes.bool,
  imageAlt: PropTypes.string,
  imageDesktop: srcOrNextImageProp.isRequired,
  imageMobile: srcOrNextImageProp.isRequired,
  imageMode: PropTypes.oneOf(['cover', 'contain', 'contain-desktop']),
  imageTablet: srcOrNextImageProp,
  hasImageBorder: PropTypes.bool,
  title: PropTypes.node,
  subTitle: PropTypes.node,
  TitleProps: PropTypes.shape({}),
  SubTitleProps: PropTypes.shape({}),
  screenWidth: PropTypes.shape({ 1: PropTypes.string, 2: PropTypes.string }),
  dataTestId: PropTypes.string,
  hasMiddleBorder: PropTypes.bool,
  wideScreenOrder: PropTypes.oneOf(['12', '21']),
};

TextImage.defaultProps = {
  backgroundColor: null,
  imageCaption: null,
  classes: null,
  contentAlign: 'left',
  contentAlignMob: 'center',
  children: null,
  description: null,
  DescriptionProps: {},
  hasBorder: false,
  hasWhiteFont: false,
  hasUncroppedMobileImage: false,
  imageAlt: '',
  imageMode: 'cover',
  imageTablet: undefined,
  title: null,
  subTitle: null,
  TitleProps: {},
  SubTitleProps: {},
  hasImageBorder: false,
  screenWidth: null,
  dataTestId: null,
  hasMiddleBorder: false,
  wideScreenOrder: '12',
};

TextImage.displayName = 'TextImage';
export default TextImage;
