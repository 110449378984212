import { useAppSelector } from 'dux/app/hooks';

import { getActiveOrVariantForPostPurchaseCrossSellOffer } from './selectors';

export const Variant = {
  NONE: 'none',
  SUBSCRIPTION_20_OFFER: 'subscription-20-offer',
  FREE_CLEANSER_OFFER: 'free-cleanser-offer',
  FIRST_ORDER_50_OFFER: 'first-order-50-offer',
} as const;

export const usePostPurchaseCrossSellOffer = () => {
  const activeOrVariant: string | boolean = useAppSelector(
    // @ts-expect-error: dux/featureFlags/selectors not ported to TS yet
    getActiveOrVariantForPostPurchaseCrossSellOffer,
  );
  if (typeof activeOrVariant === 'boolean') {
    return Variant.NONE;
  }
  return activeOrVariant;
};
