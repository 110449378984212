import noop from 'lodash/fp/noop';
import isEmpty from 'lodash/isEmpty';

import { styled } from '@prose-ui/legacy';

import CheckoutBlock from 'Apps/Checkout/Blocks/CheckoutBlock';

import AddressRadio from 'Components/AddressRadio';
import Spacer from 'Components/Spacer';
import Typography from 'Components/Typography';

import { formatPriceWithCurrency } from 'utils/currencies';

import useShippingMethodInfosByCartType from '../hooks/useShippingMethodInfosByCartType';

const Content = styled.section`
  flex-direction: column;
`;

const FreeShippingTag = styled(Typography)`
  display: flex;
  background-color: rgba(226, 255, 93, 0.6);
  height: 22px;
  align-items: center;
`;

const ShippingMethod = () => {
  const { deliveryBy, cartData, currency, isCartV2, cartSubscriptionCoupons } =
    useShippingMethodInfosByCartType();

  const isFreeShipping = cartData.total_shipping === 0.0;

  const shippingPrice = isFreeShipping
    ? 'FREE'
    : formatPriceWithCurrency({
        price: Math.abs(cartData.total_shipping).toFixed(2),
        currency,
      });

  return (
    <CheckoutBlock dataTestId="shipping-method" title="Shipping Method">
      <Content>
        <AddressRadio onClick={noop} selected>
          <Typography align="left" color="noirDark" variant="p2">
            Standard - {shippingPrice}
          </Typography>
          {!isCartV2 && isFreeShipping && !isEmpty(cartSubscriptionCoupons) && (
            <>
              <Spacer size={4} />
              <FreeShippingTag data-testid="shipping-method-free-tag">
                <Typography align="left" color="noirDark" variant="p4">
                  As a subscriber you get free shipping on every order
                </Typography>
              </FreeShippingTag>
            </>
          )}
          <Spacer size={16} />
          <Typography align="left" color="noirDark" variant="p2">
            {deliveryBy}
          </Typography>
        </AddressRadio>
      </Content>
    </CheckoutBlock>
  );
};

export default ShippingMethod;
