import { apiReducer, initialApiState } from 'utils/reduxUtils';

import { userSignout } from 'dux/auth/actions';
import { CLEAR_CHECKOUT } from 'dux/checkout/actionTypes';
import {
  ADDRESS_VALIDATION_CHOICE,
  ADDRESS_VALIDATION_CHOICE_RESET,
  ADDRESS_VALIDATION_INVALID_FIELDS,
  ADDRESS_VALIDATION_RECOMMENDED_FIELDS,
  LOAD_ADDRESSES_FAILURE,
  LOAD_ADDRESSES_REQUEST,
  LOAD_ADDRESSES_SUCCESS,
  SET_NEW_ADDRESS,
} from 'dux/checkoutAddresses/actionTypes';

export const addresses = (state = initialApiState, action) => {
  switch (action.type) {
    case userSignout.type:
    // fallthrough
    case CLEAR_CHECKOUT:
      return initialApiState;
    case SET_NEW_ADDRESS:
      return { ...state, data: [...state.data, action.data] };
    default:
      return apiReducer(
        [LOAD_ADDRESSES_REQUEST, LOAD_ADDRESSES_SUCCESS, LOAD_ADDRESSES_FAILURE],
        state,
        action
      );
  }
};

const addressValidationInitialState = {
  addressChoice: null,
  addressInvalidFields: null,
  addressRecommendedFields: null,
};

export const addressValidation = (state = addressValidationInitialState, action) => {
  switch (action.type) {
    case ADDRESS_VALIDATION_CHOICE:
      return {
        ...state,
        addressChoice: action.data,
      };
    case ADDRESS_VALIDATION_INVALID_FIELDS:
      return {
        ...state,
        addressInvalidFields: action.data,
      };
    case ADDRESS_VALIDATION_RECOMMENDED_FIELDS:
      return {
        ...state,
        addressRecommendedFields: action.data,
      };
    case ADDRESS_VALIDATION_CHOICE_RESET:
      return {
        ...addressValidationInitialState,
      };
    default:
      return state;
  }
};
