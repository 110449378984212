import * as APIClient from './APIClient';

export const createPaymentMethod = async paymentMethod => {
  const response = await APIClient.post('/v1/account/payments', paymentMethod);
  return response.json();
};

export const fetch = async () => {
  const response = await APIClient.get('/v1/account/');
  return response.json();
};

export const fetchAddresses = async () => {
  const response = await APIClient.get('/v1/account/addresses');
  return response.json();
};

/**
 * TODO:
 * - Implement backward compatibility for /v1/account/payments
 * to receive both credit card and braintree payload shapes via mocked data
 * - Migrate API to send both credit card and braintree in same endpoint /v1/account/payments
 * - Get rid of the second endpoint in the frontend
 */
export const fetchAllPaymentMethods = async () =>
  APIClient.getJson('v1/account/payments?payment_mode=all');

export const fetchPaymentMethods = async ({ braintree } = {}) =>
  APIClient.getJson(`/v1/account/payments${braintree ? '?payment_mode=braintree' : ''}`);

export const deletePaypalPaymentMethod = async paymentSourceId =>
  APIClient.del(`/v1/account/payments/${paymentSourceId}`, { payment_mode: 'braintree' });

export const fetchPaymentToken = payload =>
  APIClient.postJson('/v1/account/payments/token', payload);

export const fetchComms = async () => {
  const response = await APIClient.get('/v1/account/comms');
  return response.json();
};

export const update = user =>
  APIClient.patch(`/v1/account/`, {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email.toLowerCase(),
    phone: user.phone,
    text_marketing_optin: user?.text_marketing_optin || false,
    text_optin: user?.text_optin || false,
    username: user?.username?.toLowerCase() || user.email.toLowerCase(),
  });

export const patch = async data => {
  const response = await APIClient.patch(`/v1/account/`, data);
  return response.json();
};

export const saveCustomerOrigin = async payload => {
  const response = await APIClient.patch(`/v1/account/`, payload);
  const json = await response.json();
  return json && json.customer_origin;
};

export const createAddress = async address => {
  const response = await APIClient.post('/v1/account/addresses', address);
  return response.json();
};

export const updateAddress = async address => {
  if (!address.pubkey) {
    throw new Error('address must have a pubkey prop');
  }
  const response = await APIClient.patch(`/v1/account/addresses/${address.pubkey}`, address);
  return response.json();
};

export const persistAddress = address => {
  if (address.pubkey) {
    return APIClient.patchJson(`/v1/account/addresses/${address.pubkey}`, address);
  }
  return APIClient.postJson('/v1/account/addresses', address);
};

export const deleteAddress = async address => {
  if (!address.pubkey) {
    throw new Error('address must have a pubkey prop');
  }
  const response = await APIClient.del(`/v1/account/addresses/${address.pubkey}`);
  if (response.status === 204) return undefined;
  return response.json();
};

export const fetchRecommendations = category =>
  APIClient.getJson(`/v1/account/product_recommendations?category=${category}`);

export const fetchAccoundCards = async () => {
  const response = await APIClient.get('/v1/account/cards');
  return response.json();
};

export const setDefaultPaymentMethod = ({ paymentId, paymentMode }) =>
  APIClient.postJson('/v1/account/payments/select_as_default', {
    payment_id: paymentId,
    payment_mode: paymentMode,
  });
