import { EmailNameConsultationCaptureAbTestVariant1 } from 'abTesting/emailNameConsultationCapture/variants';

import * as consultationComponents from '../constants/consultationComponents';
import { HAIR_SCALP } from '../constants/progressCategories';

const firstName = {
  name: 'firstName',
  progressCategory: HAIR_SCALP,
  shouldBeIncluded: ({ isAuthenticated, variantForEmailNameConsultationCaptureAbTest }) =>
    !isAuthenticated &&
    variantForEmailNameConsultationCaptureAbTest === EmailNameConsultationCaptureAbTestVariant1,
  public: true,
  Component: consultationComponents.FirstName,
  skipSave: true,
  title: 'Let’s get started on your custom formulas',
  subtitle: 'First things first — what name would you like printed on your custom bottles?',
  route: '/consultation/haircare/firstname',
  signinQuestion: 'Started your consultation already?',
  signinCta: 'Resume here',
};

export default firstName;
