import isEmpty from 'lodash/fp/isEmpty';

import { Box, theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';

import Spacer from 'Components/Spacer';
import Typography from 'Components/Typography';

import { formatPriceWithCurrency } from 'utils/currencies';

import useCheckoutSummaryHeaderInfosByCartType from '../hooks/useCheckoutSummaryHeaderInfosByCartType';

const MobileFooterOrderSumaryContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OrderSummaryLabel = styled(Typography)`
  font-size: 12px;
  color: ${theme.colors.neutral['900']};
`;

const TotalContainer = styled.span`
  display: flex;
  flex-direction: row;
`;

const TotalAmount = styled(Typography)`
  color: ${theme.colors.neutral[800]};
  font-size: 12px;
`;

const TotalWithDiscount = styled(Typography)`
  font-size: 12px;
  color: ${theme.colors.primary[400]};
`;

const ChevronDown = styled.div`
  margin-top: 3px;
  margin-left: 8px;
  width: 7px;
  height: 7px;
  border-left: 1px solid;
  border-bottom: 1px solid black;
  transform: rotate(-45deg);
`;

const ChevronUp = styled(ChevronDown)`
  transform: rotate(135deg);
`;

type OrderSummaryHeader = {
  isCheckout: boolean;
  chevronDirection: 'up' | 'down';
};

const CheckoutSummaryHeader = ({ isCheckout = false, chevronDirection }: OrderSummaryHeader) => {
  const {
    cartHasSubscription,
    hasItemsFromGiftSet,
    currency,
    cartPaymentFromCustomer,
    cartTotalPrice,
    cartTotalShipping,
    cartTotalAmount,
    freeOrder,
    offerCoupons,
    creditCoupons,
    subscriptionCoupons,
    productsInCartQuantity,
  } = useCheckoutSummaryHeaderInfosByCartType();

  const total = isCheckout ? cartPaymentFromCustomer : cartTotalPrice + cartTotalShipping;
  const totalAmount = cartTotalAmount;
  const totalDisplay = freeOrder
    ? 'Free'
    : hasItemsFromGiftSet
    ? 'Gift'
    : `${formatPriceWithCurrency({ price: total, currency })}`;
  const hasDiscount =
    (cartHasSubscription && !isEmpty(subscriptionCoupons)) ||
    !isEmpty(offerCoupons) ||
    !isEmpty(creditCoupons);

  return (
    <MobileFooterOrderSumaryContainer>
      <Box display="flex">
        {/* @ts-ignore Legacy Typography  */}
        <OrderSummaryLabel variant="mono3">
          {`Order summary [${productsInCartQuantity}]`}
        </OrderSummaryLabel>
        {chevronDirection === 'up' ? <ChevronUp /> : <ChevronDown />}
      </Box>
      {/* @ts-ignore Legacy Typography  */}
      <Typography>
        {!hasItemsFromGiftSet && hasDiscount ? (
          <TotalContainer>
            {/* @ts-ignore Legacy Typography  */}
            <TotalAmount variant="mono3">
              <s>{formatPriceWithCurrency({ price: totalAmount, currency })}</s>
            </TotalAmount>
            <Spacer axis="horizontal" size={8} />
            {/* @ts-ignore Legacy Typography  */}
            <TotalWithDiscount medium variant="mono3">
              <b>{formatPriceWithCurrency({ price: total, currency })}</b>
            </TotalWithDiscount>
          </TotalContainer>
        ) : (
          totalDisplay
        )}
      </Typography>
    </MobileFooterOrderSumaryContainer>
  );
};

export default CheckoutSummaryHeader;
