import { useState } from 'react';
import PropTypes from 'prop-types';

import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';

import Typography from './Typography';

const StyledTypography = styled(Typography)`
  z-index: 10000;
  height: ${legacyTheme.props.bannerHeight};
  width: 100%;
  position: fixed;
  top: 0;
  background-color: ${theme.colors.error[200]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${`0 ${legacyTheme.spacing.s8}`};

  & > button {
    color: white;
    cursor: pointer;
    width: ${legacyTheme.props.bannerHeight};
    height: ${legacyTheme.props.bannerHeight};
    background-color: transparent;
    border: none;
  }
`;

const Version = ({ initiallyVisible, versionAlgo, versionApi, versionSite }) => {
  const [visible, setVisible] = useState(initiallyVisible);

  if (!visible) {
    return null;
  }

  return (
    <StyledTypography align="center" color="white" markupName="div" variant="p3">
      <div>www={versionSite}</div>
      <div>api={versionApi}</div>
      <div>algo={versionAlgo}</div>
      <button onClick={() => setVisible(false)}>X</button>
    </StyledTypography>
  );
};

Version.propTypes = {
  initiallyVisible: PropTypes.bool.isRequired,
  versionAlgo: PropTypes.string,
  versionApi: PropTypes.string,
  versionSite: PropTypes.string,
};

export default Version;
