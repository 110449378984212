import { Link } from 'react-router-dom';

import { theme } from '@prose-ui';

import { SKINCARE_CART_URL } from 'constants/cart';

import flatUsDollarBadge from 'assets/images/badge_145dollar_us.svg';
import flatCadDollarBadge from 'assets/images/badge_200dollar_cad.svg';
import skincareGroup from 'assets/images/promotionalModals/skincare_products_group.jpg';

export const promotionalModalContent = {
  US: {
    title: <>Try custom skincare for&nbsp;free</>,
    description: (
      <>
        Get a free full-size Cleanser, Serum, and Moisturizer{' '}
        <strong>($145 value + free&nbsp;shipping!)</strong>.
      </>
    ),
    arch: {
      src: skincareGroup,
      alt: 'skincare trio of products grouped together',
    },
    badge: {
      src: flatUsDollarBadge,
      alt: 'Offer available when you subscribe to haircare and skincare.',
    },
    cta: {
      skincareCompleted: {
        linkTo: () => SKINCARE_CART_URL,
        dataClick: 'order-your-prose',
        ctaLabel: 'Get your free routine',
      },
      skincareNotCompleted: {
        linkTo: () => '/consultation/skincare',
        dataClick: 'get-your-formula',
        ctaLabel: 'Get your free routine',
      },
    },
    declineCta: 'No thanks, I’ll pass on this offer',
    conditions: (
      <>
        Offer valid till June 16, 2024 for first-time skincare customers only. Subscribers will
        receive their first subscription skincare order free when they subscribe to Cleanser, Serum,
        and Moisturizer. For full details on our return policy, click{' '}
        <Link to="/faq/5f3da05bceb96c001a595c02">here</Link>.
      </>
    ),
  },
  CA: {
    description: (
      <>
        Get a free full-size Cleanser, Serum, and Moisturizer{' '}
        <strong>($200 CAD value + free&nbsp;shipping!)</strong>.
      </>
    ),
    badge: {
      src: flatCadDollarBadge,
    },
  },
};

export const orderSummaryCommunication = {
  content: (
    <>
      <b>First order free</b> + <b>free shipping</b> applied!
    </>
  ),
  backgroundColor: theme.colors.tertiary[200],
};

export const checkoutCommunication = {
  title: 'exclusive offer',
  description: (
    <>
      Try custom skincare for free +&nbsp;<b>free&nbsp;shipping</b> when you subscribe
    </>
  ),
  terms: 'See terms & conditions',
};

export const bottomModal = {
  US: {
    title: 'subscriber exclusive',
    description: (
      <>
        Get a free full-size Cleanser, Serum, and Moisturizer{' '}
        <strong>($145 value + free shipping!)</strong>
      </>
    ),
    conditions: (
      <>
        Offer valid till June 16, 2024 for first-time skincare customers only. Subscribers will
        receive their first subscription skincare order free when they subscribe to Cleanser, Serum,
        and Moisturizer. For full details on our return policy, click{' '}
        <Link to="/faq/5f3da05bceb96c001a595c02">here</Link>.
      </>
    ),
  },
  CA: {
    title: 'subscriber exclusive',
    description: (
      <>
        Get a free full-size Cleanser, Serum, and Moisturizer{' '}
        <strong>($200 CAD value + free shipping!)</strong>
      </>
    ),
    conditions: (
      <>
        Offer valid till June 16, 2024 for first-time skincare customers only. Subscribers will
        receive their first subscription skincare order free when they subscribe to Cleanser, Serum,
        and Moisturizer. For full details on our return policy, click{' '}
        <Link to="/faq/5f3da05bceb96c001a595c02">here</Link>.
      </>
    ),
  },
};

export const overviewModule = {
  title: 'Try custom skincare free',
  cta: 'Get free routine',
};
