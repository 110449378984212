import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

import {
  FIRST_HAIRCARE_QUESTION_ROUTE,
  FIRST_SKINCARE_QUESTION_ROUTE,
} from 'Apps/Consultation/constants';

import Button from 'Components/LegacyButton';

import { variantProps } from 'PropTypes/ButtonProps';

import * as ctaContent from 'assets/content/consultation/cta';

import { getIsAuthenticated, getIsAuthenticating } from 'dux/auth/selectors';
import * as userSelectors from 'dux/user/selectors';

const getRenderParams = ({
  isAuthenticated,
  productName,
  canSeeFeedback,
  hasCompleteConsultations,
  hasCompleteHaircareConsultation,
  hasCompleteSkincareConsultation,
  hasActiveHaircareSubscription,
  hasActiveSkincareSubscription,
  hasNotStartedHaircareConsultation,
  hasNotStartedSkincareConsultation,
}) => {
  // Has both consultations complete and both subscriptions active
  if (
    isAuthenticated &&
    hasCompleteHaircareConsultation &&
    hasCompleteSkincareConsultation &&
    hasActiveHaircareSubscription &&
    hasActiveSkincareSubscription
  ) {
    return {
      to: '/account/reorder',
      label: ctaContent.reorder,
      dataClick: 'order-prose',
    };
  }

  // Has completed skincare consultation and active haircare subscription
  if (isAuthenticated && hasActiveHaircareSubscription && hasCompleteSkincareConsultation) {
    return {
      to: '/checkout/skincare',
      label: ctaContent.reorder,
      dataClick: 'order-prose',
    };
  }

  // Has completed haircare consultation and active skincare subscription
  if (isAuthenticated && hasCompleteHaircareConsultation && hasActiveSkincareSubscription) {
    return {
      to: '/checkout/haircare',
      label: ctaContent.reorder,
      dataClick: 'order-prose',
    };
  }

  // Order delivered or has all consultations complete
  if (canSeeFeedback || hasCompleteConsultations) {
    return {
      to: '/account/reorder',
      label: ctaContent.reorder,
      dataClick: 'order-prose',
    };
  }

  // Has started skincare consultation and has an active haircare subscription
  if (isAuthenticated && hasActiveHaircareSubscription && !hasNotStartedSkincareConsultation) {
    return {
      to: FIRST_SKINCARE_QUESTION_ROUTE,
      label: ctaContent.oneConsultationStarted,
      dataClick: 'finish-consultation',
    };
  }

  // Has an active haircare subscription and hasn't started skincare consultation
  if (isAuthenticated && hasActiveHaircareSubscription) {
    return {
      to: '/consultation/skincare',
      label: ctaContent.newUser(),
      dataClick: 'consultation',
    };
  }

  // Has completed haircare consultation only
  if (isAuthenticated && hasCompleteHaircareConsultation && hasNotStartedSkincareConsultation) {
    return {
      to: '/consultation/skincare',
      label: ctaContent.oneConsultationNotStarted,
      dataClick: 'consultation',
    };
  }

  // Has completed skincare consultation only
  if (isAuthenticated && hasCompleteSkincareConsultation && hasNotStartedHaircareConsultation) {
    return {
      to: '/consultation/haircare',
      label: ctaContent.oneConsultationNotStarted,
      dataClick: 'consultation',
    };
  }

  // Has completed haircare consultation and has started skincare consultation
  if (isAuthenticated && hasCompleteHaircareConsultation && !hasCompleteSkincareConsultation) {
    return {
      to: FIRST_SKINCARE_QUESTION_ROUTE,
      label: ctaContent.oneConsultationStarted,
      dataClick: 'finish-consultation',
    };
  }

  // Has completed skincare consultation and has started haircare consultation
  if (isAuthenticated && hasCompleteSkincareConsultation && !hasCompleteHaircareConsultation) {
    return {
      to: FIRST_HAIRCARE_QUESTION_ROUTE,
      label: ctaContent.oneConsultationStarted,
      dataClick: 'finish-consultation',
    };
  }

  // Logged in
  if (isAuthenticated) {
    return {
      to: '/consultation',
      label: ctaContent.oneConsultationStarted,
      dataClick: 'finish-consultation',
    };
  }

  // Logged out
  return {
    to: '/consultation',
    label: ctaContent.newUser(productName),
    dataClick: 'consultation',
  };
};

const ConsultationCTA = ({
  dataFrom,
  dataTestId,
  isAuthenticated,
  isAuthenticating,
  productName,
  render,
  label,
  theme,
  variant,
  canSeeFeedback,
  hasCompleteConsultations,
  hasCompleteHaircareConsultation,
  hasCompleteSkincareConsultation,
  className,
  hasActiveHaircareSubscription,
  hasActiveSkincareSubscription,
  hasNotStartedHaircareConsultation,
  hasNotStartedSkincareConsultation,
  ...props
}) => {
  const params = getRenderParams({
    isAuthenticated,
    productName,
    canSeeFeedback,
    hasCompleteConsultations,
    hasCompleteHaircareConsultation,
    hasCompleteSkincareConsultation,
    hasActiveHaircareSubscription,
    hasActiveSkincareSubscription,
    hasNotStartedHaircareConsultation,
    hasNotStartedSkincareConsultation,
  });

  if (isAuthenticating) {
    return null;
  }

  if (render) {
    return render(params);
  }

  return (
    <Button
      className={className}
      Component={Link}
      data-click={params.dataClick}
      data-from={dataFrom}
      data-testid={dataTestId}
      disabled={Boolean(params.disabled)}
      to={params.to}
      variant={variant ?? theme}
      {...props}
    >
      {label || params.label}
    </Button>
  );
};

ConsultationCTA.propTypes = {
  dataFrom: PropTypes.string,
  dataTestId: PropTypes.string,
  isAuthenticated: PropTypes.bool.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
  productName: PropTypes.string,
  render: PropTypes.func,
  label: PropTypes.string,
  canSeeFeedback: PropTypes.bool,
  hasActiveHaircareSubscription: PropTypes.bool,
  hasActiveSkincareSubscription: PropTypes.bool,
  hasCompleteConsultations: PropTypes.bool,
  hasCompleteHaircareConsultation: PropTypes.bool,
  hasCompleteSkincareConsultation: PropTypes.bool,
  hasNotStartedHaircareConsultation: PropTypes.bool,
  hasNotStartedSkincareConsultation: PropTypes.bool,

  /**
   * @deprecated
   * please use variant instead.
   */
  theme: PropTypes.string,
  variant: variantProps,
  className: PropTypes.string,
};

ConsultationCTA.defaultProps = {
  dataFrom: 'consultation-cta',
  dataTestId: null,
  render: null,
  canSeeFeedback: false,
  hasActiveHaircareSubscription: false,
  hasActiveSkincareSubscription: false,
  hasCompleteConsultations: false,
  hasCompleteHaircareConsultation: false,
  hasCompleteSkincareConsultation: false,
  hasNotStartedHaircareConsultation: false,
  hasNotStartedSkincareConsultation: false,
  /**
   * @deprecated
   * please use variant instead.
   */
  theme: 'sorbet',
  variant: null,
  productName: null,
  label: null,
  className: null,
};

const mapStateToProps = state => ({
  isAuthenticated: getIsAuthenticated(state),
  isAuthenticating: getIsAuthenticating(state),
  canSeeFeedback: userSelectors.getCanSeeFeedback(state),
  hasCompleteConsultations: userSelectors.getHasCompletedAllConsultations(state),
  hasCompleteHaircareConsultation: userSelectors.getHasCompletedHaircareConsultation(state),
  hasCompleteSkincareConsultation: userSelectors.getHasCompletedSkincareConsultation(state),
  hasActiveHaircareSubscription: userSelectors.getHasActiveHaircareSubscription(state),
  hasActiveSkincareSubscription: userSelectors.getHasActiveSkincareSubscription(state),
  hasNotStartedHaircareConsultation: userSelectors.getHasNotStartedHaircareConsultation(state),
  hasNotStartedSkincareConsultation: userSelectors.getHasNotStartedSkincareConsultation(state),
});

export default connect(mapStateToProps, {})(ConsultationCTA);
