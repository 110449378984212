import { createSlice } from '@reduxjs/toolkit';

import { userSignout } from 'dux/auth/actions';

import { fetchCouponsStatuses } from './thunks';

const initialState = {
  isLoading: false,
  data: undefined,
  error: undefined,
};

const couponsStatusesSlice = createSlice({
  name: 'couponsStatuses',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // reinitialize state upon signout
      .addCase(userSignout, () => initialState);

    builder.addCase(fetchCouponsStatuses.pending, draft => {
      draft.isLoading = true;
      draft.data = undefined;
    });
    builder.addCase(fetchCouponsStatuses.fulfilled, (draft, { payload }) => {
      draft.isLoading = false;
      draft.data = payload;
    });
    builder.addCase(fetchCouponsStatuses.rejected, (draft, { error }) => {
      draft.isLoading = false;
      draft.error = error;
    });
  },
});

export const { reducer } = couponsStatusesSlice;
