import { createSlice } from '@reduxjs/toolkit';

import { userSignout } from 'dux/auth/actions';

import { signInMagicLink, signupUser } from './thunks';

const initialState = {
  error: false,
  invalidCoupon: false,
  magicLinkSent: false,
  isLoading: true,
  firstname: '',
};

const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setFirstName: (draft, action) => {
      draft.firstname = action.payload;
    },
    setMagicLinkSent: (draft, action) => {
      draft.magicLinkSent = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      // reinitialize state upon signout
      .addCase(userSignout, () => initialState)

      .addCase(signupUser.pending, draft => {
        draft.isLoading = true;
        draft.error = false;
      })
      .addCase(signupUser.fulfilled, draft => {
        draft.isLoading = false;
        draft.error = false;
      })
      .addCase(signupUser.rejected, (draft, actions) => {
        draft.isLoading = false;
        draft.error = !actions.payload.invalidCoupon;
        draft.invalidCoupon = Boolean(actions.payload.invalidCoupon);
      })
      .addCase(signInMagicLink.pending, draft => {
        draft.error = false;
      })
      .addCase(signInMagicLink.fulfilled, (draft, action) => {
        draft.isLoading = false;
        draft.magicLinkSent = true;
        draft.firstname = action.payload;
        draft.error = false;
      })
      .addCase(signInMagicLink.rejected, draft => {
        draft.isLoading = false;
        draft.error = true;
      });
  },
});

export const { reducer, actions } = signupSlice;
