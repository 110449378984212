import get from 'lodash/fp/get';

import { createSelector } from 'reselect';

import { createApiSelectors } from 'utils/reduxUtils';

import * as checkoutCartSelectors from 'dux/checkoutCart/selectors';

const getCheckoutState = state => state.checkout;

export const [getAddressesData, , getAddressesStatus] = createApiSelectors(
  get('checkout.addresses')
);

// 1-1 copy of legacy logic but as a selector
const getDefaultAddress = createSelector(
  checkoutCartSelectors.getCartData,
  getAddressesData,
  (cart, addresses) =>
    addresses.find(a => a.pubkey === cart?.shipping_address) ||
    addresses.find(a => a.primary) ||
    addresses[0]
);

export const getDefaultAddressPubkey = createSelector(getDefaultAddress, get('pubkey'));

export const getAddressChoiceAfterValidation = createSelector(
  getCheckoutState,
  state => state.addressValidation.addressChoice
);

export const getAddressValidationInvalidFields = createSelector(
  getCheckoutState,
  state => state.addressValidation.addressInvalidFields
);

export const getAddressValidationRecommendedFields = createSelector(
  getCheckoutState,
  state => state.addressValidation.addressRecommendedFields
);
