import { breakpoints } from '@prose-ui/foundations/grid';
import { legacyTheme } from '@prose-ui/legacy';
import { type Em, type Px, px, type Unitless, unitless } from '@prose-ui/utils/units';

import { Viewport } from 'constants/responsive';

import useMedia from 'utils/useMedia';

type BreapointUnit = Px | Em | Unitless;

const { values: legacyBreakpoints } = legacyTheme.breakpoints;
export default function useResponsiveVariant({ legacy = true } = {}) {
  let { sm, md, lg }: { sm: BreapointUnit; md: BreapointUnit; lg: BreapointUnit } = breakpoints;
  // NOTE: we don't have an xs breakpoint for now.
  let xs: BreapointUnit = unitless(0);
  if (legacy) {
    // INFO: but we do have it for legacy values
    xs = px(legacyBreakpoints.xs);
    sm = px(legacyBreakpoints.sm);
    md = px(legacyBreakpoints.md);
    lg = px(legacyBreakpoints.lg);
  }
  const isDesktop = useMedia(`(min-width: ${legacy ? md : lg})`);
  const isTablet = useMedia(`(min-width: ${legacy ? sm : md})`);

  const isLargeDesktop = useMedia(`min-width: ${lg}`);
  const isSmallMobile = useMedia(`min-width: ${xs}`);

  let responsiveVariant: Viewport = Viewport.MOBILE;
  if (isTablet) {
    responsiveVariant = Viewport.TABLET;
  }
  if (isDesktop) {
    responsiveVariant = Viewport.DESKTOP;
  }

  return {
    isMobile: responsiveVariant === Viewport.MOBILE,
    isTablet: responsiveVariant === Viewport.TABLET,
    isDesktop: responsiveVariant === Viewport.DESKTOP,

    isSmallMobile,
    isLargeDesktop,
    responsiveVariant,
  };
}
