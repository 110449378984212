import { MY_LIFESTYLE } from '../../constants';
import * as consultationComponents from '../../constants/consultationComponents';
import { LIFESTYLE } from '../../constants/progressCategories';

const age = {
  name: 'age',
  category: MY_LIFESTYLE,
  progressCategory: LIFESTYLE,
  Component: consultationComponents.QuestionTextInput,
  variant: 'numeric',
  route: `/consultation/skincare/${MY_LIFESTYLE}/age`,
  title: 'What year were you born?',
  subtitle: 'Your age influences your skin and helps us figure out your formula.',
  placeholder: 'Year',
  tip: {
    title: 'Why we ask:',
    content: `Over time, hormonal fluctuations and decreased collagen can change the texture, density, and strength of our skin. We’ll use this info to give you extra support at any stage.

&nbsp;
      
*Please note our skincare products are not intended for use by individuals under the age of 18. These products have not been specifically formulated or tested for this age group.`,
    couldBounce: true,
  },
  shouldBeIncluded: () => true,
  getInitialValue: ({ profile_age_year }) => profile_age_year ?? '',
  getAnswers: ({ selected }) => ({ profile_age_year: selected }),
  public: true,
};

export default age;
