import arginineImg from 'assets/images/productPages/ingredients/arginine.png';
import babassuOilImg from 'assets/images/productPages/ingredients/babassu_oil.png';
import caviarLimeExtractImg from 'assets/images/productPages/ingredients/caviar_lime_extract.png';
import fermentedRiceWaterImg from 'assets/images/productPages/ingredients/fermented_rice_water.png';
import grapeSeedExtractImg from 'assets/images/productPages/ingredients/grape_seed_extract.png';
import horehoundExtractImg from 'assets/images/productPages/ingredients/horehound_extract.png';
import hyaluronicAcidImg from 'assets/images/productPages/ingredients/hyaluronic_acid.png';
import ivyExtractImg from 'assets/images/productPages/ingredients/ivy_extract.png';
import karanjaOilImg from 'assets/images/productPages/ingredients/karanja_oil.png';
import maracujaOilImg from 'assets/images/productPages/ingredients/maracuja_oil.png';
import oatOilImg from 'assets/images/productPages/ingredients/oat_oil.png';
import plumOilImg from 'assets/images/productPages/ingredients/plum_oil.png';
import poppyOilImg from 'assets/images/productPages/ingredients/poppy_oil.png';
import sachaInchiOilImg from 'assets/images/productPages/ingredients/sacha_inchi_oil.png';
import silkProteinsImg from 'assets/images/productPages/ingredients/silk_proteins.png';
import soyProteinsImg from 'assets/images/productPages/ingredients/soy_proteins.png';
import sunflowerOilImg from 'assets/images/productPages/ingredients/sunflower_oil.png';
import vitaminEImg from 'assets/images/productPages/ingredients/vitamin_e.png';
import carouselImg1 from 'assets/images/productPages/prose-custom-conditioner.jpg';
import carouselImg2 from 'assets/images/productPages/prose-custom-conditioner-close-up.jpg';
import carouselImg3 from 'assets/images/productPages/prose-custom-conditioner-in-water.jpg';
import formulaModuleImgDesktop from 'assets/images/productPages/prose-model-posing-with-custom-conditioner-desktop.jpg';
import formulaModuleImgMobile from 'assets/images/productPages/prose-model-posing-with-custom-conditioner-mobile.jpg';
import carouselImg4 from 'assets/images/productPages/prose-model-using-custom-conditioner.jpg';

export default {
  sampleFormulasSlider: [
    {
      title: "NORA'S HYDRATING + PROTECTIVE CONDITIONER",
      ingredients: [
        {
          image: caviarLimeExtractImg,
          name: 'Caviar Lime Extract',
          benefit: 'Shine',
        },
        {
          image: horehoundExtractImg,
          name: 'Horehound Extract',
          benefit: 'Urban Defense',
        },
        {
          image: hyaluronicAcidImg,
          name: 'Hyaluronic Acid',
          benefit: 'Fiber Repair',
        },
        {
          image: plumOilImg,
          name: 'Plum Oil',
          benefit: 'Moisturizer',
        },
        {
          image: karanjaOilImg,
          name: 'Karanja Oil',
          benefit: 'UV Absorber',
        },
        {
          image: vitaminEImg,
          name: 'Vitamin E',
          benefit: 'Antioxidant',
        },
      ],
    },
    {
      title: "LUNA'S SMOOTHING + DEEP REPAIRING CONDITIONER",
      ingredients: [
        {
          image: fermentedRiceWaterImg,
          name: 'Fermented Rice Water',
          benefit: 'Smoothing',
        },
        {
          image: grapeSeedExtractImg,
          name: 'Grape Seed Extract',
          benefit: 'Anti-Breakage',
        },
        {
          image: sachaInchiOilImg,
          name: 'Sacha Inchi Oil',
          benefit: 'Nourishing',
        },
        {
          image: babassuOilImg,
          name: 'Babassu Oil',
          benefit: 'Deep Repair',
        },
        {
          image: maracujaOilImg,
          name: 'Maracuja Oil',
          benefit: 'Hair Health',
        },
        {
          image: silkProteinsImg,
          name: 'Silk Proteins',
          benefit: 'Cuticle Nutrition',
        },
      ],
    },
    {
      title: "CATALINA'S VOLUMIZING + SHINE-ENHANCING CONDITIONER",
      ingredients: [
        {
          image: sunflowerOilImg,
          name: 'Sunflower Oil',
          benefit: 'Antioxidant',
        },
        {
          image: soyProteinsImg,
          name: 'Soy Proteins',
          benefit: 'Volume',
        },
        {
          image: ivyExtractImg,
          name: 'Ivy Extract',
          benefit: 'Pollution Shield',
        },
        {
          image: poppyOilImg,
          name: 'Poppy Oil',
          benefit: 'Shine',
        },
        {
          image: oatOilImg,
          name: 'Oat Oil',
          benefit: 'Color Protection',
        },
        {
          image: arginineImg,
          name: 'Arginine',
          benefit: 'Heat Protect',
        },
      ],
    },
  ],
  proTips: [
    {
      background: 'vert',
      number: '814',
      description:
        'Detangling prior to conditioning is a great way to help remove any hard-to-get tangles. ' +
        'Plus, it allows for the most even distribution and helps you avoid overuse of product.',
    },
    {
      background: 'vert',
      number: '913',
      description:
        'As you rinse off your conditioner, finish with a shot of cool water to lock in moisture and maximize shine.',
    },
  ],
  faq: [
    {
      question: 'Can I use my conditioner as a leave-in product?',
      answer:
        "Prose conditioner should typically be left on for 3-5 minutes as that's all it takes to hydrate, detangle, and soften your strands. " +
        'Our conditioner is not a leave-in product, and should be fully rinsed off after use.',
    },
    {
      question: 'Can this be used as a cleansing conditioner?',
      answer:
        'Prose shampoo and conditioner are formulated as complementary products. Our conditioner focuses on hydrating the lengths (and so much more), but is not a cleansing product. ' +
        'For gentle cleansing and detoxifying, we recommend using Prose shampoo as needed.',
    },
    {
      question: 'Is Prose conditioner color-safe?',
      answer:
        'Yes—if you indicate that you have color-treated hair during the consultation, all of your formulas will be color-safe. For anti-brass selections, formulas will neutralize warm tones in light hair.',
    },
    {
      question: 'I use a bit more conditioner than shampoo. Do you offer larger bottle sizes?',
      answer: `At the moment, our shampoo and conditioner are only available in 8.5 oz bottles. Each bottle lasts approximately 6-8 weeks, which allows you to enjoy your active ingredients at peak freshness.
        We offer free shipping (US only) on all products, so feel free to order your next bottle of conditioner even if your shampoo has yet to run out.`,
    },
  ],
  imageCarousel: [
    {
      image: carouselImg1,
      alt: 'Prose custom conditioner packaging',
    },
    {
      image: carouselImg2,
      alt: 'Prose custom conditioner close up',
    },
    {
      image: carouselImg3,
      alt: 'Prose model using custom conditioner in the shower',
    },
    {
      image: carouselImg4,
      alt: 'Prose-custom-conditioner-in-water',
    },
  ],
  noListModule: {
    description:
      'Every ingredient in your Custom Conditioner is rigorously researched, backed by science, and precisely selected to target your unique hair goals and concerns. We responsibly source only the clean ingredients you need — nothing more, nothing less.',
    title: 'A conditioner with naturally powerful + proven-effective ingredients',
  },
  formulaModule: {
    header: (
      <>
        A personalized conditioner,
        <br /> made to order just for you
      </>
    ),
    description:
      'Every conditioner we formulate is personalized to 80+ factors from your in-depth consultation. Analyzing everything from your hair type to your zip code, we’ll create a formula tailored to your one-of-a-kind needs. Below are just a few examples from the millions of possibilities.',
    hero: {
      imageDesktop: formulaModuleImgDesktop,
      imageMobile: formulaModuleImgMobile,
      alt: 'Prose model posing with bottle of custom conditioner',
    },
  },
};
