import PropTypes from 'prop-types';
import { forbidExtraProps } from 'airbnb-prop-types';

import { styled } from '@prose-ui/legacy';
import FocusTrap from 'focus-trap-react';

import { ReactComponent as Cross } from '../assets/images/cross_icon.svg';

import ModalPortal from './ModalPortal';

const ModalBackdrop = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(40, 51, 51, 0.6);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  padding: ${({ theme, isFullWidth }) => (isFullWidth ? 0 : theme.spacing.s24)}px;
  // The highest z-index known at that time is the checkout ticket at 1500 (not taking into 999999 index instances)
  z-index: ${({ zIndexValue }) => zIndexValue};
  // To be higher than the versions banner (src/Components/Version.js)
`;

const ModalBody = styled.div`
  padding: ${props => props.theme.spacing.s36}px;
  padding-top: ${props => props.theme.spacing.s56}px;
  max-width: ${({ isFullWidth }) => (isFullWidth ? 'none' : '650px')};
  max-height: calc(100vh - 56px);
  overflow: auto;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.palette.backgrounds.white};
  position: relative;
  ${props => props.theme.breakpoints.match('sm')} {
    max-width: ${({ isFullWidth }) => (isFullWidth ? 'none' : '360px')};
    padding: ${props => props.theme.spacing.s24}px;
    padding-top: ${props => props.theme.spacing.s72}px;
    max-height: calc(100vh - ${props => props.theme.spacing.s56}px);
  }
`;

const ModalBodyFullScreen = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  position: relative;
  overflow: auto;

  /**
   * Fallback for dvh:
   * the dynamic unit is supported on modern mobiles only
   * and a small percentage of our users are still on old devices
   */
  height: 100vh;
  @supports (max-height: 100dvh) {
    height: 100dvh;
  }
  padding: 0;
`;

const CloseModalButton = styled.button`
  width: ${props => props.theme.spacing.s24}px;
  height: ${props => props.theme.spacing.s24}px;
  position: absolute;
  right: ${props => props.theme.spacing.s24}px;
  top: ${props => props.theme.spacing.s24}px;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
`;

const BasicModal = ({
  className,
  isOpen,
  onClose,
  children,
  dataFrom,
  dataClick,
  dataTestId,
  isFocusTrapActive,
  hideClose,
  isFullWidth,
  isFullScreen,
  zIndexValue = 10000,
  backgroundColor,
}) => {
  const ModalBodyComponent = isFullScreen ? ModalBodyFullScreen : ModalBody;

  return (
    <ModalPortal isOpen={isOpen}>
      <FocusTrap active={isFocusTrapActive}>
        <ModalBackdrop
          data-testid="subscription-modal-backdrop"
          isFullWidth={isFullWidth}
          onClick={onClose}
          onKeyDown={e => {
            if (e.which === 27 || e.keyCode === 27) {
              onClose();
            }
          }}
          role="dialog"
          zIndexValue={zIndexValue}
        >
          <ModalBodyComponent
            aria-describedby="modal-description modal-conditions"
            aria-labelledby="modal-title"
            backgroundColor={backgroundColor}
            className={className}
            isFullWidth={isFullWidth}
            isOpen={isOpen}
            onClick={e => {
              // This is necessary or clicking on the modal will close it
              e.stopPropagation();
            }}
            role="dialog"
          >
            {children}
            {!hideClose && (
              <CloseModalButton
                aria-label="close"
                data-click={dataClick}
                data-from={dataFrom}
                data-testid={dataTestId}
                id="modal-close-button"
                onClick={onClose}
                type="button"
              >
                <Cross alt="close modal icon" height={24} width={24} />
              </CloseModalButton>
            )}
          </ModalBodyComponent>
        </ModalBackdrop>
      </FocusTrap>
    </ModalPortal>
  );
};

BasicModal.propTypes = forbidExtraProps({
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  dataFrom: PropTypes.string,
  dataClick: PropTypes.string,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isFocusTrapActive: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
  hideClose: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  zIndexValue: PropTypes.number,
});

BasicModal.defaultProps = {
  backgroundColor: '',
  className: null,
  isFullWidth: false,
  dataFrom: 'modal',
  dataClick: 'close',
  dataTestId: 'subscription-modal-closer',
  isFocusTrapActive: true,
  isFullScreen: false,
  hideClose: false,
  zIndexValue: 10000,
};

export default BasicModal;
