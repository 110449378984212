import { useMemo } from 'react';

import { useAppSelector } from 'dux/app/hooks';

import { theme } from '@prose-ui';
import { useTrialOfferPromotionModalContent } from 'hooks/useRedeemCouponForTrialOffer';
import { isKey } from 'types/predicates';

import OfferCheckoutCommunication from 'Apps/Checkout/Blocks/OfferCheckoutCommunication';

import Spacer from 'Components/Spacer';

import currencies, { countriesCodeCurrency, type Currency } from 'constants/currencies';
import { productsCategories } from 'constants/products';

import * as postPurchaseCrossSellOfferContent from 'assets/content/promotionalModalPostPurchaseCrossSell';
import skincareMinisContent from 'assets/content/skincareMinisModal';
import * as skincareWelcomeKitContent from 'assets/content/skincareWelcomeKit';

import { getHasSkincareMinisInCatalog } from 'dux/checkoutCart/selectors';
import * as featureFlagSelectors from 'dux/featureFlags/selectors';
import {
  getHasHaircareSubscriptionInAnyState,
  getHasSkincareSubscriptionInAnyState,
  getUserCurrency,
} from 'dux/user/selectors';
import { usePostPurchaseCrossSellOffer, Variant } from 'dux/featureFlags/postPurchaseCrossSellOfer';
import { useSkincareWelcomeKitOffer } from 'dux/promotions/hooks';

import useCheckoutCategory from '../hooks/useCheckoutCategory';

const CartPromoCommunication = () => {
  const checkoutCategory = useCheckoutCategory();

  const userCurrency = useAppSelector(getUserCurrency) as Currency;
  const userCountry = useMemo(() => {
    if (!isKey(currencies, userCurrency)) return 'US'; // default when currency is unknown
    return Object.entries(countriesCodeCurrency).find(
      ([, value]) => value === userCurrency,
    )![0] as keyof typeof countriesCodeCurrency;
  }, [userCurrency]);

  const hasSkincareSubscriptionInAnyState = useAppSelector(getHasSkincareSubscriptionInAnyState);
  const hasSkincareMinisInCatalog = useAppSelector(getHasSkincareMinisInCatalog);
  const hasHaircareSubscriptionInAnyState = useAppSelector(getHasHaircareSubscriptionInAnyState);

  const postPurchaseCrosssSellOfferVariant = usePostPurchaseCrossSellOffer();

  const isSkincareWelcomeKitOfferAvailable = useSkincareWelcomeKitOffer();
  const showSkincareWelcomeKitOffer =
    isSkincareWelcomeKitOfferAvailable && checkoutCategory === productsCategories.SKINCARE;

  const showTrialOffer = useAppSelector(featureFlagSelectors.shouldShowTrialOffer);
  const { content: trialOfferContent, variant } = useTrialOfferPromotionModalContent();
  const show50DiscountMessage =
    showTrialOffer &&
    ((checkoutCategory === productsCategories.HAIRCARE && !hasHaircareSubscriptionInAnyState) ||
      (checkoutCategory === productsCategories.SKINCARE && !hasSkincareSubscriptionInAnyState));
  const showPostPurchaseCrossSellOffer =
    postPurchaseCrosssSellOfferVariant === Variant.FIRST_ORDER_50_OFFER;

  return (
    <>
      {showSkincareWelcomeKitOffer && (
        <>
          <Spacer size={14} />
          <OfferCheckoutCommunication
            checkoutCommunicationContent={skincareWelcomeKitContent.checkoutCommunication}
            modalContent={skincareWelcomeKitContent.bottomModal[userCountry]}
            padding="32px 16px"
            testPrefix="skincare-welcome-kit-offer"
          />
        </>
      )}
      {!showSkincareWelcomeKitOffer && showPostPurchaseCrossSellOffer && (
        <>
          <Spacer size={14} />
          <OfferCheckoutCommunication
            checkoutCommunicationContent={postPurchaseCrossSellOfferContent.checkoutCommunication}
            modalContent={postPurchaseCrossSellOfferContent.modal}
            padding="32px 16px"
            testPrefix="cross-sell-post-purchase-offer"
          />
        </>
      )}
      {!showSkincareWelcomeKitOffer &&
        !showPostPurchaseCrossSellOffer &&
        hasSkincareMinisInCatalog && (
          <>
            <Spacer size={14} />
            <OfferCheckoutCommunication
              backgroundColor={theme.colors.highlight[200]}
              checkoutCommunicationContent={
                skincareMinisContent.checkoutCommunication[userCurrency]
              }
              dataTestId="checkout-communication-skincare-minis"
              modalContent={skincareMinisContent.bottomModal[userCurrency]}
              padding="32px 32px"
              testPrefix="bottom-modal-skincare-minis"
            />
          </>
        )}
      {!showSkincareWelcomeKitOffer &&
        !showPostPurchaseCrossSellOffer &&
        !hasSkincareMinisInCatalog &&
        show50DiscountMessage && (
          <>
            <Spacer size={14} />
            <OfferCheckoutCommunication
              checkoutCommunicationContent={trialOfferContent.checkoutCommunication}
              dataTestId={`checkout-communication-trial-offer-${variant}`}
              modalContent={trialOfferContent.bottomModal}
              padding="32px 32px"
              testPrefix={`bottom-modal-trial-offer-${variant}`}
            />
          </>
        )}
    </>
  );
};

export default CartPromoCommunication;
