import { matchPath, useLocation } from 'react-router-dom';

import includes from 'lodash/fp/includes';

import { checkoutProductsCategories } from '../constants/checkoutProductsCategories';

const useCheckoutCategory = () => {
  const { pathname } = useLocation();
  const { category } = matchPath('/checkout/:category/*', pathname)?.params || {};
  return includes(category, checkoutProductsCategories) ? category : null;
};

export default useCheckoutCategory;
