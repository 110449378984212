import { useAppSelector } from 'dux/app/hooks';

import { productsCategories } from 'constants/products';

import {
  getCartV2Currency,
  getPaymentFromCustomerV2,
  getSelectedAddressV2,
} from 'dux/cartV2/selectors';
import * as checkoutSelectors from 'dux/checkout/selectors';
import * as checkoutCartSelectors from 'dux/checkoutCart/selectors';

import useCheckoutCategory from './useCheckoutCategory';

const useCreateCreditCardInfosByCartType = () => {
  const checkoutCategory = useCheckoutCategory();

  // Cart V1
  const cartHasSubscription = useAppSelector(checkoutCartSelectors.cartHasSubscription);
  const currency = useAppSelector(checkoutCartSelectors.getCartCurrency);
  const shippingAddress = useAppSelector(checkoutSelectors.getSelectedAddress);
  const totalAmount = useAppSelector(checkoutCartSelectors.getPaymentFromCustomer);

  // Cart V2
  const currencyV2 = useAppSelector(getCartV2Currency);
  const shippingAddressV2 = useAppSelector(getSelectedAddressV2);
  const totalAmountV2 = useAppSelector(getPaymentFromCustomerV2);

  if (checkoutCategory === productsCategories.ACCESSORIES) {
    return {
      currency: currencyV2,
      shippingAddress: shippingAddressV2,
      totalAmount: totalAmountV2,
    };
  }

  return {
    cartHasSubscription,
    currency,
    shippingAddress,
    totalAmount,
  };
};

export default useCreateCreditCardInfosByCartType;
