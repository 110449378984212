import findIndex from 'lodash/fp/findIndex';
import findLastIndex from 'lodash/fp/findLastIndex';

import insertAt from 'utils/insertAt';

import { HAIR_SCALP, LIFESTYLE } from './constants/progressCategories';
import firstName from './questions/firstName';
import hairWelcome from './questions/hair/hairWelcome';
import holdLevel from './questions/holdLevel';
import myGoals from './questions/myGoals';
import myHairAge from './questions/myHairAge';
import myHairDandruffLevel from './questions/myHairDandruffLevel';
import myHairDensity from './questions/myHairDensity';
import myHairGeneticHairLoss from './questions/myHairGeneticHairLoss';
import myHairGray from './questions/myHairGray';
import myHairHairloss from './questions/myHairHairloss';
import myHairLength from './questions/myHairLength';
import myHairOil from './questions/myHairOil';
import myHairPorosity from './questions/myHairPorosity';
import myHairSensitivity from './questions/myHairSensitivity';
import myHairShampooFrequency from './questions/myHairShampooFrequency';
import myHairSplitEnds from './questions/myHairSplitEnds';
import myHairTexture from './questions/myHairTexture';
import myHairThickness from './questions/myHairThickness';
import myHairType from './questions/myHairType';
import myLifestyleDiet from './questions/myLifestyleDiet';
import myLifestyleHormones from './questions/myLifestyleHormones';
import myLifestyleSmokeExposure from './questions/myLifestyleSmokeExposure';
import myLifestyleTransitionScreen from './questions/myLifestyleTransitionScreen';
import myPreferencesExceptions from './questions/myPreferencesExceptions';
import myPreferencesFragrance from './questions/myPreferencesFragrance';
import myPreferencesTransitionScreen from './questions/myPreferencesTransitionScreen';
import myTreatmentsColorTreatments from './questions/myTreatmentsColorTreatments';
import myTreatmentsColorTreatmentsCadence from './questions/myTreatmentsColorTreatmentsCadence';
import myTreatmentsColorTreatmentsLocation from './questions/myTreatmentsColorTreatmentsLocation';
import myTreatmentsColorTreatmentsType from './questions/myTreatmentsColorTreatmentsType';
import myTreatmentsHairStyles from './questions/myTreatmentsHairStyles';
import myTreatmentsOtherTreatments from './questions/myTreatmentsOtherTreatments';
import myTreatmentsOtherTreatmentsCadence from './questions/myTreatmentsOtherTreatmentsCadence';
import myTreatmentsRoutine from './questions/myTreatmentsRoutine';
import myTreatmentsStyles from './questions/myTreatmentsStyles';
import myTreatmentsStylesCadence from './questions/myTreatmentsStylesCadence';
import myTreatmentsTransitionScreen from './questions/myTreatmentsTransitionScreen';
import geoAggressors from './questions/shared/geoAggressors';
import hydration from './questions/shared/hydration';
import signIn from './questions/shared/signIn';
import sports from './questions/shared/sports';
import stress from './questions/shared/stress';
import stressIntensity from './questions/shared/stressIntensity';
import zipCode from './questions/shared/zipCode';
import { MY_LIFESTYLE } from './constants';

/**
 * Partial set
 * Contains every data Qs + welcome screen
 * Has to be extended with the signIn screen
 * Qs before signIn are public
 */
const partialSet = [
  hairWelcome,
  // This is part of an AB Test, this is conditioned by a feature flag
  { ...firstName, public: true },
  // -----------------------------------------------------
  // HAIR AND SCALP
  { ...myHairAge, public: true },
  { ...myHairTexture, public: true },
  { ...myHairLength, public: true },
  { ...myHairType, public: true },
  { ...myHairSplitEnds, public: true },
  { ...myHairPorosity, public: true },
  { ...myHairThickness, public: true },
  { ...myHairDensity, public: true },
  { ...myHairHairloss, public: true },
  { ...myHairGeneticHairLoss, public: true },
  { ...myHairShampooFrequency, public: true },
  { ...myHairOil, public: true },
  { ...myHairDandruffLevel, public: true },
  { ...myHairSensitivity, public: true },
  { ...myHairGray, public: true },
  // -----------------------------------------------------
  // TREATMENT AND STYLING
  { ...myTreatmentsTransitionScreen, public: true },
  { ...myTreatmentsColorTreatments, public: true },
  { ...myTreatmentsColorTreatmentsLocation, public: true },
  { ...myTreatmentsColorTreatmentsType, public: true },
  { ...myTreatmentsColorTreatmentsCadence, public: true },
  { ...myTreatmentsOtherTreatments, public: true },
  { ...myTreatmentsOtherTreatmentsCadence, public: true },
  { ...myTreatmentsStyles, public: true },
  { ...myTreatmentsStylesCadence, public: true },
  { ...myTreatmentsHairStyles, public: true },
  { ...holdLevel, public: true },
  { ...myTreatmentsRoutine, public: true },
  // -----------------------------------------------------
  // LIFESTYLE AND ENVIRONMENT
  { ...myLifestyleTransitionScreen, public: true },
  { ...myLifestyleHormones, public: true },
  { ...myLifestyleDiet, public: true },
  { ...hydration, route: `/consultation/haircare/${MY_LIFESTYLE}/hydration`, public: true },
  { ...myLifestyleSmokeExposure, public: true },
  { ...stress, route: `/consultation/haircare/${MY_LIFESTYLE}/stress`, public: true },
  {
    ...stressIntensity,
    public: true,
    route: `/consultation/haircare/${MY_LIFESTYLE}/stress-intensity`,
    shouldBeIncluded: ({ answers }) => answers.diag_hairloss_level !== 'normal',
    ModalComponent: null,
  },
  { ...sports, route: `/consultation/haircare/${MY_LIFESTYLE}/sports`, public: true },
  // signIn will be inserted here
  { ...zipCode, route: `/consultation/haircare/${MY_LIFESTYLE}/zipcode` },
  { ...geoAggressors, route: `/consultation/haircare/${MY_LIFESTYLE}/geo-aggressors` },
  // -----------------------------------------------------
  // PREFERENCES AND FRAGRANCE
  myPreferencesTransitionScreen,
  myPreferencesExceptions,
  myPreferencesFragrance,
  // -----------------------------------------------------
  // HAIR GOALS
  myGoals,
];

/**
 * Default set
 * Signin before the zip code screen.
 */
const zipcodeIdx = findIndex(q => q.name === 'zipcode')(partialSet);
const defaultSet = insertAt(zipcodeIdx, {
  ...signIn,
  // Agnostic components (used in different categories) must be overridden in a question set
  route: '/consultation/haircare/signin',
  progressCategory: LIFESTYLE,
  title: 'Almost done. Let’s save your results to move forward.',
})(partialSet);

/**
 * Signin first set
 * Signin after the welcome screen
 */
const firstQuestionIdx = findLastIndex(q => q.name === 'welcome')(partialSet) + 1;
export const signinFirstSet = insertAt(firstQuestionIdx, {
  ...signIn,
  // Agnostic components (used in different categories) must be overridden in a question set
  route: '/consultation/haircare/signin',
  progressCategory: HAIR_SCALP,
})(partialSet);

export default defaultSet;
