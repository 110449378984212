import babassuOilImg from 'assets/images/productPages/ingredients/babassu_oil.png';
import barbaryFigOilImg from 'assets/images/productPages/ingredients/barbary_fig_oil.png';
import beechOilImg from 'assets/images/productPages/ingredients/beech_oil.png';
import chicoryOilImg from 'assets/images/productPages/ingredients/chicory_oil.png';
import cucumberOilImg from 'assets/images/productPages/ingredients/cucumber_oil.png';
import appleCiderVinegarImg from 'assets/images/productPages/ingredients/grape_seed_oil.png';
import kalahariMelonOilImg from 'assets/images/productPages/ingredients/kalahari_melon_oil.png';
import maracujaOilImg from 'assets/images/productPages/ingredients/maracuja_oil.png';
import pequiOilImg from 'assets/images/productPages/ingredients/pequi_oil.png';
import riceBranOilImg from 'assets/images/productPages/ingredients/rice_bran_oil.png';
import rosehipOilImg from 'assets/images/productPages/ingredients/rosehip_oil.png';
import sachaInchiOilmg from 'assets/images/productPages/ingredients/sacha_inchi_oil.png';
import sheaOilImg from 'assets/images/productPages/ingredients/shea_oil.png';
import siberianPineNutOilImg from 'assets/images/productPages/ingredients/siberian_pine_nut_oil.png';
import carouselImg1 from 'assets/images/productPages/prose-custom-hair-oil.jpg';
import carouselImg2 from 'assets/images/productPages/prose-custom-hair-oil-dropper-in-hand.jpg';
import carouselImg4 from 'assets/images/productPages/prose-custom-hair-oil-products.jpg';
import formulaModuleImgDesktop from 'assets/images/productPages/prose-model-posing-with-custom-hair-oil-desktop.jpg';
import formulaModuleImgMobile from 'assets/images/productPages/prose-model-posing-with-custom-hair-oil-mobile.jpg';
import carouselImg3 from 'assets/images/productPages/prose-model-using-custom-hair.jpg';

export default {
  sampleFormulasSlider: [
    {
      title: "PAIGE'S NOURISHING + REPAIRING HAIR OIL",
      ingredients: [
        {
          image: appleCiderVinegarImg,
          name: 'Grapeseed Oil',
          benefit: 'Renewal',
        },
        {
          image: cucumberOilImg,
          name: 'Cucumber Oil',
          benefit: 'Hair Health',
        },
        {
          image: siberianPineNutOilImg,
          name: 'Siberian Pine Nut Oil',
          benefit: 'Shine',
        },
        {
          image: sheaOilImg,
          name: 'Shea Oil',
          benefit: 'Repair',
        },
        {
          image: riceBranOilImg,
          name: 'Rice Bran Oil',
          benefit: 'Split End Repair',
        },
        {
          image: rosehipOilImg,
          name: 'Rosehip Oil',
          benefit: 'Moisture',
        },
      ],
    },
    {
      title: "RYAN'S MOISTURIZING + ANTI-FRIZZ HAIR OIL",
      ingredients: [
        {
          image: sachaInchiOilmg,
          name: 'Sacha Inchi Oil',
          benefit: 'Repair',
        },
        {
          image: babassuOilImg,
          name: 'Babassu Oil',
          benefit: 'Hair Health',
        },
        {
          image: siberianPineNutOilImg,
          name: 'Siberian Pine Nut Oil',
          benefit: 'Shine',
        },
        {
          image: chicoryOilImg,
          name: 'Chicory Oil',
          benefit: 'Nourishment',
        },
        {
          image: beechOilImg,
          name: 'Beech Oil',
          benefit: 'Moisture',
        },
        {
          image: pequiOilImg,
          name: 'Pequi Oil',
          benefit: 'Anti-Frizz',
        },
      ],
    },
    {
      title: "TY'S HYDRATING + SHINE-ENHAnCING HAIR OIL",
      ingredients: [
        {
          image: kalahariMelonOilImg,
          name: 'Kalahari Melon Oil',
          benefit: 'Renewal',
        },
        {
          image: barbaryFigOilImg,
          name: 'Barbary Fig Oil',
          benefit: 'Nourishment',
        },
        {
          image: maracujaOilImg,
          name: 'Maracuja Oil',
          benefit: 'Repair',
        },
        {
          image: cucumberOilImg,
          name: 'Cucumber Oil',
          benefit: 'Hair Health',
        },
        {
          image: riceBranOilImg,
          name: 'Rice Bran Oil ',
          benefit: 'Split End Repair',
        },
        {
          image: rosehipOilImg,
          name: 'Rosehip Oil',
          benefit: 'Moisture',
        },
      ],
    },
  ],
  proTips: [
    {
      background: 'noir',
      number: '625',
      description:
        'Use a few drops of your oil on wet hair for fuss-free air drying or on freshly-styled hair to eliminate frizz and smooth out flyaways.',
    },
    {
      background: 'noir',
      number: '421',
      description:
        'Start with your smallest recommended amount, and build up as you go to avoid a too-slick look.',
    },
    {
      background: 'noir',
      number: '914',
      description:
        'Before your next color appointment, apply custom hair oil on strands to preserve their natural oils. ' +
        'Your colorist should apply dye directly over your custom hair oil.',
    },
  ],
  faq: [
    {
      question: 'Is custom hair oil suitable for all hair types?',
      answer:
        'Absolutely. Every hair type can benefit from custom hair oil. ' +
        'Each blend is created to provide just the right amount of hydration and nutrition without weighing down your strands.',
    },
    {
      question: 'Does custom hair oil contain fragrance?',
      answer: 'Custom hair oil has no added fragrance, but contains a light almond oil scent.',
    },
    {
      question: 'Is custom hair oil safe for those with nut allergies?',
      answer:
        'Each custom hair oil contains almond oil, and may contain additional tree nut oils. ' +
        'To see if custom oil can work for you, feel free to send us a note with your specific allergy concerns at hello@prose.com.',
    },
    {
      question: 'Can custom hair oil meet my Prose ingredient preferences?',
      answer:
        'All custom leave-in formulas are vegan. Additionally, you can opt for a silicone-free and/or fragrance-free formula during the consultation.',
    },
  ],
  imageCarousel: [
    {
      image: carouselImg1,
      alt: 'Prose custom hair oil packaging',
    },
    {
      image: carouselImg2,
      alt: 'Prose custom hair oil dropper in hand',
    },
    {
      image: carouselImg3,
      alt: 'Prose model using custom hair oil',
    },
    {
      image: carouselImg4,
      alt: 'Assortment of Prose custom hair oil bottles',
    },
  ],
  noListModule: {
    description:
      'Every ingredient in your Custom Hair Oil is rigorously researched, backed by science, and precisely selected to target your unique hair goals and concerns. We responsibly source only the clean ingredients you need — nothing more, nothing less.',
    title: 'A hair oil with naturally powerful + proven-effective ingredients',
  },
  formulaModule: {
    header: (
      <>
        A personalized hair oil,
        <br /> made to order just for you
      </>
    ),
    description:
      'Every hair oil we formulate is personalized to 80+ factors from your in-depth consultation. Analyzing everything from your hair type to your zip code, we’ll create a formula tailored to your one-of-a-kind needs. Below are just a few examples from the millions of possibilities.',
    hero: {
      imageDesktop: formulaModuleImgDesktop,
      imageMobile: formulaModuleImgMobile,
      alt: 'Prose model posing with bottle of custom hair oil',
    },
  },
};
