import { useAppSelector } from 'dux/app/hooks';

import { productsCategories } from 'constants/products';

import { getCards } from 'dux/accountCards/selectors';
import { getCartV2IsFree } from 'dux/cartV2/selectors';
import { getSelectedCard } from 'dux/checkout/selectors';
import * as checkoutCartSelectors from 'dux/checkoutCart/selectors';

import useCheckoutCategory from './useCheckoutCategory';

const useCheckoutPaymentInfosByCartType = ({ cardPubkey }) => {
  const checkoutCategory = useCheckoutCategory();

  // Mutual selector
  const card = useAppSelector(state => getSelectedCard(state, { cardPubkey }));
  const cards = useAppSelector(getCards);

  // Cart V1
  const cartHasSubscription = useAppSelector(checkoutCartSelectors.cartHasSubscription);
  const isFreeAndCartHasNoSubscription = useAppSelector(
    checkoutCartSelectors.getIsFreeAndCartHasNoSubscription
  );

  // Cart V2
  const isFree = useAppSelector(getCartV2IsFree);

  if (checkoutCategory === productsCategories.ACCESSORIES) {
    return {
      card,
      cards,
      isFree,
      isCartV2: true,
    };
  }

  return {
    card,
    cards,
    cartHasSubscription,
    isFree: isFreeAndCartHasNoSubscription,
  };
};

export default useCheckoutPaymentInfosByCartType;
