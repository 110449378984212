import PropTypes from 'prop-types';

import { Helmet as HelmetLibrary } from 'react-helmet';
import { Outlet, useLocation } from 'react-router-dom';

import getEnv from 'utils/getEnv';

const Helmet = ({
  canonicalURL: specificURL,
  description,
  inheritParentCanonicalURL,
  noIndex,
  outlet,
  title,
}) => {
  const { pathname } = useLocation();

  /**
   * To be correctly set, a canonical tag must contain the appropriate domain name (e.g. http://localhost, https://prose.com)
   * and the pathname (e.g. /product/haircare, /checkout/haircare).
   * Even if the final goal is to set this tag only in production, we want it to be also correct on each environment (local, staging, preprod, prod)
   * to facilitate QA when non-tech people use SEO tools to verify the correctness of the tag.
   */
  const domainName = getEnv('REACT_APP_DOMAIN_NAME') ?? 'https://prose.com';
  /**
   * If a canonical URL isn't specified, 2 possibilities:
   * - To avoid duplicate content and tell search engines that different pages are actually the same one,
   * we can declare a same canonical URL for several URL paths (e.g. /supplements, /supplements-search, etc.).
   * - By default, we compute the canonical URL based on the URL path.
   */
  const canonicalURL = specificURL ?? (!inheritParentCanonicalURL ? pathname : null);

  /**
   * With Next.js App Router, Helmet doesn't work anymore for React Router pages.
   * The metadata must be overridden this way.
   * Once converted to a Next.js page, the metadata object must be used instead (see src/app/layout.tsx).
   */
  document.title = title;
  const descriptionMetaTag = document.querySelector('meta[name="description"]');
  if (descriptionMetaTag) {
    descriptionMetaTag.content = description;
  }
  const canonicalMetaTag = document.querySelector('link[rel="canonical"]');
  if (canonicalURL && canonicalMetaTag) {
    canonicalMetaTag.href = `${domainName}${canonicalURL}`;
  }
  const noIndexMetaTag = document.querySelector('meta[name="robots"]');
  if (noIndex && noIndexMetaTag) {
    noIndexMetaTag.content = 'noindex';
  }

  return (
    <>
      <HelmetLibrary>
        <title>{title}</title>
        <meta content={description} name="description" />
        {noIndex && <meta content="noindex" name="robots" />}
        {canonicalURL && <link href={`${domainName}${canonicalURL}`} rel="canonical" />}
      </HelmetLibrary>
      {outlet && <Outlet />}
    </>
  );
};

Helmet.propTypes = {
  canonicalURL: PropTypes.string,
  description: PropTypes.string,
  noIndex: PropTypes.bool,
  outlet: PropTypes.bool,
  inheritParentCanonicalURL: PropTypes.bool,
  title: PropTypes.string,
};

Helmet.defaultProps = {
  canonicalURL: null,
  description:
    'Discover the difference of truly custom haircare and skincare with Prose: Personalized formulas made just for you. Take your free consultation now.',
  noIndex: false,
  outlet: false,
  inheritParentCanonicalURL: false,
  title: 'Truly Custom Haircare and Skincare | Prose',
};

export default Helmet;
