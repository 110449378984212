import cancel from 'assets/images/membership/cancel.svg';
import freeProduct from 'assets/images/membership/free-product.svg';
import freeShipping from 'assets/images/membership/free-shipping.svg';

export const subscriptionPerksContent = {
  title: {
    haircare: {
      onlySkincareSubscription: (
        <>
          <b>Save 20%</b> on all subscription orders when you also subscribe to haircare
        </>
      ),
      onlyHaircareSubscription: (
        <>
          <b>Save 20%</b> on all subscription orders when you also subscribe to skincare
        </>
      ),
      bothSubscription: (
        <>
          You&apos;re <b>saving 20%</b> as an haircare and skincare subscriber!
        </>
      ),
      noSubscription: (
        <>
          <b>Save 15%</b> when you subscribe to haircare
        </>
      ),
    },
    skincare: {
      onlySkincareSubscription: (
        <>
          <b>Save 20%</b> on all subscription orders when you also subscribe to haircare
        </>
      ),
      onlyHaircareSubscription: (
        <>
          <b>Save 20%</b> on all subscription orders when you also subscribe to skincare
        </>
      ),
      bothSubscription: (
        <>
          You&apos;re <b>saving 20%</b> as an haircare and skincare subscriber!
        </>
      ),
      noSubscription: (
        <>
          <b>Save 15%</b> when you subscribe to skincare
        </>
      ),
    },
  },
  content: [
    { logo: cancel, text: 'Cancel any time', alt: 'cross logo', width: 14, height: 14 },
    { logo: freeShipping, text: 'Free shipping', alt: 'truck logo', width: 23, height: 12 },
    {
      logo: freeProduct,
      text: 'Buy 10 products, get 1 free',
      alt: 'shampoo bottle logo',
      width: 17,
      height: 19,
    },
  ],
};
