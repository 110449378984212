import get from 'lodash/fp/get';

import { createSelector } from 'reselect';

import AuthStatus from 'dux/auth/constants/authStatuses';

const getGuestAuth = get('guestAuth');

export const getIsGuestUserAuthenticated = createSelector(
  getGuestAuth,
  (status) => status === AuthStatus.AUTHENTICATED,
);
