import PropTypes from 'prop-types';
import { forbidExtraProps } from 'airbnb-prop-types';

import { useLocation } from 'react-router-dom';

import { connect } from 'react-redux';

import find from 'lodash/fp/find';

import useHasNoFreeShipping from 'hooks/useHasNoFreeShipping';

import useCheckoutCategory from 'Apps/Checkout/hooks/useCheckoutCategory';

import * as authSelectors from 'dux/auth/selectors';
import * as couponsStatusesSelectors from 'dux/couponsStatuses/selectors';
import * as featureFlagsSelector from 'dux/featureFlags/selectors';
import {
  getFirstActiveGiftWithPurchaseBannerData,
  getIsGiftWithPurchaseBannerActive,
} from 'dux/giftWithPurchase/selectors';
import * as userSelectors from 'dux/user/selectors';

import * as Banners from './Banners';

const bannersByPriority = [
  Banners.loading, // Loading needs to be first
  Banners.checkoutMessage,
  Banners.welcomeKitOffer,
  Banners.trialOfferMixedSkincareMinisBanner,
  Banners.skincareMinisBanner,
  Banners.trialOfferBanner,
  Banners.postPurchaseCrossSellFirstOrder50Offer,
  Banners.postPurchaseCrossSellSubscription20Offer,
  Banners.postPurchaseCrossSellFreeCleanserOffer,
  Banners.promotionAccessoriesOffer,
  Banners.giftWithPurchase,
  Banners.referralCta,
  Banners.membershipMultiCategoryWithOneSubscription,
  Banners.membershipMultiCategoryWithoutSubscription,
  Banners.membershipCta,
  Banners.noFreeShippingContent,
  Banners.supplementsPages,
  Banners.defaultBanner, // Default needs to be last
];

const Banner = ({
  canSubscribe,
  haveSubscription,
  hideBannerLink,
  isAuthenticated,
  isAuthenticating,
  isFlagsDoneFetching,
  isGiftWithPurchaseEligible,
  giftWithPurchaseBannerData,
  hasEitherHairOrSkinSubscription,
  hasHaircareSubscription,
  hasSkincareSubscription,
  hasHairAndSkinSubscriptions,
  hasCompletedHaircareConsultation,
  hasCompletedSkincareConsultation,
  hasStartedHaircareConsultation,
  hasStartedSkincareConsultation,
  postPurchaseCrossSellVariantName,
  isCouponsStatusesLoading,
  couponsStatuses,
  showWelcomeKitVisibility,
  trialOfferV2ABTestVariant,
  showPromotionAccessories2023,
  showTrialOffer,
  hasSkincareOrder,
  showEvergreenWelcomeKit,
  showSkincareMinisPhase2,
  hasSkincareSubscriptionInAnyState,
  hasHaircareSubscriptionInAnyState,
}) => {
  const checkoutCategory = useCheckoutCategory();
  const hasNoFreeShipping = useHasNoFreeShipping();
  const { pathname: locationPathname } = useLocation();

  const foundBanner = find(({ shouldRender }) =>
    shouldRender({
      isAuthenticated,
      canSubscribe,
      hasNoFreeShipping,
      haveSubscription,
      isFlagsDoneFetching,
      isGiftWithPurchaseEligible,
      locationPathname,
      giftWithPurchaseBannerData,
      hasEitherHairOrSkinSubscription,
      hasHaircareSubscription,
      hasSkincareSubscription,
      hasHairAndSkinSubscriptions,
      checkoutCategory,
      postPurchaseCrossSellVariantName,
      isCouponsStatusesLoading,
      couponsStatuses,
      hasCompletedHaircareConsultation,
      hasCompletedSkincareConsultation,
      hasStartedHaircareConsultation,
      hasStartedSkincareConsultation,
      showWelcomeKitVisibility,
      trialOfferV2ABTestVariant,
      showPromotionAccessories2023,
      showTrialOffer,
      hasSkincareOrder,
      showEvergreenWelcomeKit,
      showSkincareMinisPhase2,
      hasSkincareSubscriptionInAnyState,
      hasHaircareSubscriptionInAnyState,
    })
  )(bannersByPriority);

  if (foundBanner) {
    return foundBanner.render(
      hideBannerLink || isAuthenticating || !isAuthenticated || haveSubscription || !canSubscribe,
      {
        giftWithPurchaseBannerData,
        couponsStatuses,
        hasHaircareSubscription,
        hasSkincareSubscription,
        hasCompletedHaircareConsultation,
        hasCompletedSkincareConsultation,
        showEvergreenWelcomeKit,
      }
    );
  }
  return null;
};

Banner.propTypes = forbidExtraProps({
  canSubscribe: PropTypes.bool,
  showSkincareMinisPhase2: PropTypes.bool,
  hasSkincareSubscriptionInAnyState: PropTypes.bool,
  hasHaircareSubscriptionInAnyState: PropTypes.bool,
  haveSubscription: PropTypes.bool,
  hideBannerLink: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  isAuthenticating: PropTypes.bool,
  isFlagsDoneFetching: PropTypes.bool,
  isGiftWithPurchaseEligible: PropTypes.bool,
  giftWithPurchaseBannerData: PropTypes.shape({
    lp_images: PropTypes.shape({
      mobile: PropTypes.string,
      tablet: PropTypes.string,
      desktop: PropTypes.string,
    }),
    lp_cta_text: PropTypes.string,
    lp_cta_url: PropTypes.string,
    lp_gift_description: PropTypes.string,
    lp_legal_text: PropTypes.string,
    lp_title: PropTypes.string,
    banner_color: PropTypes.string,
    banner_redirection_url: PropTypes.string,
    banner_text: PropTypes.string,
  }),
  hasSkincareOrder: PropTypes.bool,
  showEvergreenWelcomeKit: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  hasEitherHairOrSkinSubscription: PropTypes.bool,
  hasHaircareSubscription: PropTypes.bool,
  hasSkincareSubscription: PropTypes.bool,
  hasHairAndSkinSubscriptions: PropTypes.bool,
  trialOfferV2ABTestVariant: PropTypes.string,
  postPurchaseCrossSellVariantName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isCouponsStatusesLoading: PropTypes.bool,
  couponsStatuses: PropTypes.shape({
    coupons: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        is_attached_to_customer: PropTypes.bool,
        status: PropTypes.oneOf(['created', 'redeemed']),
      })
    ),
    preferred_currency: PropTypes.oneOf(['USD', 'CAD']),
    country: PropTypes.oneOf(['US', 'CA']),
  }),
  hasCompletedHaircareConsultation: PropTypes.bool,
  hasCompletedSkincareConsultation: PropTypes.bool,
  hasStartedHaircareConsultation: PropTypes.bool,
  hasStartedSkincareConsultation: PropTypes.bool,
  showWelcomeKitVisibility: PropTypes.bool,
  showPromotionAccessories2023: PropTypes.bool,
  showTrialOffer: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
});

Banner.defaultProps = {
  showEvergreenWelcomeKit: false,
  canSubscribe: false,
  haveSubscription: false,
  hideBannerLink: false,
  isAuthenticated: false,
  trialOfferV2ABTestVariant: null,
  isAuthenticating: true,
  isFlagsDoneFetching: false,
  isGiftWithPurchaseEligible: false,
  giftWithPurchaseBannerData: null,
  hasEitherHairOrSkinSubscription: false,
  hasSkincareOrder: false,
  hasHaircareSubscription: false,
  hasSkincareSubscription: false,
  hasHairAndSkinSubscriptions: false,
  postPurchaseCrossSellVariantName: null,
  isCouponsStatusesLoading: false,
  couponsStatuses: undefined,
  hasCompletedHaircareConsultation: false,
  hasCompletedSkincareConsultation: false,
  hasStartedHaircareConsultation: false,
  hasStartedSkincareConsultation: false,
  showWelcomeKitVisibility: false,
  showPromotionAccessories2023: false,
  showTrialOffer: false,
  showSkincareMinisPhase2: false,
  hasSkincareSubscriptionInAnyState: false,
  hasHaircareSubscriptionInAnyState: false,
};

// Name to be changed
export const StatelessBanner = Banner;

export default connect(
  state => ({
    canSubscribe: userSelectors.getCanSubscribe(state),
    haveSubscription: userSelectors.getHasActiveSubscription(state),
    isAuthenticated: authSelectors.getIsAuthenticated(state),
    isAuthenticating: authSelectors.getIsAuthenticating(state),
    isFlagsDoneFetching: featureFlagsSelector.isFlagsDoneFetching(state),
    isGiftWithPurchaseEligible: getIsGiftWithPurchaseBannerActive(state),
    giftWithPurchaseBannerData: getFirstActiveGiftWithPurchaseBannerData(state),
    hasEitherHairOrSkinSubscription: userSelectors.getHasEitherHairOrSkinSubscription(state),
    hasSkincareOrder: userSelectors.getHasOrdersByCategory(state).skincare,
    hasHaircareSubscription: userSelectors.getHasActiveHaircareSubscription(state),
    hasSkincareSubscription: userSelectors.getHasActiveSkincareSubscription(state),
    hasHairAndSkinSubscriptions: userSelectors.getHasHairAndSkinSubscriptions(state),
    hasCompletedHaircareConsultation: userSelectors.getHasCompletedHaircareConsultation(state),
    hasCompletedSkincareConsultation: userSelectors.getHasCompletedSkincareConsultation(state),
    hasStartedHaircareConsultation: userSelectors.getHasDraftHaircareConsultation(state),
    hasStartedSkincareConsultation: userSelectors.getHasDraftSkincareConsultation(state),
    postPurchaseCrossSellVariantName:
      featureFlagsSelector.getActiveOrVariantForPostPurchaseCrossSellOffer(state),
    isCouponsStatusesLoading: couponsStatusesSelectors.getIsLoading(state),
    couponsStatuses: couponsStatusesSelectors.getCouponsStatuses(state),
    showWelcomeKitVisibility: featureFlagsSelector.shouldShowWelcomeKitVisibility(state),
    showPromotionAccessories2023:
      featureFlagsSelector.shouldShowPromotionAccessoriesforTargetedUser(state),
    showTrialOffer: featureFlagsSelector.shouldShowTrialOffer(state),
    showEvergreenWelcomeKit: featureFlagsSelector.shouldShowEvergreenWelcomeKit(state),
    showSkincareMinisPhase2: featureFlagsSelector.shouldShowSkincareMinisPhase2(state),
    hasSkincareSubscriptionInAnyState: userSelectors.getHasSkincareSubscriptionInAnyState(state),
    hasHaircareSubscriptionInAnyState: userSelectors.getHasHaircareSubscriptionInAnyState(state),
  }),
  {}
)(Banner);
