import { fragranceNewChoice } from 'Apps/Feedback/constants/feedbackSlugs';

import { capitalizeFirstLetter } from 'utils/textUtils';

const haircareFragranceNewChoice = {
  name: fragranceNewChoice,
  category: 'fragrance',
  route: `/feedback/haircare/${fragranceNewChoice}`,
  shouldBeIncluded: ({ feedbackAnswers, isSoldoutFragrance }) =>
    feedbackAnswers?.new_fragrance || isSoldoutFragrance,
  component: 'FragranceContainer',
  title: 'Which fragrance would you like to try?',
  subtitle:
    'Note: Your chosen fragrance may not apply to all of your custom formulas, as certain products are fragrance-free or lightly scented with essential oil.',
  options: ({ currentFragrance }) => [
    { value: true, label: "Yes, I'd like to try something new" },
    {
      value: false,
      label: `No, I'd like to keep ${capitalizeFirstLetter(currentFragrance)}`,
    },
  ],
  getInitialValue: answers => answers?.fragrance_new_choice,
  getAnswers: ({ selected }) => ({
    fragrance_new_choice: selected === 'NONE' ? 'frag_free' : selected,
  }),
};

export default haircareFragranceNewChoice;
