import {
  FIRST_HAIRCARE_QUESTION_ROUTE,
  FIRST_SKINCARE_QUESTION_ROUTE,
} from 'Apps/Consultation/constants';

import { HAIRCARE_CART_URL, SKINCARE_CART_URL } from 'constants/cart';

import imageHC from 'assets/images/promotionalModals/trial-offer-pouches-gwp-hc-promotion-modal.jpg';
import imageSC from 'assets/images/promotionalModals/trial-offer-pouches-gwp-sc-promotion-modal.jpg';

const content = {
  contentDefault: {
    banner: 'July 4th Deal: 50% off + a free gift',
    popUpModal: {
      image: imageHC,
      headerTitle: 'July 4th Deal',
      title: '50% off + 2 free travel pouches',
      description: 'Subscribe to 3+ hair or skin products to claim your free gift.',
      cta: {
        consultationCompleted: {
          linkTo: () => HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'hc-sc-pop-up',
          ctaLabel: 'unlock offer',
        },
        consultationNotCompleted: {
          linkTo: (route: string) => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'hc-sc-pop-up',
          ctaLabel: 'unlock offer',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'hc-sc-pop-up',
      },
      conditions:
        '*Get [2] free Travel Pouches ($16 value) when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off, or 20% off when subscribed to both Haircare and Skincare. Offer is valid when your subscription order is placed by 07/09/2024 at 11:59 PM EST or while supplies last. Gift with purchase selection randomized. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
      tracking: {
        modalClosing: 'Trial Offer v2 HC + SC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 HC + SC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 HC + SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'July 4th Deal',
      description: (
        <>
          Get <b>50% off + 2 free travel pouches</b> when you subscribe to 3+ hair or skin products.
        </>
      ),
      conditions:
        '*Get [2] free Travel Pouches ($16 value) when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off, or 20% off when subscribed to both Haircare and Skincare. Offer is valid when your subscription order is placed by 07/09/2024 at 11:59 PM EST or while supplies last. Gift with purchase selection randomized. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
    },
    checkoutCommunication: {
      title: 'July 4th Deal',
      description: (
        <>
          Get <b>50% off + 2 free travel pouches</b> when you subscribe to 3+ hair or skin products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  contentHaircare: {
    banner: 'July 4th Deal: 50% off + a free gift',
    popUpModal: {
      image: imageHC,
      headerTitle: 'July 4th Deal',
      title: '50% off + 2 free travel pouches',
      description: 'Subscribe to 3+ haircare products to claim your free gift.',
      cta: {
        consultationCompleted: {
          linkTo: () => HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'hc-pop-up',
          ctaLabel: 'unlock offer',
        },
        consultationNotCompleted: {
          linkTo: (route: string) => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'hc-pop-up',
          ctaLabel: 'unlock offer',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'hc-pop-up',
      },
      conditions:
        '*Get [2] free Travel Pouches ($16 value) when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off, or 20% off when subscribed to both Haircare and Skincare. Offer is valid when your subscription order is placed by 07/09/2024 at 11:59 PM EST or while supplies last. Gift with purchase selection randomized. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
      tracking: {
        modalClosing: 'Trial Offer v2 HC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 HC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 HC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'July 4th Deal',
      description: (
        <>
          Get <b>50% off + 2 free travel pouches ($16 value)</b> when you subscribe to 3+ hair
          products.
        </>
      ),
      conditions:
        '*Get [2] free Travel Pouches ($16 value) when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off, or 20% off when subscribed to both Haircare and Skincare. Offer is valid when your subscription order is placed by 07/09/2024 at 11:59 PM EST or while supplies last. Gift with purchase selection randomized. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
    },
    checkoutCommunication: {
      title: 'July 4th Deal',
      description: (
        <>
          Get <b> 50% off + 2 free travel pouches</b> when you subscribe to 3+ hair products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  contentSkincare: {
    banner: 'July 4th Deal: 50% off + a free gift',
    popUpModal: {
      image: imageSC,
      headerTitle: 'July 4th Deal',
      title: '50% off + 2 free travel pouches',
      description: 'Subscribe to 3+ skincare products to claim your free gift.',
      cta: {
        consultationCompleted: {
          linkTo: () => SKINCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'sc-pop-up',
          ctaLabel: 'unlock offer',
        },
        consultationNotCompleted: {
          linkTo: () => FIRST_SKINCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'sc-pop-up',
          ctaLabel: 'unlock offer',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'sc-pop-up',
      },
      conditions:
        '*Get [2] free Travel Pouches ($16 value) when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off, or 20% off when subscribed to both Haircare and Skincare. Offer is valid when your subscription order is placed by 07/09/2024 at 11:59 PM EST or while supplies last. Gift with purchase selection randomized. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
      tracking: {
        modalClosing: 'Trial Offer v2 SC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 SC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'July 4th Deal',
      description: (
        <>
          Get <b>50% off + two free travel pouches ($16 value)</b> when you subscribe to 3+ skincare
          products.
        </>
      ),
      conditions:
        '*Get [2] free Travel Pouches ($16 value) when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off, or 20% off when subscribed to both Haircare and Skincare. Offer is valid when your subscription order is placed by 07/09/2024 at 11:59 PM EST or while supplies last. Gift with purchase selection randomized. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
    },
    checkoutCommunication: {
      title: 'July 4th Deal',
      description: (
        <>
          Get <b>50% off + two free travel pouches ($16 value)</b> when you subscribe to 3+ skincare
          products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
};

export default content;
