import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import clamp from 'lodash/fp/clamp';

import { styled } from '@prose-ui/legacy';
import { mediaMinWidth } from '@prose-ui/utils/media';
import { animated, easings, useSpring } from '@react-spring/web';

import useEffectOnResize from 'utils/useEffectOnResize';
import useRerenderOnResize from 'utils/useRerenderOnResize';

const Container = styled.div`
  position: relative;
`;

const Slider = styled(animated.div)`
  width: 100%;

  contain: inline-size;
  @supports not (contain: inline-size) {
    display: grid; /* this is only to create a new formatting context (see Block Formatting Context) and obtain the same layout behaviour as 'contain: inline-size' */
  }

  overflow: auto;
  position: relative; /* required to make the snap work properly */
  scroll-snap-type: none;
  &[data-snap] {
    scroll-snap-type: x mandatory;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const Track = styled.div<{
  gap: string;
  layoutMode: 'flex' | 'grid';
  trackPadding: string;
}>`
  width: fit-content;

  ${({ layoutMode }) =>
    layoutMode === 'flex'
      ? `
  display: flex;
  `
      : `
  display: grid;
  grid-auto-flow: column;
  `}

  gap: ${({ gap }) => gap};

  padding-inline: ${({ trackPadding }) => trackPadding};

  margin-inline: auto;

  &[data-align='left'] {
    margin-inline: 0 auto;
  }
`;

const ArrowsVisibilityWrapper = styled.div`
  display: none; /* no arrow on mobile */
  ${mediaMinWidth('lg')} {
    display: contents;
  }
  @media (any-pointer: fine) {
    display: contents; /* a11y: display the arrows if any mouse-like pointer is detected, regardless of the screen size */
  }
`;

type Without<T> = {
  [K in keyof T]?: undefined;
};

type BaseProps = {
  slides: Array<React.ReactElement>;
  gap?: string; // TODO: use SpacingToken
  layoutMode?: 'flex' | 'grid';
  forcedFocus?: number; // for controlling from outside (optional)
  snap?: boolean; // only 'center' for now. TODO: allow for 'start' | 'end' values instead of boolean
  alignTrack?: 'center' | 'left';
  onFocusedSlideChange?: (closestSlideIndex: number) => void;
  trackPadding?: string;
};

type ArrowComponent = React.FC<{
  isActive: boolean;
  onClick?: React.MouseEventHandler;
}>;

type ArrowProps =
  | {
      arrowsForceScroll: true; // arrow controls managed inside this component
      ArrowPrevious: ArrowComponent; // the plain component
      ArrowNext: ArrowComponent; // the plain component
    }
  | {
      arrowsForceScroll?: false; // arrow controls managed outside of this component (using forcedFocus)
      forcedFocus: number; // becomes required !
      ArrowPrevious: React.ReactElement; // JSX
      ArrowNext: React.ReactElement; // JSX
    };

const HorizontalSlider = ({
  slides,
  gap = '0',
  layoutMode = 'flex',
  forcedFocus,
  ArrowPrevious,
  ArrowNext,
  arrowsForceScroll = false,
  snap = false,
  alignTrack = 'center',
  trackPadding = '0',
  onFocusedSlideChange,
}: (BaseProps & Without<ArrowProps>) | (BaseProps & ArrowProps)) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const trackRef = useRef<HTMLDivElement>(null);

  const [isForceScrolling, setIsForceScrolling] = useState(false);

  useRerenderOnResize(); // we are using JS to get sizes of elements and do stuff with it

  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffectOnResize(() =>
    setIsOverflowing(
      Boolean(sliderRef.current && sliderRef.current.scrollWidth > sliderRef.current.offsetWidth),
    ),
  );

  const [{ x }, spring] = useSpring(
    () => ({
      x: 0, // used to set scrollLeft in JSX
      config: { decay: 0.9975, precision: 0.15 },
    }),
    [],
  );

  const forceScrollToElement = useCallback((element: HTMLElement) => {
    const sliderElement = sliderRef.current;
    const trackElement = trackRef.current;
    if (sliderElement !== null && trackElement !== null) {
      const startPos = sliderElement.scrollLeft ?? 0;
      const targetPos = clamp(
        /* min */ 0,
        /* max */ trackElement.offsetWidth - sliderElement.offsetWidth, // clamp between 0 and the container's width
        element.offsetLeft - sliderElement.offsetWidth / 2 + element.offsetWidth / 2,
      );

      if (startPos === targetPos) return; // do nothing if we don't move

      sliderElement.style.overflow = 'hidden'; // workaround to avoid conflict with native scroll momentum
      setIsForceScrolling(true);

      spring.start({
        from: { x: startPos },
        to: { x: targetPos },
        /* here we set x so it scrolls until the clicked element is centered (or is clamped) using scrollLeft */
        config: { duration: 300, easing: easings.easeOutCubic },
        onRest: () => {
          sliderElement.style.overflow = 'auto'; // workaround to avoid conflict with native scroll momentum
          setIsForceScrolling(false);
        },
      });
    }
  }, []);

  const findClosestSlideElement = () => {
    const sliderElement = sliderRef.current!;
    const trackElement = trackRef.current!;
    const currScrollX = sliderElement.scrollLeft;
    const slideElements = [...trackElement.children] as Array<HTMLElement>;

    if (currScrollX === 0) {
      return { element: slideElements[0], index: 0 };
    }

    if (trackElement.offsetWidth - currScrollX <= sliderElement.offsetWidth) {
      return { element: slideElements.at(-1), index: slideElements.length - 1 };
    }

    // set an initial closest element
    let closestElement = null as null | HTMLElement;
    let closestDistance = Infinity as number;
    let closestIndex = null as null | number;

    // loop through the rest of the slideElements and find the actual closest one.
    slideElements.forEach((currSlideEl, index) => {
      const currSlideDistance = Math.abs(
        currScrollX +
          sliderElement.offsetWidth / 2 -
          (currSlideEl.offsetLeft + currSlideEl.offsetWidth / 2),
      );
      if (currSlideDistance < closestDistance) {
        closestElement = currSlideEl;
        closestDistance = currSlideDistance;
        closestIndex = index;
      }
    });

    return { element: closestElement, index: closestIndex };
  };

  const [closestSlideIndex, setClosestSlideIndex] = useState(forcedFocus ?? 0);

  useEffect(() => {
    onFocusedSlideChange?.(closestSlideIndex);
  }, [closestSlideIndex]);

  // focus animation
  useLayoutEffect(() => {
    if (isForceScrolling) return;
    if (typeof forcedFocus !== 'undefined') {
      const focusedSlideElement = trackRef.current?.children[forcedFocus];
      if (focusedSlideElement instanceof HTMLElement) {
        forceScrollToElement(focusedSlideElement);
      }
    }
  }, [forcedFocus]);

  const renderSnapTargets = useCallback(() => {
    return (
      <div style={{ display: 'contents' }}>
        {trackRef.current &&
          ([...trackRef.current.children] as Array<HTMLElement>).map((slideEl, index) => (
            <div
              key={index} // TODO: use key defined on slides?
              style={{
                scrollSnapAlign: 'center',
                pointerEvents: 'none',
                height: '100%',
                width: slideEl.offsetWidth,
                position: 'absolute',
                top: '0',
                left: slideEl.offsetLeft,
              }}
            />
          ))}
      </div>
    );
  }, [slides]);

  const renderArrows = useCallback(() => {
    // no arrows if no overflow
    if (!(isOverflowing && ArrowPrevious && ArrowNext)) {
      return null;
    }

    if (arrowsForceScroll) {
      const FCArrowPrevious = ArrowPrevious as ArrowComponent;
      const FCArrowNext = ArrowNext as ArrowComponent;

      const sliderElement = sliderRef.current!;
      const currScrollX = sliderElement.scrollLeft;
      const trackElement = trackRef.current!;
      const slideElements = [...trackElement!.children] as Array<HTMLElement>;

      const outOfViewThreshold = slideElements[0]!.offsetWidth * 0.33; // in px;

      const findNextOutOfViewElement = () =>
        slideElements.find(
          (slideEl) =>
            slideEl.offsetLeft + slideEl.offsetWidth - outOfViewThreshold >
            currScrollX + sliderElement.offsetWidth,
        );
      const findPreviousOutOfViewElement = () =>
        slideElements.findLast((slideEl) => slideEl.offsetLeft + outOfViewThreshold < currScrollX);

      return (
        <>
          <FCArrowPrevious
            isActive={currScrollX > outOfViewThreshold}
            onClick={() => {
              forceScrollToElement(findPreviousOutOfViewElement() ?? slideElements[0]!); // put first out of view element in the center
            }}
          />
          <FCArrowNext
            isActive={
              currScrollX <
              trackElement.offsetWidth - sliderElement.offsetWidth - outOfViewThreshold
            }
            onClick={() => {
              forceScrollToElement(findNextOutOfViewElement() ?? slideElements.at(-1)!); // put first out of view element in the center
            }}
          />
        </>
      );
    }

    return (
      <>
        {ArrowPrevious}
        {ArrowNext}
      </>
    );
  }, [isOverflowing, arrowsForceScroll, ArrowPrevious, ArrowNext]);

  return (
    <Container>
      <Slider
        ref={sliderRef}
        data-snap={snap && !isForceScrolling ? '' : undefined}
        onScroll={() => {
          setClosestSlideIndex(findClosestSlideElement().index!);
        }}
        scrollLeft={x}
      >
        <Track
          ref={trackRef}
          data-align={alignTrack}
          gap={gap}
          layoutMode={layoutMode}
          trackPadding={trackPadding}
        >
          {slides}
        </Track>
        {snap && renderSnapTargets()}
      </Slider>
      <ArrowsVisibilityWrapper>{renderArrows()}</ArrowsVisibilityWrapper>
    </Container>
  );
};

export default HorizontalSlider;
