import { FRAGRANCE } from 'Apps/Feedback/constants/feedbackSections';
import { haircareTransitionFragrance } from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs, productsWithFragrance } from 'constants/products';

import auraTransitionImgDesktop from 'assets/images/reviewAndRefine/fragrance-transition-aura_desktop.jpg';
import auraTransitionImgMobile from 'assets/images/reviewAndRefine/fragrance-transition-aura_mobile.jpg';
import candelaTransitionImgDesktop from 'assets/images/reviewAndRefine/fragrance-transition-candela_desktop.jpg';
import candelaTransitionImgMobile from 'assets/images/reviewAndRefine/fragrance-transition-candela_mobile.jpg';
import elixirTransitionImgDesktop from 'assets/images/reviewAndRefine/fragrance-transition-elixir_desktop.jpg';
import elixirTransitionImgMobile from 'assets/images/reviewAndRefine/fragrance-transition-elixir_mobile.jpg';
import feteTransitionImgDesktop from 'assets/images/reviewAndRefine/fragrance-transition-fete_desktop.jpg';
import feteTransitionImgMobile from 'assets/images/reviewAndRefine/fragrance-transition-fete_mobile.jpg';
import fruitissimeTransitionImgDesktop from 'assets/images/reviewAndRefine/fragrance-transition-fruitissime_desktop.jpg';
import fruitissimeTransitionImgMobile from 'assets/images/reviewAndRefine/fragrance-transition-fruitissime_mobile.jpg';
import ispahanTransitionImgDesktop from 'assets/images/reviewAndRefine/fragrance-transition-ispahan_desktop.jpg';
import ispahanTransitionImgMobile from 'assets/images/reviewAndRefine/fragrance-transition-ispahan_mobile.jpg';
import napoliTransitionImgDesktop from 'assets/images/reviewAndRefine/fragrance-transition-napoli_desktop.jpg';
import napoliTransitionImgMobile from 'assets/images/reviewAndRefine/fragrance-transition-napoli_mobile.jpg';
import palmaTransitionImgDesktop from 'assets/images/reviewAndRefine/fragrance-transition-palma_desktop.png';
import palmaTransitionImgMobile from 'assets/images/reviewAndRefine/fragrance-transition-palma_mobile.png';
import perleTransitionImgDesktop from 'assets/images/reviewAndRefine/fragrance-transition-perle_desktop.jpg';
import perleTransitionImgMobile from 'assets/images/reviewAndRefine/fragrance-transition-perle_mobile.jpg';
import rituelTransitionImgDesktop from 'assets/images/reviewAndRefine/fragrance-transition-rituel_desktop.jpg';
import rituelTransitionImgMobile from 'assets/images/reviewAndRefine/fragrance-transition-rituel_mobile.jpg';
import signatureTransitionImgDesktop from 'assets/images/reviewAndRefine/fragrance-transition-signature_desktop.jpg';
import signatureTransitionImgMobile from 'assets/images/reviewAndRefine/fragrance-transition-signature_mobile.jpg';
import vitaeTransitionImgDesktop from 'assets/images/reviewAndRefine/fragrance-transition-vitae_desktop.jpg';
import vitaeTransitionImgMobile from 'assets/images/reviewAndRefine/fragrance-transition-vitae_mobile.jpg';
import arcadiaTransitionImgDesktop from 'assets/images/reviewAndRefine/prose-arcadia-swatch-desktop.jpg';
import arcadiaTransitionImgMobile from 'assets/images/reviewAndRefine/prose-arcadia-swatch-mobile.jpg';
import botanicaTransitionImgDesktop from 'assets/images/reviewAndRefine/prose-botanica-swatch-desktop.jpg';
import botanicaTransitionImgMobile from 'assets/images/reviewAndRefine/prose-botanica-swatch-mobile.jpg';
import corsicaTransitionImgDesktop from 'assets/images/reviewAndRefine/prose-corsica-swatch-desktop.jpg';
import corsicaTransitionImgMobile from 'assets/images/reviewAndRefine/prose-corsica-swatch-mobile.jpg';
import meleniTransitionImgDesktop from 'assets/images/reviewAndRefine/prose-meleni-swatch-desktop.jpg';
import meleniTransitionImgMobile from 'assets/images/reviewAndRefine/prose-meleni-swatch-mobile.jpg';
import oasisTransitionImgDesktop from 'assets/images/reviewAndRefine/prose-oasis-swatch-desktop.jpg';
import oasisTransitionImgMobile from 'assets/images/reviewAndRefine/prose-oasis-swatch-mobile.jpg';
import paradisoTransitionImgDesktop from 'assets/images/reviewAndRefine/prose-paradiso-swatch-desktop.jpg';
import paradisoTransitionImgMobile from 'assets/images/reviewAndRefine/prose-paradiso-swatch-mobile.jpg';
import preludeTransitionImgDesktop from 'assets/images/reviewAndRefine/prose-prelude-swatch-desktop.jpg';
import preludeTransitionImgMobile from 'assets/images/reviewAndRefine/prose-prelude-swatch-mobile.jpg';

const FragranceTransitionImages = {
  arcadia: { desktop: arcadiaTransitionImgDesktop, mobile: arcadiaTransitionImgMobile },
  aura: { desktop: auraTransitionImgDesktop, mobile: auraTransitionImgMobile },
  botanica: { desktop: botanicaTransitionImgDesktop, mobile: botanicaTransitionImgMobile },
  candela: { desktop: candelaTransitionImgDesktop, mobile: candelaTransitionImgMobile },
  corsica: { desktop: corsicaTransitionImgDesktop, mobile: corsicaTransitionImgMobile },
  elixir: { desktop: elixirTransitionImgDesktop, mobile: elixirTransitionImgMobile },
  fete: { desktop: feteTransitionImgDesktop, mobile: feteTransitionImgMobile },
  fruitissime: { desktop: fruitissimeTransitionImgDesktop, mobile: fruitissimeTransitionImgMobile },
  ispahan: { desktop: ispahanTransitionImgDesktop, mobile: ispahanTransitionImgMobile },
  meleni: { desktop: meleniTransitionImgDesktop, mobile: meleniTransitionImgMobile },
  napoli: { desktop: napoliTransitionImgDesktop, mobile: napoliTransitionImgMobile },
  oasis: { desktop: oasisTransitionImgDesktop, mobile: oasisTransitionImgMobile },
  palma: { desktop: palmaTransitionImgDesktop, mobile: palmaTransitionImgMobile },
  perle: { desktop: perleTransitionImgDesktop, mobile: perleTransitionImgMobile },
  prelude: { desktop: preludeTransitionImgDesktop, mobile: preludeTransitionImgMobile },
  rituel: { desktop: rituelTransitionImgDesktop, mobile: rituelTransitionImgMobile },
  signature: { desktop: signatureTransitionImgDesktop, mobile: signatureTransitionImgMobile },
  vitae: { desktop: vitaeTransitionImgDesktop, mobile: vitaeTransitionImgMobile },
  paradiso: { desktop: paradisoTransitionImgDesktop, mobile: paradisoTransitionImgMobile },
};

const fragranceTransitionScreen = {
  name: haircareTransitionFragrance,
  route: `/feedback/haircare/${haircareTransitionFragrance}`,
  category: FRAGRANCE,
  shouldBeIncluded: ({ formulas, consultationAnswers }) =>
    formulas?.some(
      f =>
        [productsSlugs.SCALP_MASK].includes(f.variant.product.type) &&
        f.item_object.smells_like !== null
    ) ||
    formulas.some(
      f =>
        [productsSlugs.OIL].includes(f.variant.product.type) && f.item_object.smells_like !== null
    ) ||
    (!consultationAnswers?.pref_fragrance_free &&
      formulas.some(f => productsWithFragrance.includes(f.variant.product.type))),
  component: 'HaircareTransitionScreen',
  skipSave: true,
  title: "Finally, let's talk about your fragrance.",
  style: ({ fragrance }) => ({
    ctaTheme: 'whiteFull',
    theme: 'white',
    backgroundImages:
      FragranceTransitionImages[fragrance.toLowerCase()] ?? FragranceTransitionImages.signature,
  }),
};

export default fragranceTransitionScreen;
