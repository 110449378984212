import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { forbidExtraProps } from 'airbnb-prop-types';

import { Navigate } from 'react-router-dom';

import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import valuesOf from 'lodash/fp/values';

import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';
import { FastField, Field } from 'formik';

import CheckoutBlock from 'Apps/Checkout/Blocks/CheckoutBlock';
import CreateCreditCard from 'Apps/Checkout/Components/CreateCreditCard';
import * as checkoutSteps from 'Apps/Checkout/constants/checkoutSteps';
import useCheckoutCategory from 'Apps/Checkout/hooks/useCheckoutCategory';

import AddressRadio from 'Components/AddressRadio';
import Spacer from 'Components/Spacer';
import Typography from 'Components/Typography';

import { APPLE_PAY, CREDIT_CARD, GOOGLE_PAY } from 'constants/paymentMethods';

import * as saveCreditCardLegalContent from 'assets/content/legals/saveCreditCard';
import paymentMethodsImg from 'assets/images/icon_card.svg';
import { ReactComponent as NoIcon } from 'assets/images/no-icon.svg';
import { ReactComponent as YesIcon } from 'assets/images/yes-icon.svg';

import addOriginParam from 'utils/addOriginParam';

import useCheckoutPaymentInfosByCartType from '../hooks/useCheckoutPaymentInfosByCartType';

const Image = styled.img`
  align-self: flex-start;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 398px;
  margin-top: ${legacyTheme.spacing.s16};

  & label {
    display: flex;
    align-items: center;
  }
`;

const ToggleText = styled(Typography)`
  margin-left: ${legacyTheme.spacing.s16};
`;

const CheckoutPayment = ({ cardPubkey, params, saveCard, step }) => {
  const checkoutCategory = useCheckoutCategory();
  const { isFree, card, cartHasSubscription, isCartV2, cards } = useCheckoutPaymentInfosByCartType({
    cardPubkey,
  });

  // The origin param should be preserved when redirecting to avoid cart refresh on page reload
  const appendOrigin = addOriginParam(params);

  // Fix existing bug when refreshing cart with free order
  if (isFree && step === checkoutSteps.PAYMENT) {
    return (
      <Navigate
        replace
        to={appendOrigin(`/checkout/${checkoutCategory}/${checkoutSteps.SHIPPING_ADDRESS}`)}
      />
    );
  }

  if (isFree) {
    return null;
  }

  const isCurrentStep = step === checkoutSteps.PAYMENT;

  // Payment checkout mode
  return (
    <CheckoutBlock dataTestId="payment-block" id="checkout-payment-block" title="Payment Method">
      {!isCartV2 && cartHasSubscription && isCurrentStep && (
        <>
          <Typography align="left" color="noirDark" variant="p2">
            We’ll use this payment for all of your subscription orders.
          </Typography>
          <Spacer size={18} />
        </>
      )}

      {!isCurrentStep && (
        <>
          {card ? (
            <>
              <Typography align="left" color="noirDark" variant="p2">
                {card.brand}
              </Typography>
              <Typography align="left" color="noirDark" variant="p2">
                {card?.name}
              </Typography>
              <Typography align="left" color="noirDark" variant="p2">
                **** **** **** {card.last4}
              </Typography>
              <Typography align="left" color="noirDark" variant="p2">
                {card.exp_month}/{card.exp_year}
              </Typography>
            </>
          ) : (
            // Payment methods image
            <Image
              alt={`${CREDIT_CARD}, ${GOOGLE_PAY}, ${APPLE_PAY} - Payment method icon - vector`}
              src={paymentMethodsImg}
            />
          )}

          {cardPubkey === 'newCard' && (
            <FastField name="saveCard">
              {({ field }) => (
                <ToggleContainer>
                  {field.value ? (
                    <YesIcon stroke={theme.colors.primary[400]} />
                  ) : (
                    <NoIcon stroke={theme.colors.primary[400]} />
                  )}
                  <ToggleText color="grey" variant="p3">
                    {field.value
                      ? saveCreditCardLegalContent.LABEL
                      : saveCreditCardLegalContent.LABEL_OFF}
                  </ToggleText>
                </ToggleContainer>
              )}
            </FastField>
          )}
        </>
      )}

      <div
        style={{
          // Display none instead of removing from DOM so we can show it again server error after confirmation step
          display: isCurrentStep ? 'block' : 'none',
        }}
      >
        <Field name="cardPubkey">
          {({ field: { value }, form: { setFieldValue } }) =>
            !isEmpty(cards) && (
              <>
                {map(registeredCard => (
                  <Fragment key={registeredCard.id}>
                    <AddressRadio
                      aria-label="Use Existing card"
                      onClick={() => setFieldValue('cardPubkey', registeredCard.id)}
                      selected={value === registeredCard.id}
                    >
                      <Typography align="left" color="noirDark" variant="p3">
                        <strong>
                          {registeredCard.brand || registeredCard.object}
                          {registeredCard.is_subscription && ` - subscription card`}
                        </strong>
                        <br />
                        {registeredCard.name || <>&nbsp;</>}
                        <br />
                        {registeredCard.last4 && (
                          <>
                            {registeredCard.brand || registeredCard.object} ending in{' '}
                            {registeredCard.last4}
                          </>
                        )}
                        <br />
                        Exp. {registeredCard.exp_month}/{registeredCard.exp_year}
                        <br />
                      </Typography>
                    </AddressRadio>
                    <Spacer size={20} />
                  </Fragment>
                ))(cards)}

                <AddressRadio
                  aria-label="Add a new payment method"
                  color="noirDark"
                  onClick={() => setFieldValue('cardPubkey', 'newCard')}
                  selected={value === 'newCard'}
                >
                  <Typography align="left" variant="p3">
                    New payment method
                  </Typography>
                </AddressRadio>
              </>
            )
          }
        </Field>

        <CreateCreditCard
          cardPubkey={cardPubkey}
          saveCard={saveCard}
          showExpressPayment={step === checkoutSteps.PAYMENT}
        />
      </div>
    </CheckoutBlock>
  );
};

CheckoutPayment.propTypes = forbidExtraProps({
  cardPubkey: PropTypes.string.isRequired,
  params: PropTypes.shape({
    has: PropTypes.func.isRequired,
    get: PropTypes.func.isRequired,
  }).isRequired,
  saveCard: PropTypes.bool.isRequired,
  step: PropTypes.oneOf(valuesOf(checkoutSteps)).isRequired,
});

export default CheckoutPayment;
