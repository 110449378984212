import * as APIClient from './APIClient';

export const fetch = async pubkey => {
  const response = await APIClient.get(`/v1/gifts/${pubkey}`);
  return response.json();
};

export const post = async gift => {
  const response = await APIClient.post('/v1/gifts/', gift);
  return response.json();
};
