import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import isEmpty from 'lodash/isEmpty';

import { legacyTheme } from '@prose-ui/legacy';
import { useGoToCheckoutV2 } from 'hooks/useGoToCheckout';
import { makeStyles, styled } from 'legacyStyles';

import Spacer from 'Components/Spacer';
import { CartAccessories } from '../Components/CartAccessories';
import UpsaleSectionCartV2 from '../Components/UpsaleSectionCartV2';

import { SUCCESS } from 'constants/statuses';

import CheckoutTicketV2 from '../Blocks/CheckoutTicketV2';

import LoadingScene from 'Scenes/LoadingScene';
import BaseScene from '../Scenes/BaseScene';

import {
  getCartV2Currency,
  getCartV2Status,
  getCartV2UpdatingStatus,
  getIsDisabledV2,
  getUpsaleSectionRecommendationV2,
} from 'dux/cartV2/selectors';
import {
  useTrackCartV2OnAddFromRecommendedSection,
  useTrackingCartVisited,
} from 'dux/cartV2/hooks';
import { getCartRecommendationV2, updateCartV2ItemsQuantity } from 'dux/cartV2/thunks';
import { fetchProducts } from 'dux/products/thunks';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '60% 40%',
    alignItems: 'stretch',
    [theme.breakpoints.match('sm')]: {
      display: 'block',
    },
  },
}));

const Container = styled.div`
  padding: 56px 20px;
  ${props => props.theme.breakpoints.up('md')} {
    padding: 90px 0px 0px 90px;
  }
`;

const TicketContainer = styled.div`
  ${legacyTheme.breakpoints.up('sm')} {
    padding-top: 90px;
  }
`;

const CartAccessoriesContainer = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const status = useAppSelector(getCartV2Status);
  const currency = useAppSelector(getCartV2Currency);
  const upsaleProducts = useAppSelector(getUpsaleSectionRecommendationV2);
  const isUpdating = useAppSelector(getCartV2UpdatingStatus);
  const disabled = useAppSelector(getIsDisabledV2);

  const { goToNextCheckoutStep } = useGoToCheckoutV2();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { onAdd: onAddTracking } = useTrackCartV2OnAddFromRecommendedSection();

  useTrackingCartVisited();

  useEffect(() => {
    dispatch(fetchProducts());
    if (status === SUCCESS) {
      dispatch(getCartRecommendationV2());
    }
  }, [status]);

  if (status !== SUCCESS) {
    return <LoadingScene />;
  }

  const onAddProduct = async product => {
    await onAddTracking(product.product_slug || product.slug);
    await dispatch(
      updateCartV2ItemsQuantity({
        quantity: 1,
        variant_slug: product.product_slug || product.slug,
      })
    );
    // wait for patch response from cart to get the new reco from algo
    dispatch(getCartRecommendationV2());
  };

  return (
    <BaseScene
      classes={{ content: classes.root }}
      footer
      FooterProps={{
        hideCTA: true,
        paddedOnRightOnDesktop: true,
      }}
    >
      <Container>
        <CartAccessories />
        <Spacer size={20} />
        {/* Hide the section if no products in it */}
        {!isEmpty(upsaleProducts) && (
          <UpsaleSectionCartV2
            currency={currency}
            isUpdating={isUpdating}
            list={upsaleProducts}
            onAddProduct={onAddProduct}
          />
        )}
      </Container>
      <TicketContainer>
        <CheckoutTicketV2
          ctaButtonProps={{
            disabled,
            isLoading: isUpdating,
            text: 'checkout',
            onClick: goToNextCheckoutStep,
            variant: 'vert',
          }}
        />
      </TicketContainer>
    </BaseScene>
  );
};

export default CartAccessoriesContainer;
