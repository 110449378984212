import { Link } from 'react-router-dom';

import { legacyTheme, styled } from '@prose-ui/legacy';

import useCheckoutCategory from 'Apps/Checkout/hooks/useCheckoutCategory';
import useConnectedConfirmationButtonInfosByCartType from 'Apps/Checkout/hooks/useConnectedConfirmationButtonInfosByCartType';

import SubscriptionTicketLegalWording from 'Components/SubscriptionTicketLegalWording';
import Typography from 'Components/Typography';

const SatisfactionGuaranteeText = styled(Typography)`
  margin: 18px 0;

  ${legacyTheme.breakpoints.up('sm')} {
    margin: 20px 0;
  }
`;

const ConnectedConfirmationButton = () => {
  const checkoutCategory = useCheckoutCategory();
  const { cartHasSubscription, isCartV2 } = useConnectedConfirmationButtonInfosByCartType();

  return (
    <>
      <SatisfactionGuaranteeText align="center" color="grey" variant="mono3">
        LOVE YOUR FIRST ORDER (OR IT&apos;S ON US)
      </SatisfactionGuaranteeText>

      <Typography color="grey" paragraph variant="p3">
        By placing your order, you agree to Prose’s{' '}
        <Link target="_blank" to="/terms">
          End User License and Terms of Service
        </Link>{' '}
        and{' '}
        <Link target="_blank" to="/privacy">
          Privacy Policy
        </Link>
        .
      </Typography>

      {!isCartV2 && cartHasSubscription && (
        <SubscriptionTicketLegalWording
          align="left"
          category={checkoutCategory}
          ctaWording="PLACE MY ORDER"
          paragraph
        />
      )}
    </>
  );
};

export default ConnectedConfirmationButton;
