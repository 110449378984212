import { useAppSelector } from 'dux/app/hooks';

import { productsCategories } from 'constants/products';

import {
  getCartTotalPriceV2,
  getCartTotalShippingV2,
  getCartV2Currency,
  getItemQuantityInCartV2,
  getPaymentFromCustomerV2,
} from 'dux/cartV2/selectors';
import * as checkoutCartSelectors from 'dux/checkoutCart/selectors';
import * as userSelectors from 'dux/user/selectors';

import useCheckoutCategory from './useCheckoutCategory';

const useCheckoutSummaryHeaderInfosByCartType = () => {
  const checkoutCategory = useCheckoutCategory();

  // Cart V1
  const cartHasSubscription = useAppSelector(checkoutCartSelectors.cartHasSubscription);
  const hasItemsFromGiftSet = useAppSelector(checkoutCartSelectors.getHasItemsFromGiftSet);
  const currency = useAppSelector(checkoutCartSelectors.getCurrencyFromCart);
  const paymentFromCustomer = useAppSelector(checkoutCartSelectors.getPaymentFromCustomer);
  const cartTotalPrice = useAppSelector(checkoutCartSelectors.getCartTotalPrice);
  const cartTotalShipping = useAppSelector(checkoutCartSelectors.getCartTotalShipping);
  const cartTotalAmount = useAppSelector(checkoutCartSelectors.getCartTotalAmount);
  const freeOrder = useAppSelector(userSelectors.getIsOrderFree);
  const offerCoupons = useAppSelector(checkoutCartSelectors.getCartOfferCoupons);
  const creditCoupons = useAppSelector(checkoutCartSelectors.getCartCreditCoupons);
  const subscriptionCoupons = useAppSelector(checkoutCartSelectors.getCartSubscriptionCoupons);
  const productsInCartQuantity = useAppSelector(checkoutCartSelectors.getCartProductsQuantity);

  // Cart V2
  const currencyV2 = useAppSelector(getCartV2Currency);
  const cartPaymentFromCustomerV2 = useAppSelector(getPaymentFromCustomerV2);
  const cartTotalPriceV2 = useAppSelector(getCartTotalPriceV2);
  const cartTotalShippingV2 = useAppSelector(getCartTotalShippingV2);
  const productsInCartQuantityV2 = useAppSelector(getItemQuantityInCartV2);

  if (checkoutCategory === productsCategories.ACCESSORIES) {
    return {
      currency: currencyV2,
      cartPaymentFromCustomer: cartPaymentFromCustomerV2,
      cartTotalPrice: cartTotalPriceV2,
      cartTotalShipping: cartTotalShippingV2,
      productsInCartQuantity: productsInCartQuantityV2,
      freeOrder,
    };
  }

  return {
    cartHasSubscription,
    hasItemsFromGiftSet,
    currency,
    cartPaymentFromCustomer: paymentFromCustomer,
    cartTotalPrice,
    cartTotalShipping,
    cartTotalAmount,
    freeOrder,
    offerCoupons,
    creditCoupons,
    subscriptionCoupons,
    productsInCartQuantity,
  };
};

export default useCheckoutSummaryHeaderInfosByCartType;
