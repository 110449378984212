import { useAppSelector as useSelector } from 'dux/app/hooks';

import * as ctaContent from 'assets/content/consultation/cta';

import { getIsAuthenticating } from 'dux/auth/selectors';
import { shouldShowUnlockAccessories } from 'dux/featureFlags/selectors';
import {
  getCanSeeFeedback,
  getHasCompletedAllConsultations,
  getHasCompletedHaircareConsultation,
  getHasCompletedSkincareConsultation,
} from 'dux/user/selectors';

const getRenderParams = ({
  productName,
  canSeeFeedback,
  hasCompleteConsultations,
  hasCompleteHaircareConsultation,
  hasCompleteSkincareConsultation,
  showUnlockAccessories,
}) => {
  // Order delivered or has all consultations complete
  if (canSeeFeedback || hasCompleteConsultations) {
    return {
      to: '/account/reorder',
      label: ctaContent.reorder,
      dataClick: 'order-prose',
      showShoppingCart: true,
    };
  }

  // Has complete haircare consultation only
  if (hasCompleteHaircareConsultation && !hasCompleteSkincareConsultation) {
    return {
      to: '/checkout/haircare',
      label: ctaContent.abandonedCart(productName),
      dataClick: 'order-prose',
      showShoppingCart: true,
    };
  }

  // Has complete skincare consultation only
  if (hasCompleteSkincareConsultation && !hasCompleteHaircareConsultation) {
    return {
      to: '/checkout/skincare',
      label: ctaContent.abandonedCart(productName),
      dataClick: 'order-prose',
      showShoppingCart: true,
    };
  }

  return {
    showShoppingCart: showUnlockAccessories,
    to: showUnlockAccessories ? '/checkout/accessories' : null,
  };
};

function useMapSelectorsToState() {
  const isAuthenticating = useSelector(getIsAuthenticating);
  const canSeeFeedback = useSelector(getCanSeeFeedback);
  const hasCompleteConsultations = useSelector(getHasCompletedAllConsultations);
  const showUnlockAccessories = useSelector(shouldShowUnlockAccessories);
  const hasCompleteHaircareConsultation = useSelector(getHasCompletedHaircareConsultation);
  const hasCompleteSkincareConsultation = useSelector(getHasCompletedSkincareConsultation);

  return {
    isAuthenticating,
    canSeeFeedback,
    hasCompleteConsultations,
    hasCompleteHaircareConsultation,
    hasCompleteSkincareConsultation,
    showUnlockAccessories,
  };
}

export default function useCTA(
  { productName = null } = {},
  { useGlobalValues = useMapSelectorsToState } = {}
) {
  const {
    isAuthenticating,
    canSeeFeedback,
    hasCompleteConsultations,
    hasCompleteHaircareConsultation,
    hasCompleteSkincareConsultation,
    showUnlockAccessories,
  } = useGlobalValues();

  if (isAuthenticating) {
    return {
      to: '',
      label: '',
      dataClick: '',
      showShoppingCart: false,
    };
  }

  return getRenderParams({
    productName,
    canSeeFeedback,
    hasCompleteConsultations,
    hasCompleteHaircareConsultation,
    hasCompleteSkincareConsultation,
    showUnlockAccessories,
  });
}
