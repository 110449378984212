import { useState } from 'react';
import PropTypes from 'prop-types';

import { theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';

import CartOrderSummary from 'Apps/Checkout/Blocks/CartOrderSummary';

import CheckoutSummaryHeader from './OrderSummarHeader';

const Container = styled.div`
  background-color: ${theme.colors.neutral[100]};
`;

const Drawer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 30px;

  background-color: ${theme.colors.neutral[100]};
  color: ${theme.colors.primary[400]};
`;

const TicketBorder = styled.div`
  border: none;
  border-top: 1px solid ${theme.colors.neutral[600]};
`;

const Accordion = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease-out;

  grid-template-rows: ${({ isOpen }) => isOpen && '1fr'};
`;

const ChildrenContainer = styled.div`
  overflow: hidden;
  padding: 0 30px;
`;

const CheckoutSummaryAccordion = ({ wantsPumps }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container>
      <Drawer
        data-click="order-summary-open"
        data-from="cart"
        data-testid="checkout-summary-accordion"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <CheckoutSummaryHeader chevronDirection={isOpen ? 'up' : 'down'} isCheckout />
      </Drawer>

      <Accordion isOpen={isOpen}>
        <ChildrenContainer>
          <TicketBorder />
          <CartOrderSummary isCheckout wantsPumps={wantsPumps} />
        </ChildrenContainer>
      </Accordion>
    </Container>
  );
};

CheckoutSummaryAccordion.propTypes = {
  wantsPumps: PropTypes.bool,
};

CheckoutSummaryAccordion.defaultProps = {
  wantsPumps: true,
};

export default CheckoutSummaryAccordion;
