import { Link } from 'react-router-dom';

import { theme } from '@prose-ui';
import { css, legacyTheme, styled } from '@prose-ui/legacy';
import { mediaMinWidth } from '@prose-ui/utils/media';
import Image from 'next/image';

import { Sustainability } from 'Apps/Main/Scenes/HomeV2/Sustainability';

import Typography from 'Components/Typography';

import ConsultationCTA from 'Containers/ConsultationCTA';

import company from 'assets/content/company';
import content from 'assets/content/footer';

// Properties shared bewteen <a> and <Link> tags
const LinkStyles = css`
  margin: ${legacyTheme.spacing.s8} 0;

  font-size: 14px;
  line-height: 1.5em;
  letter-spacing: 0.5px;
`;

const StyledFooter = styled.footer<{ paddedOnRightOnDesktop: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  background: ${theme.colors.neutral['900']};
  box-shadow: 0 10px 14px -10px rgba(0, 0, 0, 0.04);

  color: ${legacyTheme.palette.common.white.light};

  ${legacyTheme.breakpoints.up('md')} {
    flex-direction: row;
  }

  ${({ paddedOnRightOnDesktop }) =>
    `${
      paddedOnRightOnDesktop &&
      `
      padding-right: 0;

      ${legacyTheme.breakpoints.up('md')} {
        padding-right: 450px;
      }
    `
    }`}
`;

const CTA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  order: 1;
  min-height: 224px;

  background: ${theme.colors.primary['400']};

  color: ${legacyTheme.palette.common.white.light};

  ${legacyTheme.breakpoints.up('md')} {
    order: 2;
  }
`;

const Grid = styled.div<{ shouldDisplaySustainabilityComponent: boolean }>`
  display: grid;
  order: 2;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'contactUs            careers'
    'gift                 blog'
    'faq                  stylists'
    'privacy              partner'
    'healthPrivacyNotice  terms'
    'privacyChoices       accessibility'
    'border               border'
    'bCorp                bCorp'
    'social               social'
    'copywright           copywright'
    'sitemap              sitemap'
    'donotsell            donotsell'
    'supplements          supplements';
  flex: 1 1 0%;
  justify-content: space-around;
  place-items: flex-start start;
  justify-items: center;
  padding: ${legacyTheme.spacing.s80} ${legacyTheme.spacing.s24} 62px;

  text-align: center;

  & a {
    margin-bottom: 30px;
  }

  ${legacyTheme.breakpoints.up('sm')} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      'contactUs      faq                 gift'
      'blog           privacy             stylists'
      'partner        healthPrivacyNotice terms'
      'careers        privacyChoices      accessibility'
      'border         border              border'
      'bCorp          bCorp               bCorp'
      'social         social              social'
      'copywright     copywright          copywright'
      'sitemap        sitemap             sitemap'
      'donotsell      donotsell           donotsell'
      'supplements    supplements         supplements';
    padding: ${legacyTheme.spacing.s48} ${legacyTheme.props.verticalSpacingMob} 70px;
  }

  ${legacyTheme.breakpoints.up('md')} {
    grid-template-areas:
      'contactUs      gift        privacy             bCorp'
      'careers        stylists    healthPrivacyNotice bCorp'
      'blog           partner     privacyChoices      bCorp'
      'faq            terms       accessibility       bCorp'
      'social         social      social              bCorp'
      'copywright     copywright  supplements         supplements'
      'sitemap        sitemap     supplements         supplements'
      'donotsell      donotsell   supplements         supplements';
    grid-template-columns: none;
    order: 1;
    flex: 2 1 0%;
    justify-items: start;
    padding: ${({ shouldDisplaySustainabilityComponent }) =>
      `${
        shouldDisplaySustainabilityComponent ? '80px' : legacyTheme.props.verticalSpacingDes
      } 0px ${legacyTheme.props.verticalSpacingDes} 50px`};

    text-align: left;

    & a {
      margin-bottom: ${legacyTheme.spacing.s8};
    }

    & a:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const Nav = styled.nav`
  display: contents;
`;

const StyledLink = styled(Link)`
  ${LinkStyles}

  margin: ${legacyTheme.spacing.s8};

  color: ${legacyTheme.palette.common.white.light};

  ${legacyTheme.breakpoints.up('md')} {
    margin: ${legacyTheme.spacing.s8} 0;
  }
`;

const ContactUsLink = styled(StyledLink)`
  grid-area: contactUs;
`;

const CareersLink = styled(StyledLink)`
  grid-area: careers;
`;

const GiftLink = styled(StyledLink)`
  grid-area: gift;
`;

const BlogLink = styled.a`
  ${LinkStyles}

  grid-area: blog;
  margin: ${legacyTheme.spacing.s8};

  color: ${legacyTheme.palette.common.white.light};

  ${legacyTheme.breakpoints.up('md')} {
    margin: ${legacyTheme.spacing.s8} 0;
  }
`;

const FaqLink = styled(StyledLink)`
  grid-area: faq;
`;

const Social = styled.div`
  grid-area: social;
`;

const TermsLink = styled(StyledLink)`
  grid-area: terms;
`;

const Privacy = styled.div`
  grid-area: privacy;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${legacyTheme.breakpoints.up('md')} {
    align-items: start;
  }

  & a {
    margin-bottom: 0;
  }
`;

const HealthPrivacyNotice = styled(StyledLink)`
  grid-area: healthPrivacyNotice;
`;

const PrivacyChoices = styled(StyledLink)`
  grid-area: privacyChoices;
`;

const PartnerProgramLink = styled.a`
  ${LinkStyles};

  grid-area: partner;
  margin: ${legacyTheme.spacing.s8};

  color: ${legacyTheme.palette.common.white.light};

  ${legacyTheme.breakpoints.up('md')} {
    margin: ${legacyTheme.spacing.s8} 0;
  }
`;

const StylistsLink = styled(StyledLink)`
  grid-area: stylists;
`;

const AccessibilityLink = styled(StyledLink)`
  grid-area: accessibility;
`;

const Border = styled.div`
  display: block;
  grid-area: border;
  width: 100%;
  margin: ${legacyTheme.spacing.s16} 0;

  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.15);

  ${legacyTheme.breakpoints.up('md')} {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  grid-area: bCorp;
  margin: ${legacyTheme.spacing.s24};

  ${legacyTheme.breakpoints.up('md')} {
    margin: ${legacyTheme.spacing.s24} 0;
  }
`;

const Copyright = styled(Typography)`
  grid-area: copywright;
  margin-bottom: ${legacyTheme.spacing.s8};

  color: ${legacyTheme.palette.common.grey.medium};
`;

const Sitemap = styled(Typography)`
  grid-area: sitemap;
  margin-bottom: ${legacyTheme.spacing.s8};

  color: ${legacyTheme.palette.common.grey.medium};
`;

const Donotsell = styled.a`
  ${LinkStyles};

  grid-area: donotsell;

  color: ${legacyTheme.palette.common.grey.medium};

  ${legacyTheme.breakpoints.up('sm')} {
    margin-bottom: ${legacyTheme.spacing.s36};
  }

  ${legacyTheme.breakpoints.up('md')} {
    margin-bottom: 0;
  }
`;

const SocialImg = styled(Image)`
  margin: ${legacyTheme.spacing.s16};

  ${legacyTheme.breakpoints.up('md')} {
    margin: ${legacyTheme.spacing.s72} ${legacyTheme.spacing.s32} ${legacyTheme.spacing.s16} 0px;
  }
`;

const Supplements = styled(Typography)`
  grid-area: supplements;
  max-width: 344px;
  margin: 0 ${legacyTheme.spacing.s16} ${legacyTheme.spacing.s40} ${legacyTheme.spacing.s16};
  padding: 20px 14px;

  border: 1px solid ${legacyTheme.palette.common.noir.medium};

  text-align: center;
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-size: 12px;
  color: ${legacyTheme.palette.common.white.light};

  ${legacyTheme.breakpoints.up('sm')} {
    margin: 0 34px ${legacyTheme.spacing.s40} 34px;
  }

  ${legacyTheme.breakpoints.up('md')} {
    max-width: 400px;
    margin-bottom: 0;
    padding: 15px 20px;

    text-align: left;
  }
`;

const Logo = styled(Image)`
  margin: 0px 15px;
`;

const SustainabilityPadding = styled.div`
  position: relative;

  padding: 0px ${theme.spacing['4x']};

  ${mediaMinWidth('lg')} {
    padding: 0px ${theme.spacing['8x']};
  }
`;

const SustainabilityContainer = styled.div`
  position: relative;
`;

const SustainabilityBackground1 = styled.div`
  position: absolute;
  top: 0;

  width: 100%;
  height: 50%;
`;

const SustainabilityBackground2 = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  height: 50%;

  background-color: ${legacyTheme.palette.common.noir.dark};
`;

const currentYear = new Date().getFullYear();

type FooterProps = {
  hideCTA: boolean;
  paddedOnRightOnDesktop: boolean;
  shouldDisplaySustainabilityComponent: boolean;
};

export const Footer = ({
  hideCTA = false,
  paddedOnRightOnDesktop = false,
  shouldDisplaySustainabilityComponent = false,
}: FooterProps) => (
  <>
    {shouldDisplaySustainabilityComponent && (
      <SustainabilityContainer>
        <SustainabilityBackground1 />
        <SustainabilityBackground2 />
        <SustainabilityPadding>
          <Sustainability />
        </SustainabilityPadding>
      </SustainabilityContainer>
    )}

    <StyledFooter data-testid="footer" id="footer" paddedOnRightOnDesktop={paddedOnRightOnDesktop}>
      {!hideCTA && (
        <CTA data-testid="footerCta">
          {/* @ts-expect-error - Typography has no TS types yet */}
          <Typography align="center" markupName="h2" paragraph variant="h3">
            {content.message}
          </Typography>
          <ConsultationCTA dataFrom="footer" theme="whiteBg" />
        </CTA>
      )}

      <Grid shouldDisplaySustainabilityComponent={shouldDisplaySustainabilityComponent}>
        <Nav>
          <ContactUsLink data-click="contact-us" data-from="footer" to="/contact">
            Contact us
          </ContactUsLink>
          <CareersLink data-click="careers" data-from="footer" to="/careers">
            Careers
          </CareersLink>
          <GiftLink data-click="gift" data-from="footer" to="/gift">
            Gift Prose
          </GiftLink>
          <BlogLink data-click="blog" data-from="footer" href="/blog/">
            Blog
          </BlogLink>
          <FaqLink data-click="faq" data-from="footer" to="/faq">
            Help + FAQ
          </FaqLink>
          <StylistsLink
            data-click="for-stylists"
            data-from="footer"
            to="/pages/stylist-content-call"
          >
            For stylists
          </StylistsLink>
          <TermsLink data-click="terms" data-from="footer" to="/terms">
            Terms of use
          </TermsLink>
          <Privacy>
            <StyledLink data-click="privacy" data-from="footer" to="/privacy">
              Privacy policy
            </StyledLink>
            {/* @ts-expect-error - Typography has no TS types yet */}
            <Typography color="sorbet" variant="mono4">
              updated
            </Typography>
          </Privacy>
          <HealthPrivacyNotice
            data-click="health-privacy-notice"
            data-from="footer"
            to="/health-privacy-notice"
          >
            Health Privacy Notice
          </HealthPrivacyNotice>
          <PrivacyChoices
            data-click="privacy-choices"
            data-from="footer"
            onClick={() => {
              // @ts-ignore - TODO: type window
              window.Fides.showModal();
            }}
            to="#"
          >
            Your Privacy Choices
          </PrivacyChoices>
          <PartnerProgramLink
            data-click="partner-program"
            data-from="footer"
            href="https://app.impact.com/campaign-campaign-info-v2/Prose.brand "
            rel="noopener noreferrer"
            target="_blank"
          >
            Partner program
          </PartnerProgramLink>
          <AccessibilityLink data-click="accessibility" data-from="footer" to="/accessibility">
            Accessibility
          </AccessibilityLink>
        </Nav>
        <Social>
          {company.social.map((social) => (
            <a
              key={social.name}
              data-click={social.name}
              data-from="footer"
              href={social.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <SocialImg alt={`${social.name} icon`} height={20} src={social.img} width={20} />
            </a>
          ))}
        </Social>
        <Border />
        {!shouldDisplaySustainabilityComponent && (
          <LogoWrapper>
            <Link data-click="bcorp" data-from="footer" to={content.bCorp.link}>
              <Logo
                alt={`${content.bCorp.alt} logo`}
                height={115}
                src={content.bCorp.image}
                width={82}
              />
            </Link>
            <Logo
              alt={`${content.climateNeutral.alt} logo`}
              height={115}
              src={content.climateNeutral.image}
              width={82}
            />
          </LogoWrapper>
        )}
        {/* @ts-expect-error - Typography has no TS types yet */}
        <Copyright variant="mono3">
          &copy; {currentYear} {company.legalName}
        </Copyright>
        {/* @ts-expect-error - Typography has no TS types yet */}
        <Sitemap
          data-click="sitemap"
          data-from="footer"
          markupName={Link}
          to="/sitemap"
          variant="mono3"
        >
          Site map
        </Sitemap>
        <Donotsell href={content.doNotSell.link}>
          {/* @ts-expect-error - Typography has no TS types yet */}
          <Typography variant="mono3">{content.doNotSell.copy}</Typography>
        </Donotsell>{' '}
        {/* @ts-expect-error - Typography has no TS types yet */}
        <Supplements markupName="p" variant="p3">
          *These statements have not been evaluated by the Food and Drug Administration. This
          product is not intended to diagnose, treat, cure or prevent any disease.
        </Supplements>
      </Grid>
    </StyledFooter>
  </>
);
