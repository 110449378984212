import { Link } from 'react-router-dom';

import { styled } from '@prose-ui/legacy';

const A11YLinks = styled.ul`
  margin: 0;
  padding: 0;

  list-style: none;

  a {
    position: absolute;

    display: block;
    width: 0;
    height: 0;

    text-indent: -1000000px;

    &:focus {
      top: 0;
      z-index: 1101;

      width: auto;
      height: auto;
      padding: 0 8px;

      background-color: white;

      line-height: 32px;
      text-indent: 0;
      color: black;
    }
  }
`;

type SkipLinkA11yProps = {
  showFooterLink: boolean;
};

export const SkipLinkA11y = ({ showFooterLink = false }: SkipLinkA11yProps) => (
  <A11YLinks>
    <li>
      <a href="#maincontent">Skip to main content</a>
    </li>
    {showFooterLink && (
      <li>
        <a href="#footer">Skip to footer</a>
      </li>
    )}
    <li>
      <Link to="/accessibility">Accessibility Page</Link>
    </li>
  </A11YLinks>
);
