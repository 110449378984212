import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { Link, useLocation } from 'react-router-dom';

import { useAppSelector } from 'dux/app/hooks';

import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';
import { animated, useSpring } from '@react-spring/web';
import useCTADI from 'hooks/useCTA';
import Image from 'next/image';

import BagIcon from 'Components/BagIcon';
import Button from 'Components/LegacyButton';
import { breakpoints } from 'Components/ThemeProvider';
import Typography from 'Components/Typography';

import ConsultationCTA from 'Containers/ConsultationCTA';

import { navbarContent as content } from 'assets/content/navbar';

import useHeight from 'utils/useHeight';
import useIsMounted from 'utils/useIsMounted';
import useMedia from 'utils/useMedia';

import { getItemQuantityInCartV2 } from 'dux/cartV2/selectors';
import * as featureFlagSelectors from 'dux/featureFlags/selectors';
import * as userSelectors from 'dux/user/selectors';

const isDesktopThreshold = breakpoints.md + 200;

const Root = styled.nav`
  display: block;
  width: 100%;
  height: ${legacyTheme.props.navbarHeight};

  background-color: ${theme.colors.neutral[100]};
  box-shadow: ${legacyTheme.shadows.navBar};

  transition: height 0.56s cubic-bezier(0.52, 0.16, 0.24, 1);

  &.menuOpen {
    height: 100vh;
  }

  &.hideOverflow {
    overflow: hidden;
  }
`;

const NavHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${legacyTheme.props.navbarHeight};
  padding: 0 20px;

  ${legacyTheme.breakpoints.up(isDesktopThreshold)} {
    padding: 0 ${legacyTheme.spacing.s32};
  }
`;

const NavContent = styled.div`
  flex: 1 1 50%;
`;

const NavContentRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${legacyTheme.spacing.s32};

  ${legacyTheme.breakpoints.down(isDesktopThreshold)} {
    flex: 1 1 50%;
    gap: ${legacyTheme.spacing.s24};
  }
`;

const MobileMenuRoot = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: calc(100vh - ${legacyTheme.props.navbarHeight});
  /**
   * high bottom padding to avoid overlap with iOS native navigation
   * XXX: to be reconsidered once new css units are a thing we can use
   */
  padding-block: ${legacyTheme.spacing.s24} 114px;

  background-color: ${theme.colors.neutral[100]};
`;

const DesktopNavBarWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: ${legacyTheme.spacing.s32};
  padding-left: ${legacyTheme.spacing.s32};
`;

const MenuFooter = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
  padding: 0 ${legacyTheme.spacing.s24};

  & > * {
    flex-grow: 1;
    min-width: fit-content;
  }
`;

const linkTpl = `
  flex-shrink: 0;

  cursor: pointer;

  &:hover {
    color: ${theme.colors.primary[400]};
    text-decoration: underline;
  }

  &.align-left {
    width: fit-content;
    margin-right: auto;
  }
`;

const NavLink = styled(Typography)`
  ${linkTpl}

  position: relative;

  display: flex;
`;

const LinkContainer = styled(Link)`
  ${linkTpl}

  display: flex;
`;

const MenuList = styled.ul`
  overflow-y: scroll;

  height: 100%;
  margin: 0;
  padding: 0;
`;

const MobileMenuListItem = styled.li`
  list-style: none;
`;

const MobileMenuListAnchor = styled(Typography)`
  padding: 6px ${legacyTheme.spacing.s24};
`;

const MenuSeparator = styled(MobileMenuListItem)`
  width: ${legacyTheme.spacing.s36};
  margin: ${legacyTheme.spacing.s16} ${legacyTheme.spacing.s24};

  border-bottom: 1px solid ${theme.colors.neutral[400]};
`;

const MenuMessage = styled(Typography)`
  padding: 0 ${legacyTheme.spacing.s24};
`;

const SubNavContainerDesktop = styled.div`
  position: relative;

  flex-shrink: 0;

  & > a {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  /* handling all hover states, and focus */
  /* also handling focus with tab for accessibility */
  &:hover > div,
  &:focus-within > div,
  & div:hover,
  & div:focus {
    display: flex;

    opacity: 1;
  }

  /* displaying a CSS-made arrow */
  & > a::after {
    width: ${legacyTheme.spacing.s8};
    height: ${legacyTheme.spacing.s8};
    margin-left: ${legacyTheme.spacing.s16};
    margin-top: 4px;

    border-top: 1.5px solid black;
    border-right: 1.5px solid black;
    transform: translate3d(-50%, -75%, 0px) rotate(135deg);

    content: '';
  }

  &:hover > a::after {
    transform: translate3d(-50%, -25%, 0px) rotate(-45deg);
  }
`;

const SubNavBoxDesktop = styled.div`
  position: absolute;
  left: 0;

  display: none;
  flex-direction: column;
  width: 240px;
  gap: ${legacyTheme.spacing.s16};
  padding: 35px ${legacyTheme.spacing.s16} 18px ${legacyTheme.spacing.s16};

  background-color: ${theme.colors.neutral[100]};
  transition: all 0.5s ease;

  opacity: 0;
`;

const SubNavContainerMobile = styled.div<{ isOpen: string }>`
  padding: 4px ${legacyTheme.spacing.s24};

  & > a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  & > a::after {
    width: ${legacyTheme.spacing.s8};
    height: ${legacyTheme.spacing.s8};
    margin-left: ${legacyTheme.spacing.s8};
    margin-top: 4px;

    border-top: 1.5px solid black;
    border-right: 1.5px solid black;

    transform: ${({ isOpen }) =>
      isOpen === 'true'
        ? 'translate3d(-50%, -25%, 0px) rotate(-45deg)'
        : 'translate3d(-50%, -75%, 0px) rotate(135deg)'};

    content: '';
  }
`;

const SubNavBoxMobile = styled(animated.div)`
  overflow: hidden;

  & > div {
    padding-top: ${legacyTheme.spacing.s12};
  }

  & > div > a + a {
    margin-top: ${legacyTheme.spacing.s12};
  }
`;

type SubNavDesktopProps = {
  link: {
    text: string;
    subMenu: {
      dataClick: string;
      text: string;
      to: string;
    }[];
  };
};

const SubNavDesktop = ({ link: { subMenu, text } }: SubNavDesktopProps) => (
  <SubNavContainerDesktop>
    {/* @ts-expect-error - Typography has no TS types yet */}
    <NavLink color="noir" markupName={Link} to="#" variant="p2">
      {text}
    </NavLink>
    <SubNavBoxDesktop>
      {subMenu.map(({ dataClick: subData, text: subText, to: subTo }) => (
        // @ts-expect-error - Typography has no TS types yet
        <NavLink
          key={subText}
          color="noir"
          data-click={subData}
          data-from="header-nav"
          data-testid={subData}
          markupName={Link}
          to={subTo}
          variant="p2"
        >
          {subText}
        </NavLink>
      ))}
    </SubNavBoxDesktop>
  </SubNavContainerDesktop>
);

type SubNavMobileProps = {
  closeMenu: Function;
  link: {
    subMenu: {
      dataClick: string;
      text: string;
      to: string;
    }[];
    text: string;
  };
};

const SubNavMobile = ({ link: { subMenu, text }, closeMenu }: SubNavMobileProps) => {
  const isMountedRef = useIsMounted();
  const ref = useRef<HTMLDivElement | null>(null);
  const height = useHeight(ref);
  const [open, setOpen] = useState(false);

  const props = useSpring({
    height: height && open ? height : 0,
    from: 0,
  });

  return (
    <SubNavContainerMobile isOpen={open.toString()}>
      {/* @ts-expect-error - Typography has no TS types yet */}
      <Typography
        align="left"
        color="noir"
        markupName={Link}
        onClick={() => {
          setOpen(!open);
        }}
        style={{ fontSize: 20 }}
        to="#"
        variant="h3"
      >
        {text}
      </Typography>
      {/* check ref to avoid state update on unmounted component error */}
      <SubNavBoxMobile style={(isMountedRef.current === true && props) || undefined}>
        <div ref={ref}>
          {subMenu.map(({ dataClick: subData, text: subText, to: subTo }) => (
            // @ts-expect-error - Typography has no TS types yet
            <Typography
              key={subText}
              align="left"
              color="noir"
              data-click={subData}
              data-from="header-nav"
              data-testid={subData}
              markupName={Link}
              onClick={closeMenu}
              to={subTo}
              variant="p2"
            >
              {subText}
            </Typography>
          ))}
        </div>
      </SubNavBoxMobile>
    </SubNavContainerMobile>
  );
};

const scrollTop = () => {
  try {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  } catch (_error) {
    // looks like some browsers don't accept the parameters above.
    // So we retry with the classic signature.
    window.scrollTo(0, 0);
  }
};

const NavButton = styled.button`
  margin-right: auto;

  background-color: transparent;
  border: none;

  font-family: inherit;

  &:hover {
    text-decoration: underline;
    color: ${legacyTheme.palette.common.noir.medium};
  }
`;

const QuantityIndicator = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  width: 16px;
  height: 16px;

  background-color: ${theme.colors.accent[200]};
  border-radius: 16px;
`;

type NavBarProps = {
  hideNavbarCTA?: boolean;
  isAuthenticated?: boolean;
  isAuthenticating?: boolean;
  userName?: string;
  useCTA?: Function;
};

export const NavBar = ({
  hideNavbarCTA = false,
  isAuthenticated = false,
  isAuthenticating = false,
  useCTA = useCTADI,
  userName = '',
}: NavBarProps) => {
  const hasCompletedOneOfConsultation = useAppSelector(
    userSelectors.getHasCompletedOneOfConsultation,
  );
  const hasSubscription = useAppSelector(userSelectors.getHasSubscription);
  const itemQuantityInCartV2 = useAppSelector(getItemQuantityInCartV2);
  // @ts-expect-error - Some selectors have no TS types yet
  const showUnlockAccessories = useAppSelector(featureFlagSelectors.shouldShowUnlockAccessories);
  const { showShoppingCart, ...params } = useCTA();
  const { pathname } = useLocation();
  // 1024px is not enough to see all of the items in the navbar
  const isDesktop = useMedia(`(min-width: ${isDesktopThreshold}px)`);
  const [isMenuOpen, setMenuOpen] = useState(false);

  // ensure menu is closed on url change
  useEffect(() => {
    setMenuOpen(false);
  }, [pathname]);

  // ensure menu is closed on wider screens
  useEffect(() => {
    if (isDesktop && !isMenuOpen) {
      setMenuOpen(false);
    }
  }, [isDesktop, isMenuOpen]);

  // add class to the root element to disable scroll when menu is open
  useEffect(() => {
    const browserSupport = document.documentElement && document.documentElement.classList;
    if (isMenuOpen && browserSupport) {
      document.documentElement.classList.add('prose_no-scroll');
      return () => document.documentElement.classList.remove('prose_no-scroll');
    }
    return () => {};
  }, [isMenuOpen]);

  // get started CTA
  // this is Hacky, waiting for product to define next steps after success of this AB test
  const showGetStarted = !isDesktop && !isAuthenticating && !isAuthenticated;

  const showSigninLink = !isAuthenticated && !showGetStarted;
  const showAccountLink = !showUnlockAccessories ? isAuthenticated && !showGetStarted : true;
  const showCTA = !hideNavbarCTA && isDesktop && !showGetStarted;

  const closeMenu = () => isMenuOpen && setMenuOpen(false);

  return (
    <Root
      className={classNames({
        menuOpen: isMenuOpen && !isDesktop,
        hideOverflow: !isDesktop,
      })}
      role="navigation"
    >
      <NavHeader data-testid="navbar-navigation">
        {/* Link to open and close the menu */}
        {!isDesktop && (
          <NavContent>
            <NavButton
              aria-label={isMenuOpen ? 'close-menu' : 'open-menu'}
              onClick={() => setMenuOpen(!isMenuOpen)}
              type="button"
            >
              {isMenuOpen ? (
                <Image
                  alt={content.crossIcon.alt}
                  height={14}
                  src={content.crossIcon.image}
                  width={16}
                />
              ) : (
                // @ts-expect-error - Typography has no TS types yet
                <Typography color="noir" variant="p2">
                  Menu
                </Typography>
              )}
            </NavButton>
          </NavContent>
        )}

        {/* Prose nav logo */}
        <LinkContainer
          data-click="prose-logo"
          data-from="header-nav"
          data-testid="navbar-logo"
          onClick={() => {
            closeMenu();
            scrollTop();
          }}
          to="/"
        >
          <Image alt={content.logo.alt} height={22} src={content.logo.image} width={62} />
        </LinkContainer>

        {/* Desktop links on the nav to replace the menu */}
        {isDesktop && (
          <DesktopNavBarWrapper>
            {content.menuOptions.map((link) => {
              const { dataClick, dataTestId, subMenu, text, to } = link;

              /* Handling submenu display */
              if (subMenu) {
                return <SubNavDesktop key={text} link={link} />;
              }

              return (
                // @ts-expect-error - Typography has no TS types yet
                <NavLink
                  key={text}
                  color="noir"
                  data-click={dataClick}
                  data-from="header-nav"
                  data-testid={dataTestId || ''}
                  markupName={Link}
                  to={to}
                  variant="p2"
                >
                  {text}
                </NavLink>
              );
            })}
            {/* To be migrated in content.navbar once turned into selector */}
            {/* @ts-expect-error - Typography has no TS types yet */}
            <NavLink
              color="noir"
              data-click={hasSubscription ? 'membership-lp-subscribers' : 'membership-lp-prospects'}
              data-from="header-nav"
              data-testid="membership-nav-link"
              markupName={Link}
              to="/the-salon"
              variant="p2"
            >
              {hasSubscription ? 'My Subscription' : 'Subscribe & Save'}
            </NavLink>
          </DesktopNavBarWrapper>
        )}
        {/* Links to the right of the bar */}
        <NavContentRight>
          {showGetStarted && !showUnlockAccessories && (
            <ConsultationCTA
              // @ts-expect-error - ConsultationCTA has no TS types yet
              render={({ to, dataClick }) => (
                // @ts-expect-error - Typography has no TS types yet
                <NavLink
                  className="align-right"
                  color="noir"
                  data-click={dataClick}
                  data-from="header-nav"
                  markupName={Link}
                  to={to}
                  variant="p2"
                >
                  Get Started
                </NavLink>
              )}
            />
          )}

          {!showShoppingCart && showCTA && !showUnlockAccessories && (
            <ConsultationCTA
              // @ts-expect-error - ConsultationCTA has no TS types yet
              render={({ to, dataClick, label }) => (
                // @ts-expect-error - Typography has no TS types yet
                <NavLink
                  className="align-right"
                  color="sorbet"
                  data-click={dataClick}
                  data-from="header-nav"
                  data-testid="navbar-consultation-cta"
                  markupName={Link}
                  to={to}
                  variant="p2"
                >
                  {label}
                </NavLink>
              )}
            />
          )}

          {showShoppingCart && (
            // @ts-expect-error - Typography has no TS types yet
            <NavLink
              className="align-right"
              color="sorbet"
              data-click={params.dataClick}
              data-from="header-nav"
              data-testid={params.dataClick}
              markupName={Link}
              onClick={closeMenu}
              style={{ padding: 0 }}
              title={params.label}
              to={params.to}
              variant="p2"
            >
              <BagIcon data-testid="cart-icon" />
              {showUnlockAccessories &&
                (Boolean(itemQuantityInCartV2) || hasCompletedOneOfConsultation) && (
                  <QuantityIndicator data-testid="cart-icon-quantity-indicator">
                    {!hasCompletedOneOfConsultation && (
                      // @ts-expect-error - Typography has no TS types yet
                      <Typography color="noir" variant="p2">
                        {itemQuantityInCartV2}
                      </Typography>
                    )}
                  </QuantityIndicator>
                )}
            </NavLink>
          )}
          {showAccountLink && (
            <LinkContainer
              data-click="account"
              data-from="header-nav"
              data-testid="account-link"
              onClick={closeMenu}
              to={isDesktop ? '/account/overview' : '/account/nav'}
            >
              <Image
                alt={content.userIcon.alt}
                data-testid="account-icon"
                height={22}
                src={content.userIcon.image}
                width={21}
              />
              {isDesktop && (
                // @ts-expect-error - Typography has no TS types yet
                <Typography
                  className="remove-in-percy"
                  color="noir"
                  css={{
                    display: 'inline',
                    marginLeft: legacyTheme.spacing.s8,
                    alignSelf: 'center',
                  }}
                  markupName="span"
                  variant="p2"
                >
                  {userName}
                </Typography>
              )}
            </LinkContainer>
          )}

          {showSigninLink && !showUnlockAccessories && (
            // @ts-expect-error - Typography has no TS types yet
            <NavLink
              color="noir"
              data-click="signin"
              data-from="header-nav"
              markupName={Link}
              onClick={closeMenu}
              to="/signin"
              variant="p2"
            >
              {content.signinLink}
            </NavLink>
          )}
        </NavContentRight>
      </NavHeader>

      {/* Collapsible part of the menu */}
      {!isDesktop && (
        <MobileMenuRoot data-testid="navbar-menu">
          <MenuList>
            {content.menuOptions.map((link) => {
              const { dataClick, dataTestId, subMenu, text, to } = link;
              if (subMenu) {
                return (
                  <MobileMenuListItem key={text}>
                    <SubNavMobile closeMenu={closeMenu} link={link} />
                  </MobileMenuListItem>
                );
              }
              return (
                <MobileMenuListItem key={text}>
                  {/* @ts-expect-error - Typography has no TS types yet */}
                  <MobileMenuListAnchor
                    align="left"
                    color="noir"
                    data-click={dataClick}
                    data-from="header-nav"
                    data-testid={dataTestId || ''}
                    markupName={Link}
                    onClick={closeMenu}
                    style={{ fontSize: 20 }}
                    to={to}
                    variant="h3"
                  >
                    {text}
                  </MobileMenuListAnchor>
                </MobileMenuListItem>
              );
            })}
            {/*  To be migrated in content.navbar once turned into selector */}
            <MobileMenuListItem>
              {/* @ts-expect-error - Typography has no TS types yet */}
              <MobileMenuListAnchor
                align="left"
                color="noir"
                data-click={
                  hasSubscription ? 'membership-lp-subscribers' : 'membership-lp-prospects'
                }
                data-from="header-nav"
                data-testid="membership-nav-link"
                markupName={Link}
                onClick={closeMenu}
                style={{ fontSize: 20 }}
                to="/the-salon"
                variant="h3"
              >
                {hasSubscription ? 'My Subscription' : 'Subscribe & Save'}
              </MobileMenuListAnchor>
            </MobileMenuListItem>

            {isAuthenticated && (
              <>
                <MenuSeparator />
                <MobileMenuListItem>
                  {/* @ts-expect-error - Typography has no TS types yet */}
                  <MobileMenuListAnchor
                    align="left"
                    color="noir"
                    data-click="account"
                    data-from="header-nav"
                    markupName={Link}
                    onClick={closeMenu}
                    style={{ fontSize: 20 }}
                    to="/account"
                    variant="h3"
                  >
                    {content.accountLink}
                  </MobileMenuListAnchor>
                </MobileMenuListItem>
              </>
            )}
          </MenuList>
          <MenuFooter>
            {/* @ts-expect-error - Typography has no TS types yet */}
            <MenuMessage align="left" color="grey" variant="p2">
              Have a question?{' '}
              <Link data-click="contact-us" data-from="header-nav" to="/contact">
                Contact us.
              </Link>
            </MenuMessage>
            <ButtonContainer>
              <ConsultationCTA
                css={{ maxWidth: 327, marginRight: legacyTheme.spacing.s8 }}
                dataFrom="header-nav"
                dataTestId="navbar-mobile-consultation-cta"
                variant="vert"
              />
              {!isAuthenticated && (
                // @ts-expect-error - Button has no TS types yet
                <Button
                  Component={Link}
                  css={{ maxWidth: 111 }}
                  data-click="signin"
                  data-from="header-nav"
                  onClick={closeMenu}
                  to="/signin"
                  variant="noir"
                >
                  {content.signinLink}
                </Button>
              )}
            </ButtonContainer>
          </MenuFooter>
        </MobileMenuRoot>
      )}
    </Root>
  );
};
