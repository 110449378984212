import { theme } from '@prose-ui';

import { SKINCARE_CART_URL } from 'constants/cart';

import discount20PercentBadge from 'assets/images/badge_20percent_off.svg';
import discount50PercentBadge from 'assets/images/badge_save_50percent.svg';
import freeCleanserBadge from 'assets/images/bagde_free_cleanser.svg';

export const rootToastContent = {
  'subscription-20-offer': {
    content: '20% off all subscription orders will be applied at cart',
    style: { color: 'white', backgroundColor: theme.colors.primary[400] },
  },
  'free-cleanser-offer': {
    content: 'Your free Cleanser will be applied at cart',
    style: { color: 'white', backgroundColor: theme.colors.primary[400] },
  },
  'first-order-50-offer': {
    content: 'Your 50% offer will be applied at cart',
    style: { color: 'white', backgroundColor: theme.colors.primary[400] },
  },
};

const contentVariantMap = {
  US: {
    'subscription-20-offer': {
      archedText: 'Introducing custom skincare',
      title: 'Save 20% when you subscribe to skincare',
      badge: { src: discount20PercentBadge, alt: '20% off' },
      cta: {
        skincareCompleted: {
          linkTo: () => '/pages/skincare',
          dataClick: 'explore-skincare',
          label: 'Explore skincare',
        },
        skincareNotCompleted: {
          linkTo: () => '/pages/skincare',
          dataClick: 'explore-skincare',
          label: 'Explore skincare',
        },
      },
      conditions: 'Offer available when you subscribe to haircare and skincare.',
    },
    'free-cleanser-offer': {
      archedText: 'Introducing custom skincare',
      title: 'Save 20% + get your Cleanser free when you subscribe to skincare',
      badge: { src: freeCleanserBadge, alt: 'free cleanser' },
      cta: {
        skincareCompleted: {
          linkTo: () => '/pages/skincare',
          dataClick: 'explore-skincare',
          label: 'Explore skincare',
        },
        skincareNotCompleted: {
          linkTo: () => '/pages/skincare',
          dataClick: 'explore-skincare',
          label: 'Explore skincare',
        },
      },
      conditions:
        'Offer valid for a full-size Cleanser when you subscribe to Cleanser, Serum, and Moisturizer, expires in 48 hours.',
    },
    'first-order-50-offer': {
      archedText: 'special offer',
      title: 'Get 50% off when you subscribe to skincare',
      description: 'Free shipping | 100% satisfaction guarantee',
      badge: { src: discount50PercentBadge, alt: 'save 50%' },
      cta: {
        skincareCompleted: {
          linkTo: () => SKINCARE_CART_URL,
          dataClick: 'get-your-offer',
          label: 'Get your offer',
        },
        skincareNotCompleted: {
          linkTo: () => '/consultation/skincare',
          dataClick: 'get-your-offer',
          label: 'Get your offer',
        },
      },
      conditions:
        'Enjoy a total of 50% off your first order when you subscribe to skincare. All ongoing skincare subscription orders are eligible for free shipping and 15% off. Subscribe to both haircare and skincare for a total ongoing subscription discount of 20%. The 50% offer is valid for new subscription orders placed on or before 12/26/2023 at 11:59 PM EST. Promotion cannot be applied to previous orders, existing skincare subscriptions, and non-subscribed products. Not valid for accessories (hair towels, candles, and brushes), or digital gift cards.',
    },
  },
};

// bottom modal
export const modal = {
  title: (
    <>
      Get <b>50% off</b> your first order +&nbsp;<b>free&nbsp;shipping</b> when you subscribe to
      your skincare
    </>
  ),
  description:
    'Enjoy a total of 50% off your first order when you subscribe to skincare. All ongoing skincare subscription orders are eligible for free shipping and 15% off. Subscribe to both haircare and skincare for a total ongoing subscription discount of 20%. The 50% offer is valid for new subscription orders placed on or before 12/26/2023 at 11:59 PM EST. Promotion cannot be applied to previous orders, existing skincare subscriptions, and non-subscribed products. Not valid for accessories (hair towels, candles, and brushes), or digital gift cards.',
};

export const checkoutCommunication = {
  title: 'first order offer',
  description: (
    <>
      Get <b>50% off</b> your first order +&nbsp;<b>free&nbsp;shipping</b> when you subscribe to
      your skincare
    </>
  ),
  terms: 'See terms & conditions',
};

export default contentVariantMap;
