import {
  FIRST_HAIRCARE_QUESTION_ROUTE,
  FIRST_SKINCARE_QUESTION_ROUTE,
} from 'Apps/Consultation/constants';

import { HAIRCARE_CART_URL, SKINCARE_CART_URL } from 'constants/cart';

import image from 'assets/images/promotionalModals/trial-offer-summer-sale-gwp-sc-promotion-modal.jpg';

const content = {
  contentDefault: {
    banner: 'Get 50% off + a free mystery gift',
    popUpModal: {
      image,
      headerTitle: 'Summer sale',
      title: '50% off + a free mystery gift',
      description: 'Subscribe to 3+ hair or skin products to unlock the deal.',
      cta: {
        consultationCompleted: {
          linkTo: () => HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'hc-sc-pop-up',
          ctaLabel: 'Get your gift',
        },
        consultationNotCompleted: {
          linkTo: (route: string) => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'hc-sc-pop-up',
          ctaLabel: 'Get your gift',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'hc-sc-pop-up',
      },
      conditions:
        '*Get [1] free mystery gift when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off, or 20% off when subscribed to both Haircare and Skincare. Offer is valid when your subscription order is placed by 06/17/2024 at 11:59 PM EST or while supplies last. Gift with purchase selection randomized. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
      tracking: {
        modalClosing: 'Trial Offer v2 HC + SC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 HC + SC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 HC + SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'Summer sale',
      description: (
        <>
          Get <b>50% off + a free mystery gift</b> when you subscribe to 3+ hair or skin products.
        </>
      ),
      conditions:
        '*Get [1] free mystery gift when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off, or 20% off when subscribed to both Haircare and Skincare. Offer is valid when your subscription order is placed by 06/17/2024 at 11:59 PM EST or while supplies last. Gift with purchase selection randomized. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
    },
    checkoutCommunication: {
      title: 'Summer sale',
      description: (
        <>
          Get <b>50% off + a free mystery gift</b> when you subscribe to 3+ hair or skin products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  contentHaircare: {
    banner: 'Get 50% off + a free mystery gift',
    popUpModal: {
      image,
      headerTitle: 'Summer sale',
      title: '50% off + a free mystery gift',
      description: 'Subscribe to 3+ haircare products to unlock the deal.',
      cta: {
        consultationCompleted: {
          linkTo: () => HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'hc-pop-up',
          ctaLabel: 'Get your gift',
        },
        consultationNotCompleted: {
          linkTo: (route: string) => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'hc-pop-up',
          ctaLabel: 'Get your gift',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'hc-pop-up',
      },
      conditions:
        '*Get [1] free mystery gift when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off, or 20% off when subscribed to both Haircare and Skincare. Offer is valid when your subscription order is placed by 06/17/2024 at 11:59 PM EST or while supplies last. Gift with purchase selection randomized. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
      tracking: {
        modalClosing: 'Trial Offer v2 HC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 HC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 HC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'Summer sale',
      description: (
        <>
          Get <b>50% off + a free mystery gift</b> when you subscribe to 3+ haircare products.
        </>
      ),
      conditions:
        '*Get [1] free mystery gift when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off, or 20% off when subscribed to both Haircare and Skincare. Offer is valid when your subscription order is placed by 06/17/2024 at 11:59 PM EST or while supplies last. Gift with purchase selection randomized. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
    },
    checkoutCommunication: {
      title: 'Summer sale',
      description: (
        <>
          Get <b>50% off + a free mystery gift</b> when you subscribe to 3+ haircare products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  contentSkincare: {
    banner: 'Get 50% off + a free mystery gift',
    popUpModal: {
      image,
      headerTitle: 'Summer sale',
      title: '50% off + a free mystery gift',
      description: 'Subscribe to 3+ skincare products to unlock the deal.',
      cta: {
        consultationCompleted: {
          linkTo: () => SKINCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'sc-pop-up',
          ctaLabel: 'Get your gift',
        },
        consultationNotCompleted: {
          linkTo: () => FIRST_SKINCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'sc-pop-up',
          ctaLabel: 'Get your gift',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'sc-pop-up',
      },
      conditions:
        '*Get [1] free mystery gift when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off, or 20% off when subscribed to both Haircare and Skincare. Offer is valid when your subscription order is placed by 06/17/2024 at 11:59 PM EST or while supplies last. Gift with purchase selection randomized. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
      tracking: {
        modalClosing: 'Trial Offer v2 SC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 SC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'Summer sale',
      description: (
        <>
          Get <b>50% off + a free mystery gift</b> when you subscribe to 3+ skincare products.
        </>
      ),
      conditions:
        '*Get [1] free mystery gift when you subscribe to 3+ products in the same category (Haircare or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to haircare or skincare. Subsequent subscription orders will receive 15% off, or 20% off when subscribed to both Haircare and Skincare. Offer is valid when your subscription order is placed by 06/17/2024 at 11:59 PM EST or while supplies last. Gift with purchase selection randomized. Cannot be applied to previous orders or non-subscribed items. Not valid for accessories or digital gift cards.',
    },
    checkoutCommunication: {
      title: 'Summer sale',
      description: (
        <>
          Get <b>50% off + a free mystery gift</b> when you subscribe to 3+ skincare products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
};

export default content;
