import {
  FIRST_HAIRCARE_QUESTION_ROUTE,
  FIRST_SKINCARE_QUESTION_ROUTE,
} from 'Apps/Consultation/constants';

import { HAIRCARE_CART_URL, SKINCARE_CART_URL } from 'constants/cart';

import posterImage from 'assets/images/promotionalModals/trial-offer-mystery-gwp-video-frame.jpg';

const content = {
  contentDefault: {
    banner: 'Get 50% off + a free mystery gift (up to $25 value!)',
    popUpModal: {
      video: {
        source: 'trial-offer-mystery-gift-popup',
        posterImage,
        posterImageAlt: 'Get 50% off + a free mystery gift (up to $25 value!)',
      },
      headerTitle: 'ROUTINE REFRESH SALE',
      title: 'Get 50% off + a free mystery gift',
      description:
        'Save 50% + get a free mystery gift (up to $25 value!) when you subscribe to 3+ hair or skin products.',
      cta: {
        consultationCompleted: {
          linkTo: () => HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'mystery-gwp-jan-2024-hc-sc-pop-up',
          ctaLabel: 'GET YOUR GIFT',
        },
        consultationNotCompleted: {
          linkTo: route => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'mystery-gwp-jan-2024-hc-sc-pop-up',
          ctaLabel: 'GET YOUR GIFT',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'mystery-gwp-jan-2024-hc-sc-pop-up',
      },
      conditions:
        '*Get (1) free Mystery Gift when you subscribe to 3+ products. Enjoy a total of 50% off your first order when you subscribe to haircare or skincare. All ongoing subscription orders are eligible for free shipping and 15% off. Subscribe to both haircare and skincare for a total ongoing subscription discount of 20%. Promotion cannot be applied to previous orders, existing haircare or skincare subscriptions, and non-subscribed products. On-hold subscriptions that are reactivated during the promotional period are not eligible for the promotion. Not valid for accessories (hair towels, candles, and brushes [US only]), or digital gift cards. The 50% off and (1) free Mystery Gift offers are valid for new subscription orders processed on or before 1/17/2024 at 11:59 PM EST. Free Mystery Gift with purchase available while supplies last.',
      tracking: {
        modalClosing: 'Mystery GWP Jan 2024 HC + SC Pop-up - Closed',
        modalOpening: 'Mystery GWP Jan 2024 HC + SC Pop-up - Viewed',
        modalOpening2sec: 'Mystery GWP Jan 2024 HC + SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'ROUTINE REFRESH SALE',

      description: (
        <>
          <b>Get 50% off + a free mystery gift (up to $25 value!)</b> when you subscribe to 3+ hair
          or skin products.
        </>
      ),
      conditions:
        '*Get (1) free Mystery Gift when you subscribe to 3+ products. Enjoy a total of 50% off your first order when you subscribe to haircare or skincare. All ongoing subscription orders are eligible for free shipping and 15% off. Subscribe to both haircare and skincare for a total ongoing subscription discount of 20%. Promotion cannot be applied to previous orders, existing haircare or skincare subscriptions, and non-subscribed products. On-hold subscriptions that are reactivated during the promotional period are not eligible for the promotion. Not valid for accessories (hair towels, candles, and brushes [US only]), or digital gift cards. The 50% off and (1) free Mystery Gift offers are valid for new subscription orders processed on or before 1/17/2024 at 11:59 PM EST. Free Mystery Gift with purchase available while supplies last.',
    },
    checkoutCommunication: {
      title: 'ROUTINE REFRESH SALE',
      description:
        'Get 50% off + a free mystery gift (up to $25 value!) when you subscribe to 3+ hair or skin products.',
      terms: 'See terms & conditions',
    },
  },
  contentHaircare: {
    banner: 'Get 50% off + a free mystery gift (up to $25 value!)',
    popUpModal: {
      video: {
        source: 'trial-offer-mystery-gift-popup',
        posterImage,
        posterImageAlt: 'Get 50% off + a free mystery gift (up to $25 value!)',
      },
      headerTitle: 'ROUTINE REFRESH SALE',
      title: 'Get 50% off + a free mystery gift',
      description:
        'Save 50% + get a free mystery gift (up to $25 value!) when you subscribe to 3+ haircare products.',
      cta: {
        consultationCompleted: {
          linkTo: () => HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'mystery-gwp-jan-2024-hc-pop-up',
          ctaLabel: 'GET YOUR GIFT',
        },
        consultationNotCompleted: {
          linkTo: route => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'mystery-gwp-jan-2024-hc-pop-up',
          ctaLabel: 'GET YOUR GIFT',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'mystery-gwp-jan-2024-hc-pop-up',
      },
      conditions:
        '*Get (1) free Mystery Gift when you subscribe to 3+ products. Enjoy a total of 50% off your first order when you subscribe to haircare. All ongoing subscription orders are eligible for free shipping and 15% off. Subscribe to both haircare and skincare for a total ongoing subscription discount of 20%. Promotion cannot be applied to previous orders, existing haircare or skincare subscriptions, and non-subscribed products. On-hold subscriptions that are reactivated during the promotional period are not eligible for the promotion. Not valid for accessories (hair towels, candles, and brushes [US only]), or digital gift cards. The 50% off and (1) free Mystery Gift offers are valid for new subscription orders processed on or before 1/17/2024 at 11:59 PM EST. Free Mystery Gift with purchase available while supplies last.',
      tracking: {
        modalClosing: 'Mystery GWP Jan 2024 HC Pop-up - Closed',
        modalOpening: 'Mystery GWP Jan 2024 HC Pop-up - Viewed',
        modalOpening2sec: 'Mystery GWP Jan 2024 HC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'ROUTINE REFRESH SALE',

      description: (
        <>
          <b>Get 50% off + a free mystery gift (up to $25 value!)</b> when you subscribe to 3+
          haircare products.
        </>
      ),
      conditions:
        '*Get (1) free Mystery Gift when you subscribe to 3+ products. Enjoy a total of 50% off your first order when you subscribe to haircare. All ongoing subscription orders are eligible for free shipping and 15% off. Subscribe to both haircare and skincare for a total ongoing subscription discount of 20%. Promotion cannot be applied to previous orders, existing haircare or skincare subscriptions, and non-subscribed products. On-hold subscriptions that are reactivated during the promotional period are not eligible for the promotion. Not valid for accessories (hair towels, candles, and brushes [US only]), or digital gift cards. The 50% off and (1) free Mystery Gift offers are valid for new subscription orders processed on or before 1/17/2024 at 11:59 PM EST. Free Mystery Gift with purchase available while supplies last.',
    },
    checkoutCommunication: {
      title: 'ROUTINE REFRESH SALE',
      description:
        'Get 50% off + a free mystery gift (up to $25 value!) when you subscribe to 3+ haircare products.',
      terms: 'See terms & conditions',
    },
  },
  contentSkincare: {
    banner: 'Get 50% off + a free mystery gift (up to $25 value!)',
    popUpModal: {
      video: {
        source: 'trial-offer-mystery-gift-popup',
        posterImage,
        posterImageAlt: 'Get 50% off + a free mystery gift (up to $25 value!)',
      },
      headerTitle: 'ROUTINE REFRESH SALE',
      title: 'Get 50% off + a free mystery gift',
      description:
        'Save 50% + get a free mystery gift (up to $25 value!) when you subscribe to 3+ skincare products.',
      cta: {
        consultationCompleted: {
          linkTo: () => SKINCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'mystery-gwp-jan-2024-sc-pop-up',
          ctaLabel: 'GET YOUR GIFT',
        },
        consultationNotCompleted: {
          linkTo: () => FIRST_SKINCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'mystery-gwp-jan-2024-sc-pop-up',
          ctaLabel: 'GET YOUR GIFT',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'mystery-gwp-jan-2024-sc-pop-up',
      },
      conditions:
        '*Get (1) free Mystery Gift when you subscribe to 3+ products. Enjoy a total of 50% off your first order when you subscribe to skincare. All ongoing subscription orders are eligible for free shipping and 15% off. Subscribe to both haircare and skincare for a total ongoing subscription discount of 20%. Promotion cannot be applied to previous orders, existing haircare or skincare subscriptions, and non-subscribed products. On-hold subscriptions that are reactivated during the promotional period are not eligible for the promotion. Not valid for accessories (hair towels, candles, and brushes [US only]), or digital gift cards. The 50% off and (1) free Mystery Gift offers are valid for new subscription orders processed on or before 1/17/2024 at 11:59 PM EST. Free Mystery Gift with purchase available while supplies last.',
      tracking: {
        modalClosing: 'Mystery GWP Jan 2024 SC Pop-up - Closed',
        modalOpening: 'Mystery GWP Jan 2024 SC Pop-up - Viewed',
        modalOpening2sec: 'Mystery GWP Jan 2024 SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'ROUTINE REFRESH SALE',

      description: (
        <>
          <b>Get 50% off + a free mystery gift (up to $25 value!)</b> when you subscribe to 3+
          skincare products.
        </>
      ),
      conditions:
        '*Get (1) free Mystery Gift when you subscribe to 3+ products. Enjoy a total of 50% off your first order when you subscribe to skincare. All ongoing subscription orders are eligible for free shipping and 15% off. Subscribe to both haircare and skincare for a total ongoing subscription discount of 20%. Promotion cannot be applied to previous orders, existing haircare or skincare subscriptions, and non-subscribed products. On-hold subscriptions that are reactivated during the promotional period are not eligible for the promotion. Not valid for accessories (hair towels, candles, and brushes [US only]), or digital gift cards. The 50% off and (1) free Mystery Gift offers are valid for new subscription orders processed on or before 1/17/2024 at 11:59 PM EST. Free Mystery Gift with purchase available while supplies last.',
    },
    checkoutCommunication: {
      title: 'ROUTINE REFRESH SALE',
      description:
        'Get 50% off + a free mystery gift (up to $25 value!) when you subscribe to 3+ skincare products.',
      terms: 'See terms & conditions',
    },
  },
};

export default content;
