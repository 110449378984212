import { type MouseEventHandler, useCallback, useState } from 'react';

import { theme } from '@prose-ui';
import { isPropValid, styled } from '@prose-ui/legacy';
import { mediaMinWidth } from '@prose-ui/utils/media';

import HorizontalSlider from 'Components/HorizontalSlider';
import Typography from 'Components/Typography';

import { ReactComponent as ArrowLeftIcon } from 'assets/images/icon_arrow_left_no_tail.svg';

import { ingredientURL } from 'utils/ingredients';

import type { EnrichedOrderItem } from 'dux/digitalLeaflet/types';

const IngredientSlide = styled.div`
  width: 50vw; /* use of viewport units here assumes the component is full-width on mobile */

  padding-inline: ${theme.spacing['3x']};

  ${mediaMinWidth('md')} {
    width: 100%;
    max-width: 33.3%;
  }

  display: flex;
  flex-direction: column;

  &:not(:last-of-type) {
    border-right: 0.5px solid ${theme.colors.neutral[800]};
  }
`;

const IngredientImage = styled.img`
  width: 80px;
  align-self: center;
  margin-bottom: ${theme.spacing['4x']};
`;
const IngredientDescription = styled(Typography)`
  margin-top: ${theme.spacing['1x']};
  margin-bottom: 0;
  color: ${theme.colors.neutral[800]};
`;

const NavContainer = styled.div`
  display: flex;
  width: 100%;

  align-items: center;
  justify-content: center;
  margin-top: ${theme.spacing['4x']};
  padding-inline: ${theme.spacing['3x']};

  ${mediaMinWidth('lg')} {
    display: none;
  }
`;

const Arrow = styled(ArrowLeftIcon, { shouldForwardProp: isPropValid })<{ isActive: boolean }>`
  width: 2.7rem;
  height: 2.7rem;
  padding: 0.7rem;

  border-radius: 100vmax;

  path {
    stroke: black;
  }

  ${({ isActive }) => `
  cursor: ${isActive ? 'pointer' : 'auto'};
  pointer-events: ${isActive ? 'auto' : 'none'};
  opacity: ${isActive ? '1' : '0.15'};
  `}

  transition: opacity 150ms;
`;
const ArrowPrevious = styled(Arrow)``;
const ArrowNext = styled(Arrow)`
  transform: scaleX(-1);
`;

const DotsContainer = styled.div`
  display: flex;
  margin-inline: auto;
`;

const Dot = styled.div<{ isSelected: boolean }>`
  --clearance-size: 8px; /* spacing between 2 dots */
  --visual-size: 6px;
  --clickable-size: calc(var(--visual-size) + var(--clearance-size));

  width: var(--clickable-size);
  height: var(--clickable-size);

  border-radius: 100vmax;

  display: grid;
  place-content: center center;

  ${({ isSelected }) => `
    opacity: ${isSelected ? 1 : 0.3};
  `}

  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.3)};
  transition: opacity 150ms;

  &:before {
    content: '';
    display: block;
    width: var(--visual-size);
    height: var(--visual-size);
    border-radius: 100vmax;
    background-color: black;
  }
`;

type DisplayedIngredients = EnrichedOrderItem['formula']['displayedIngredients'];

type Props = {
  ingredients: DisplayedIngredients;
};

const IngredientsCarousel = ({ ingredients }: Props) => {
  const [focusedIndex, setFocusedIndex] = useState(0);

  const focusPrevious = useCallback<MouseEventHandler>(
    (evt) => {
      evt.preventDefault();
      setFocusedIndex((prevFocusedIndex) =>
        Math.max(0, Math.min(ingredients.length - 1, prevFocusedIndex - 1)),
      );
    },
    [ingredients],
  );
  const focusNext = useCallback<MouseEventHandler>(
    (evt) => {
      evt.preventDefault();
      setFocusedIndex((prevFocusedIndex) =>
        Math.max(0, Math.min(ingredients.length - 1, prevFocusedIndex + 1)),
      );
    },
    [ingredients],
  );

  return (
    <div>
      <HorizontalSlider
        alignTrack="left"
        forcedFocus={focusedIndex}
        onFocusedSlideChange={setFocusedIndex}
        slides={ingredients.map((ing) => (
          <IngredientSlide key={ing.slug}>
            <IngredientImage alt="" src={ingredientURL(ing?.picture_name).src} />
            {/* @ts-expect-error - Typography has no TS types for now */}
            <Typography variant="mono2">{ing.name}</Typography>
            {/* @ts-expect-error - Typography has no TS types for now */}
            <IngredientDescription variant="p2">
              {ing.description_short || ing.description}
            </IngredientDescription>
          </IngredientSlide>
        ))}
        snap
      />
      {ingredients.length > 2 && (
        <NavContainer aria-hidden>
          <ArrowPrevious
            isActive={ingredients.length > 1 && focusedIndex > 0}
            onClick={focusPrevious}
          />
          <DotsContainer>
            {ingredients.map((_, index) => (
              <Dot key={index} isSelected={focusedIndex === index} />
            ))}
          </DotsContainer>
          <ArrowNext isActive={focusedIndex < ingredients.length - 1} onClick={focusNext} />
        </NavContainer>
      )}
    </div>
  );
};

export default IngredientsCarousel;
