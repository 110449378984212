import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'dux/app/hooks';

import { Modal, theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';
import Image from 'next/image';

import { promotionalModalContent } from 'assets/content/skincareWelcomeKit';
import { ReactComponent as Cross } from 'assets/images/cross_icon.svg';

import { createTranslationFn } from 'utils/createTranslationFn';
import useResponsiveVariant from 'utils/useResponsiveVariant';

import { trackHeapEvent } from 'dux/tracking/actions';
import { getCouponsStatuses } from 'dux/couponsStatuses/selectors';
import { getHasCompletedSkincareConsultation } from 'dux/user/selectors';

import OgArchedText from './ArchedText';
import Button from './LegacyButton';
import Typography from './Typography';

/**
 * Derive a new Modal component from @prose-ui
 * basically, we can use 'up' instead of match
 */
const StyledModal = styled(Modal)`
  --first-row-width: minmax(274px, 0.5fr);
  display: grid;

  grid-template-rows: var(--first-row-width) auto;
  justify-content: center;

  padding: 0;

  ${legacyTheme.breakpoints.up('sm')} {
    --first-row-width: minmax(274px, 1fr);
  }
`;

/**
 * Main container for the images
 *
 * Order of components within this container:
 * <IllusContainer>
 *  <BackgroundFiller>
 *  <ArchedText>
 *  <ArchedImageContainer>
 *    <ArchedImage>
 *  <BadgeContainer>
 *    <BadgeImage>
 */
const IllusContainer = styled.div`
  --img-width: clamp(180px, 65%, 30vh);
  /* distance between the top of the arch & the top of the container */
  --v-offset: calc(${theme.spacing['10x']} + ${theme.spacing['2x']});

  position: relative;

  overflow-x: hidden;
  display: grid;
  grid-template-columns: minmax(0, 1fr) var(--img-width) minmax(0, 1fr);
  grid-template-rows: 1.5rem min-content 1.3fr 2fr;
  grid-template-areas:
    '... ... ...'
    '... txt ...'
    '... img ...'
    '... img ...';

  height: 100%;
  min-height: fit-content;

  font-size: 0.8rem;

  ${legacyTheme.breakpoints.up('sm')} {
    /* above mobile we can rely on a fixed width */
    --img-width: 238px;
  }
`;

const BackgroundFiller = styled.div`
  /* it fills the whole column */
  grid-column: 1 / -1;
  /* but spans only on row 1 */
  grid-row: 1;

  height: calc(var(--img-width) / 2 + var(--v-offset));

  background: ${theme.colors.primary[300]};
`;

const ArchedText = styled(OgArchedText)`
  justify-self: center;

  width: min(100%, 178px);

  ${legacyTheme.breakpoints.up('sm')} {
    width: var(--img-width);
  }
`;

const ArchedImage = styled(Image)`
  /* to increase fidelity to the mock we zoom the img */
  transform: scale(1.4);

  display: block;

  width: 100%;
  height: 100%;

  object-fit: cover;
`;
// Handles the placement of the ArchedImage
const ArchedImageContainer = styled.div`
  /* badge is positioned absolutely to the arched image */
  position: relative;

  grid-area: img;
  justify-self: center;

  /* we zoom the img & want a crop effect */
  overflow: hidden;

  width: min(100%, var(--img-width));
  max-height: 226px;

  border-radius: 100vmax 100vmax 0 0;

  ${legacyTheme.breakpoints.up('sm')} {
    max-height: 303px;
  }
`;

const BadgeImage = styled(Image)`
  display: block;

  width: 100%;
  height: 100%;
`;
// Handles the placement of the BadgeImage
const BadgeContainer = styled.div`
  --badge-size: 88px;

  position: absolute;
  right: calc(-1 * var(--badge-size) / 2);

  grid-area: img;
  place-self: center;

  width: var(--badge-size);
  height: var(--badge-size);

  ${legacyTheme.breakpoints.up('sm')} {
    --badge-size: 114px;

    /* no need of absolute positioning above mobile */
    position: revert;

    /* translate is easier to manipulate in this case */
    transform: translate(calc(50% + var(--badge-size) / 2), calc(var(--badge-size) / 2));
  }
`;

/**
 * Main container for the text & buttons
 *
 * Order of components within this container:
 * <Container>
 *  <DiscountTitle>
 *  <Typography>
 *  <Button>
 *  <ClickableTypography>
 *  <Typography>
 */
const Container = styled.div`
  display: grid;
  gap: 0.8rem;
  justify-items: center;
  overflow-x: hidden;

  padding-block: min(${legacyTheme.spacing.s24}, 4vh) min(${legacyTheme.spacing.s32}, 4vh);
  padding-inline: ${legacyTheme.spacing.s24} ${legacyTheme.spacing.s24};
  height: auto;

  ${legacyTheme.breakpoints.up('sm')} {
    padding-top: ${legacyTheme.spacing.s16};
    padding-bottom: ${legacyTheme.spacing.s32};
    padding-inline: ${legacyTheme.spacing.s40};
  }
`;

const DiscountTitle = styled(Typography)`
  max-width: 28ch;
  @media (max-height: 660px), (max-width: 405px) {
    font-size: min(
      26px,
      3.75vh
    ); /* title would take a lot of vertical space on very small devices */
  }
`;

const ClickableTypography = styled(Typography)`
  cursor: pointer;

  width: fit-content;

  white-space: nowrap;
`;

const CloseModalButton = styled.button`
  cursor: pointer;

  position: absolute;

  top: ${legacyTheme.spacing.s24};
  right: ${legacyTheme.spacing.s24};

  width: ${legacyTheme.spacing.s24};
  height: ${legacyTheme.spacing.s24};

  padding: 0;
  margin: 0;
  z-index: 1;

  appearance: none;
  background: none;
  border: none;
`;

export const PromotionalModalWelcomeKit = ({
  isOpen,
  onClose,
  countryCode,
  hasCompletedSkincareConsultation,
}) => {
  const { isMobile } = useResponsiveVariant();
  const t = createTranslationFn(promotionalModalContent, countryCode, 'US');

  const ctaProps = hasCompletedSkincareConsultation
    ? t('cta.skincareCompleted')
    : t('cta.skincareNotCompleted');

  const dispatch = useDispatch();

  useEffect(() => {
    let timeout;
    if (isOpen) {
      dispatch(trackHeapEvent('Welcome kit popup - viewed')); // send event whenever displaying the Modal
      timeout = setTimeout(() => {
        dispatch(trackHeapEvent('Welcome kit popup - 2 sec viewed')); // send event after isOpen has not changed after duration
      }, 2000);
    }
    return () => {
      clearTimeout(timeout); // clear the timeout whenever isOpen changes
    };
  }, [isOpen]);

  return (
    <StyledModal hideClose isOpen={isOpen} onClose={onClose}>
      <CloseModalButton aria-label="close" id="modal-close-button" onClick={onClose} type="button">
        <Cross height="24" width="24" />
      </CloseModalButton>
      <IllusContainer>
        <BackgroundFiller />
        <ArchedText fontSize={isMobile ? '0.75rem' : '1rem'}>Exclusive offer</ArchedText>
        <ArchedImageContainer>
          <ArchedImage alt={t('arch.alt')} placeholder="blur" src={t('arch.src')} />
        </ArchedImageContainer>

        <BadgeContainer>
          <BadgeImage alt={t('badge.alt')} height={114} src={t('badge.src')} width={114} />
        </BadgeContainer>
      </IllusContainer>
      <Container data-testid="skincare-welcome-kit-modal-content">
        <DiscountTitle align="center" variant="h2">
          {t('title')}
        </DiscountTitle>
        <Typography
          align="center"
          data-testid={`skincare-welcome-kit-modal-${countryCode}-description`}
          style={{ maxWidth: '45ch' }}
          variant="p1"
        >
          {t('description')}
        </Typography>

        <Button
          Component={Link}
          data-click={ctaProps.dataClick}
          data-from="welcome-kit-popup"
          fullWidth={isMobile}
          style={!isMobile ? { width: legacyTheme.width.narrow } : {}}
          to={ctaProps.linkTo()}
          variant="vert"
        >
          {ctaProps.ctaLabel}
        </Button>
        <ClickableTypography
          color="noir"
          data-click="decline-offer"
          data-from="welcome-kit-popup"
          onClick={onClose}
          underline
          variant="p1"
        >
          {t('declineCta')}
        </ClickableTypography>
        <Typography align="center" color="grey" italic variant="p3">
          {t('conditions')}
        </Typography>
      </Container>
    </StyledModal>
  );
};

PromotionalModalWelcomeKit.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  hasCompletedSkincareConsultation: PropTypes.bool.isRequired,
  countryCode: PropTypes.oneOf(['US', 'CA']).isRequired,
};

PromotionalModalWelcomeKit.defaultProps = {
  isOpen: false,
};

/**
 * Toggle hook
 * @param {boolean} [initialValue=false]
 *
 * @returns {[boolean, (forcedValue: boolean) => void]}
 */
const useToogle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);
  const toggle = useCallback(
    forcedValue => (forcedValue ? setValue(forcedValue) : setValue(val => !val)),
    []
  );

  return [value, toggle];
};
const WithOwnStateWelcomeKitModal = ({ isOpen }) => {
  const [isOpened, toggleIsOpened] = useToogle(isOpen);
  const couponsStatuses = useSelector(getCouponsStatuses);
  const hasCompletedSkincareConsultation = useSelector(getHasCompletedSkincareConsultation);
  const countryCode = couponsStatuses?.country ?? 'US';

  return (
    <PromotionalModalWelcomeKit
      countryCode={countryCode}
      hasCompletedSkincareConsultation={hasCompletedSkincareConsultation}
      isOpen={isOpened}
      onClose={() => toggleIsOpened(false)}
    />
  );
};

WithOwnStateWelcomeKitModal.propTypes = {
  isOpen: PropTypes.bool,
};
WithOwnStateWelcomeKitModal.defaultProps = { isOpen: false };

export default WithOwnStateWelcomeKitModal;
