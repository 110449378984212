import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import BaseField from './BaseField';

/**
 * TextField is basically a BaseField which renders a text input with disabled autocomplete.
 */
const TextField = forwardRef(({ autoCorrect, renderInput, ...props }, ref) => (
  <BaseField ref={ref} {...props}>
    {inputProps =>
      renderInput ? (
        renderInput(inputProps)
      ) : (
        <input {...inputProps} autoComplete="chrome-off" autoCorrect={autoCorrect ? 'on' : 'off'} />
      )
    }
  </BaseField>
));

TextField.displayName = 'TextField';
TextField.propTypes = {
  autoCorrect: PropTypes.bool,
  /**
   * Function to render the input element, will be called with the input props
   */
  renderInput: PropTypes.func,

  /**
   * All remaining props are passed to BaseField
   */
};

TextField.defaultProps = {
  autoCorrect: true,
};

export default TextField;
