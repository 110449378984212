import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@prose-ui/legacy';

const Container = styled.div`
  grid-area: txt; /* must be positioned by its parent using grid */

  overflow: visible;
  font-size: ${({ baseLineOffset, fontSize }) => `calc(${fontSize} / ${baseLineOffset})`};
  font-family: ${props => props.theme.typography.simplonMono};
  text-transform: uppercase;
  font-weight: bold;

  --div-height: ${({ baseLineOffset, fontSize, height }) =>
    `calc((${height * baseLineOffset}px - ${height}px) + ${fontSize} / ${baseLineOffset})`};
  height: var(--div-height);

  svg {
    display: inline-block;
    overflow: visible;
    fill: ${props => props.theme.palette.common.white.light};

    transform-origin: center calc(100% + var(--div-height));
    transform: translateY(var(--div-height)) scale(${({ baseLineOffset }) => baseLineOffset});
  }
`;

const defaultWidth = 1;
const defaultHeight = 0.5;
const ArchedText = ({ children, fontSize, letterSpacing, baseLineOffset, className }) => {
  const containerRef = useRef(null);

  const [width, setWidth] = useState(defaultWidth);
  const [height, setHeight] = useState(defaultHeight);

  const handleResize = useCallback(() => {
    const rect = containerRef.current?.getBoundingClientRect();
    setWidth(rect.width ?? defaultWidth);
    setHeight(rect.width ? rect.width * 0.5 : defaultHeight);
  }, [fontSize, letterSpacing, baseLineOffset, className, width]);

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <Container
      ref={containerRef}
      baseLineOffset={baseLineOffset}
      className={className}
      fontSize={fontSize}
      height={height}
      width={width}
    >
      <svg viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg">
        <path
          d={`M0,${height}  C0 0, ${height} 0, ${height} 0, S${width} 0, ${width} ${height}`}
          fill="none"
          id="curvePath"
          stroke="transparent" // use a color instead for visualising the path (e.g. debugging)
        />

        <text textAnchor="middle">
          <textPath href="#curvePath" letterSpacing={letterSpacing} startOffset="50%">
            {children}
          </textPath>
        </text>
      </svg>
    </Container>
  );
};

ArchedText.propTypes = {
  children: PropTypes.node.isRequired,
  fontSize: PropTypes.string,
  letterSpacing: PropTypes.number,
  baseLineOffset: PropTypes.number,
  className: PropTypes.string,
};

ArchedText.defaultProps = {
  fontSize: '1em',
  letterSpacing: 1.4,
  baseLineOffset: 1.08,
  className: null,
};

export default ArchedText;
