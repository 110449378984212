import { createSlice } from '@reduxjs/toolkit';

import * as statuses from 'constants/statuses';
import { subscriptionSubCategories } from 'constants/subscriptions';

import {
  createUpsellSubscription,
  fetchHaircareAccountOverviewData,
  fetchSkincareAccountOverviewData,
  getUpsellSubscriptionPreview,
} from './thunks';

type Status =
  | typeof statuses.IDLE
  | typeof statuses.LOADING
  | typeof statuses.SUCCESS
  | typeof statuses.ERROR;

type OverviewReduxState = {
  status: {
    haircare: Status;
    skincare: Status;
  };
  data: {
    haircare: {
      member_since: string;
      goals: string[];
      subscribed_subcategory: (
        | typeof subscriptionSubCategories.SUPPLEMENTS
        | typeof subscriptionSubCategories.TOPICALS
      )[];
      upsell_title: string;
      transaction_id: string;
      upsell_products: {
        product: string;
        rank: number;
        benefit: string;
        product_how_to: string;
        product_description: string | string[]; // old: `string`, new: `string[]`
        fragrance: string;
        fragrance_description: string;
        fragrance_smells_like: string;
        price: number;
        subscribed_price: number;
        subscription_discount: number;
        sub_category:
          | typeof subscriptionSubCategories.SUPPLEMENTS
          | typeof subscriptionSubCategories.TOPICALS;
        capacity: string;
        currency: string;
        key_ingredients: {
          description: string;
          slug: string;
          name: string;
          picture_name: string;
        }[];
      }[];
    } | null;
    skincare: {
      member_since: string;
      goals: string[];
      subscribed_subcategory: (
        | typeof subscriptionSubCategories.SUPPLEMENTS
        | typeof subscriptionSubCategories.TOPICALS
      )[];
      upsell_title: string;
      transaction_id: string;
      upsell_products: {
        product: string;
        rank: number;
        benefit: string;
        product_how_to: string;
        product_description: string | string[]; // old: `string`, new: `string[]`
        fragrance: string;
        fragrance_description: string;
        fragrance_smells_like: string;
        price: number;
        subscribed_price: number;
        subscription_discount: number;
        sub_category:
          | typeof subscriptionSubCategories.SUPPLEMENTS
          | typeof subscriptionSubCategories.TOPICALS;
        capacity: string;
        currency: string;
        key_ingredients: {
          description: string;
          slug: string;
          name: string;
          picture_name: string;
        }[];
      }[];
    } | null;
  };
  error: boolean | null;
  subscriptionCreationStatus: Status;
  subscriptionModalStatus: Status;
};

const accountOverview = createSlice({
  name: 'accountOverview',
  initialState: {
    status: {
      haircare: statuses.IDLE,
      skincare: statuses.IDLE,
    },
    data: {
      haircare: null,
      skincare: null,
    },
    error: null,
    subscriptionCreationStatus: statuses.IDLE,
    subscriptionModalStatus: statuses.IDLE,
  } as OverviewReduxState,
  reducers: {
    clearSubscriptionCreation: (draft) => {
      draft.subscriptionCreationStatus = statuses.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHaircareAccountOverviewData.pending, (draft) => {
        draft.status.haircare = statuses.LOADING;
        draft.error = false;
      })
      .addCase(fetchHaircareAccountOverviewData.fulfilled, (draft, { payload }) => {
        draft.status.haircare = statuses.SUCCESS;
        draft.error = false;
        draft.data.haircare = payload;
      })
      .addCase(fetchHaircareAccountOverviewData.rejected, (draft, action) => {
        if (action?.error.name === 'ConditionError') {
          draft.status.haircare = statuses.SUCCESS;
          draft.error = false;
        } else {
          draft.status.haircare = statuses.ERROR;
          draft.error = true;
        }
      })
      .addCase(fetchSkincareAccountOverviewData.pending, (draft) => {
        draft.status.skincare = statuses.LOADING;
        draft.error = false;
      })
      .addCase(fetchSkincareAccountOverviewData.fulfilled, (draft, { payload }) => {
        draft.status.skincare = statuses.SUCCESS;
        draft.error = false;
        draft.data.skincare = payload;
      })
      .addCase(fetchSkincareAccountOverviewData.rejected, (draft, action) => {
        if (action?.error.name === 'ConditionError') {
          draft.status.skincare = statuses.SUCCESS;
          draft.error = false;
        } else {
          draft.status.skincare = statuses.ERROR;
          draft.error = true;
        }
      })
      .addCase(createUpsellSubscription.pending, (draft) => {
        draft.subscriptionCreationStatus = statuses.LOADING;
      })
      .addCase(createUpsellSubscription.fulfilled, (draft) => {
        draft.subscriptionCreationStatus = statuses.SUCCESS;
      })
      .addCase(createUpsellSubscription.rejected, (draft) => {
        draft.subscriptionCreationStatus = statuses.ERROR;
      })
      .addCase(getUpsellSubscriptionPreview.pending, (draft) => {
        draft.subscriptionModalStatus = statuses.LOADING;
      })
      .addCase(getUpsellSubscriptionPreview.fulfilled, (draft, { payload }) => {
        draft.subscriptionModalStatus = statuses.SUCCESS;
        draft.data = { ...draft.data, ...payload };
      })
      .addCase(getUpsellSubscriptionPreview.rejected, (draft) => {
        draft.subscriptionModalStatus = statuses.ERROR;
      });
  },
});

export const { actions, reducer } = accountOverview;
