import { useCallback } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import {
  useAppDispatch as useDispatch,
  useAppSelector,
  useAppSelector as useSelector,
} from 'dux/app/hooks';

import * as checkoutSteps from 'Apps/Checkout/constants/checkoutSteps';
import useCheckoutCategory from 'Apps/Checkout/hooks/useCheckoutCategory';

import addOriginParam from 'utils/addOriginParam';

import * as checkoutCartActions from 'dux/checkoutCart/actions';
import { getIsAuthenticated } from 'dux/auth/selectors';
import {
  getHasCompletedShippingAddressSectionV2,
  getNextStepFromStepPropV2,
} from 'dux/cartV2/selectors';
import {
  getHasCompletedAccountDetailsSection,
  getNextStepFromStepProp,
} from 'dux/checkout/selectors';

export const useGoToCheckout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const hasCompletedAccountDetailsSection = useSelector(getHasCompletedAccountDetailsSection);

  const checkoutCategory = useCheckoutCategory();

  const params = new URLSearchParams(location.search);
  const withOrigin = addOriginParam(params);
  const nextStepFromStepProp = useSelector(state =>
    getNextStepFromStepProp(state, { step: checkoutSteps.ACCOUNT_DETAILS })
  );

  const goToNextCheckoutStep = useCallback(async () => {
    await dispatch(checkoutCartActions.goToCheckout()).unwrap();

    if (!hasCompletedAccountDetailsSection) {
      // New users arrive here
      navigate(withOrigin(`/checkout/${checkoutCategory}/${checkoutSteps.ACCOUNT_DETAILS}`));
    } else {
      navigate(withOrigin(`/checkout/${checkoutCategory}/${nextStepFromStepProp}`));
    }
  }, [hasCompletedAccountDetailsSection, checkoutCategory]);

  return { goToNextCheckoutStep };
};

export const useGoToCheckoutV2 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  const hasCompletedAccountDetailsSection = useSelector(getHasCompletedAccountDetailsSection);
  const hasCompletedShippingAddressSection = useSelector(getHasCompletedShippingAddressSectionV2);

  const params = new URLSearchParams(location.search);
  const withOrigin = addOriginParam(params);
  const nextStepFromStepProp = useSelector(state =>
    getNextStepFromStepPropV2(state, { step: checkoutSteps.ACCOUNT_DETAILS })
  );

  const goToNextCheckoutStep = useCallback(async () => {
    if (!isAuthenticated) {
      // New users arrive here
      navigate(withOrigin(`/checkout/accessories/${checkoutSteps.CREATE_ACCOUNT}`));
    } else if (!hasCompletedAccountDetailsSection) {
      navigate(withOrigin(`/checkout/accessories/${checkoutSteps.ACCOUNT_DETAILS}`));
    } else {
      navigate(withOrigin(`/checkout/accessories/${nextStepFromStepProp}`));
    }
  }, [hasCompletedAccountDetailsSection, hasCompletedShippingAddressSection]);

  return { goToNextCheckoutStep };
};
