import { createSelector } from 'reselect';

import giftContent from 'assets/content/gift';

const getGifting = state => state.gifting;

export const getGiftContent = createSelector(() => giftContent());

export const getSelectedGift = createSelector(getGifting, gifting => gifting.selectedGift);

export const getGiftPubkey = createSelector(getGifting, gifting => gifting.giftPubkey);

export const getGiftError = createSelector(getGifting, gifting => gifting.error);
