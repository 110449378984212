import { createAsyncThunk } from '@reduxjs/toolkit';

import * as APIClient from 'Services/APIClient';
import * as UserService from 'Services/UserService';

import { PAYPAL_ID } from 'constants/paymentMethods';
import { productsSlugs } from 'constants/products';
import { subscriptionCategories } from 'constants/subscriptions';

import logSentryError from 'utils/logSentry';

import { trackCreateAddress, trackFetchUser, trackUpdateAddress } from 'dux/tracking/actions';

export const fetchUser = createAsyncThunk('user/fetchUser', async (_, { dispatch }) => {
  try {
    const user = await UserService.fetch();
    dispatch(trackFetchUser(user));
    return /** @type {{pubkey: string; email: string}} */ (user);
  } catch (err) {
    logSentryError('[user/fetchUser]', err);
    throw err;
  }
});

export const patchUser = createAsyncThunk('user/patchUser', async (data, { dispatch }) => {
  try {
    const user = await UserService.patch(data);
    dispatch(trackFetchUser(user));
    return user;
  } catch (error) {
    logSentryError('[user/patchUser] ', error);
    throw error;
  }
});

export const fetchAddresses = createAsyncThunk('user/fetchAddresses', async (_, { dispatch }) => {
  try {
    const result = await UserService.fetchAddresses();
    dispatch(trackUpdateAddress(result));
    return result;
  } catch (err) {
    logSentryError('API action (User.fetchAddresses)', err);
    throw err;
  }
});

export const createAddress = createAsyncThunk(
  'user/createAddress',
  async (address, { dispatch }) => {
    try {
      const result = await UserService.createAddress(address);
      dispatch(trackCreateAddress(result));
      return result;
    } catch (err) {
      logSentryError('API action (User.createAddress)', err);
      throw err;
    }
  }
);

export const fetchAllPaymentMethods = createAsyncThunk('user/fetchAllPaymentMethods', async () => {
  try {
    const paymentMethods = await UserService.fetchAllPaymentMethods();
    return paymentMethods;
  } catch (err) {
    logSentryError('API action (User.fetchAllPaymentMethods)', err);
    throw err;
  }
});

export const fetchPaymentMethods = createAsyncThunk('user/fetchPaymentMethods', async () => {
  try {
    const paymentMethods = await UserService.fetchPaymentMethods();
    return paymentMethods;
  } catch (err) {
    logSentryError('API action (User.fetchPaymentMethods)', err);
    throw err;
  }
});

export const fetchPayPalPaymentMethods = createAsyncThunk(
  'user/fetchPayPalPaymentMethods',
  async () => {
    try {
      const result = await UserService.fetchPaymentMethods({ braintree: true });
      return result;
    } catch (error) {
      logSentryError('API action (User.fetchPayPalPaymentMethods)', error);
      throw error;
    }
  }
);

export const deletePayPalPaymentMethods = createAsyncThunk(
  'user/deletePayPalPaymentMethods',
  async (/** @type {string} */ paymentSourceId) => {
    try {
      await UserService.deletePaypalPaymentMethod(paymentSourceId);
    } catch (error) {
      logSentryError('API action (User.deletePayPalPaymentMethods)', error);
    }
  }
);

export const createPaymentMethod = createAsyncThunk(
  'user/createPaymentMethod',
  async paymentMethod => {
    try {
      const result = await UserService.createPaymentMethod(paymentMethod);
      return result;
    } catch (err) {
      logSentryError('API action (User.createPaymentMethod)', err);
      throw err;
    }
  }
);

export const setDefaultPaymentMethod = createAsyncThunk(
  'user/setDefaultPaymentMethod',
  async (/** @type {{ id?: string; service?: string }} */ paymentMethod, { dispatch }) => {
    try {
      await UserService.setDefaultPaymentMethod({
        paymentId: paymentMethod.id,
        paymentMode: paymentMethod.service === PAYPAL_ID ? 'braintree' : 'stripe_charge',
      });
      await dispatch(fetchAllPaymentMethods());
    } catch (err) {
      logSentryError('API action (User.setDefaultPaymentMethod)', err);
      throw err;
    }
  }
);

export const fetchRecommendations = createAsyncThunk(
  'user/fetchRecommendations',
  async subscriptionCategory => {
    try {
      const category =
        subscriptionCategory === subscriptionCategories.SKINCARE
          ? subscriptionCategories.SKINCARE
          : subscriptionCategories.HAIRCARE;

      const recommendations = await UserService.fetchRecommendations(category);
      return recommendations;
    } catch (error) {
      logSentryError('[user/fetchRecommendations]', error);
      throw error;
    }
  }
);

export const fetchSupplementsVariant = createAsyncThunk(
  'user/fetchSupplementsVariant',
  async () => {
    try {
      const result = await APIClient.postJson('/v1/account/hair_profile/formula', {
        skeleton: productsSlugs.SUPPLEMENT_CORE,
      });
      return result;
    } catch (error) {
      logSentryError(`[Account Supplements]`, error);
      throw error;
    }
  }
);
